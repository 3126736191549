/**
 * Lodash chain doesn't work well with lodash-es, so this is a custom version
 * with just the modules we're using.
 * Adapted from https://github.com/lodash/lodash/issues/3298#issuecomment-341685354
 */

import {
  cloneDeep,
  concat,
  filter,
  find,
  findLast,
  first,
  flatten,
  get,
  join,
  map,
  mapValues,
  max,
  orderBy,
  reject,
  slice,
  some,
  sortBy,
  take,
  toPairs,
  uniq,
  values,
  zip,
} from 'lodash-es';

// Add new lodash methods here as we need them
const chainableFunctions = {
  cloneDeep,
  concat,
  filter,
  find,
  findLast,
  first,
  flatten,
  get,
  join,
  map,
  max,
  orderBy,
  reject,
  slice,
  some,
  sortBy,
  take,
  toPairs,
  uniq,
  values,
  zip,
};

const chain = (input) => {
  let value = input;
  const wrapper = {
    ...mapValues(
      chainableFunctions,
      (f) => (...args) => {
        // Lodash always puts the input as the first argument
        value = f(value, ...args);
        return wrapper;
      },
    ),
    value: () => value,
  };
  return wrapper;
};

export default chain;
