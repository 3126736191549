import axios from 'axios';
import * as Ably from 'ably/browser/static/ably-commonjs';
import { v4 as uuid } from 'uuid';
import {
  first,
  get,
  isString,
  merge,
  pickBy,
  reject,
  shuffle,
} from 'lodash-es';
import chain from '@/lib/utils/chain';
import Observable from './observable';
import SharedEvent from './shared-event';
import SharedMessage from './shared-message';
import SharedSession from './shared-session';
import SharedChannels from './shared-channels';
import Utils from './utils';
import {
  SharedDocumentError,
  SharedDocumentAuthError,
  SharedDocumentCreateSessionError,
  SharedDocumentLoadSessionError,
} from './errors';

/**
 * Time to wait between attempts while connecting to the active session.
 */
const CONNECT_TO_SESSION_INTERVAL = 1000;
/**
 * Max number of attempts to try when connecting to a sessoin before
 * creating a new session.
 */
const CONNECT_TO_SESSION_MAX_ATTEMPTS = 30;
/**
 * When the host has changed and the new host doesn't have a session
 * loaded, this is the time to wait after requesting session keys from
 * other connected users before creating a new session.
 */
const HOST_CHANGED_SESSION_KEY_WAIT_TIME = 5000;
/**
 * After Ably client becomes disconnected, this is how often the Ably
 * client should attempt to reconnect.
 */
const DISCONNECTED_RETRY_TIMEOUT = 5000;
/**
 * After Ably client becomes suspended, this is how often the Ably
 * client should attempt to reconnect.
 */
const SUSPENDED_RETRY_TIMEOUT = 10000;
/**
 * This is the default number of messages that should be sent per second
 * by this SharedDocument instance.
 */
const MAX_MESSAGES_PER_SECOND = 10;
/**
 * This is the max binary payload size that should be used when sending
 * messages to other connected users. Note that the JSON payload size is
 * not calculated as part of the message size so this value should be a little
 * smaller (~1KB) than the max message size configured for the Ably platform to leave
 * room for the somewhat small and constant JSON payload size.
 */
const MAX_MESSAGE_SIZE_BYTES = 1024 * 15;
/**
 * This is how often Ably will ping users to determine if they're still connected.
 */
const CONNECTION_HEARTBEAT_INTERVAL = 5000;
/**
 * This is how long Ably will wait after a ping in order to determine user is dormant.
 */
const CONNECTION_PRESENCE_TIMEOUT = 1000;
/**
 * Generic timeout for Ably API requests.
 */
const CONNECTION_REQUEST_TIMEOUT = 30 * 1000;
/**
 * Default base url used by the SharedDocument unless configured in options.
 */
const BASE_URL = 'https://api.local.discoveryeducation.com/v1/studio';
/**
 * Unique identifiers used when isShared option is false.
 */
const LOCAL_ONLY_CLIENT_ID = uuid();
const LOCAL_ONLY_CONNECTION_ID = uuid();

/**
 * Creates a new instace of the SharedDocument object.
 * SharedDocument opens a document in a live editing session that allows multple
 * remote users (clients) to edit the document at the same time. SharedDocument handles
 * realtime synchronizing and merging of changes to all clients connected to
 * to the same document.
 */
export default class SharedDocument extends Observable {
  /**
   * Creates a new instance of the SharedDocument.
   * @param {Object} options Configuration options for the SharedDocument instance.
   * @param {string} options.appId Name of the app using SharedDocument e.g. 'studio'
   * @param {string} options.resourceId Unique identifier for the document for the set appId
   * @param {Array} options.references Listed of references to be used to authorize
   * the current user for the document loaded in this SharedDocument.
   * @param {Object} options.userData Custom data for the current user. e.g. { status: 'typing' }
   * This data will be propogated to all other users connected to this document.
   * @param {string} options.baseUrl Base url SharedDocument will use when interacting
   * with the server.
   * @param {Object} options.axiosClient A custom configured axios client to use instead of default.
   * @param {boolean} options.useCompression Compress save requests sent from SharedDocument
   * to the server.
   * @param {number} options.maxMessagesPerSecond Max number of messages this instance of
   * SharedDocument will send per second. Should be less than what's configured for the
   * Ably platform account.
   * @param {number} options.maxMessageSizeBytes The max binary payload size that should be
   * used when sending messages to other connected users. Should be ~1KB less than what's configured
   * for the Ably account.
   * @param {boolean} options.isShared Determines if the document will be shared with other
   * connected users. When false, this instance will not synchronize its changes with
   * other remote users.
   * @param {Object} options.connection Configuration options for the Ably realtime client.
   */
  constructor(options) {
    super();
    this.options = {
      appId: null,
      resourceId: null,
      env: null,
      region: null,
      references: [],
      userData: {},
      baseUrl: BASE_URL,
      axiosClient: axios,
      useCompression: false,
      maxMessagesPerSecond: MAX_MESSAGES_PER_SECOND,
      maxMessageSizeBytes: MAX_MESSAGE_SIZE_BYTES,
      isShared: true,
      connection: {
        authCallback: async (error, callback) => {
          const url = this._utils.url(options.baseUrl || BASE_URL, 'shared_document/auth');
          /*
            Post to auth with references, so that we can use the references to authorize access.
            document_id will be used on the server to authorize the token for this document only.
            client_id will typically be undefined here and be set on the server instead.
          */
          try {
            const response = await this.options.axiosClient.post(
              url,
              {
                references: options.references,
                document_id: this.options.documentId,
                client_id: this.options.clientId,
                app_id: this.options.appId,
                // send the resource id w/o the env, as the url is env-specific
                resource_id: this.options.resourceId,
              },
              {
                headers: {
                  'X-Token': this.options.userToken,
                },
                withCredentials: true,
              },
            );
            callback(null, get(response, 'data.token', response.data));
          } catch (authError) {
            callback(authError);
            this._emitError(new SharedDocumentAuthError(authError));
          }
        },
        authHeaders: {
          authorization: 1,
        },
        authMethod: 'POST',
        transportParams: {
          heartbeatInterval: CONNECTION_HEARTBEAT_INTERVAL,
          dormantAfter: CONNECTION_PRESENCE_TIMEOUT,
        },
        queryTime: true,
        echoMessages: false,
        queueMessages: false,
        useBinaryProtocol: true,
        timeouts: {
          realtimeRequestTimeout: CONNECTION_REQUEST_TIMEOUT,
        },
        disconnectedRetryTimeout: DISCONNECTED_RETRY_TIMEOUT,
        suspendedRetryTimeout: SUSPENDED_RETRY_TIMEOUT,
        transports: ['web_socket'],
      },
    };
    /*
      The documentId is a calculated value derived from appId and resourceId.
      The resulting string value should be the unique identifier for the
      document currently loaded from the call to getDocument().
    */
    const documentId = this._getDocumentId(options.appId, options.env, options.resourceId);
    merge(this.options, options, {
      documentId,
    });

    this._validateDocumentId();

    this.connectedUsers = [];
    this.currentUser = null;
    this.hostUser = null;

    this._sharedEvent = new SharedEvent(this);
    this._sessionKeys = {};
    this._session = null;
    this._client = null;
    this._channels = null;
    this._initializingSession = false;
    this._connectedUsersSynced = false;
    this._connectToSessionTimeout = null;
    this._connectToSessionAttempts = 0;
    this._connectToSessionQueue = [];
    this._lastSession = null;
    this._connectionBecameDisconnected = false;
    this._utils = new Utils(this);
  }

  /**
   * Determines if this instance of the SharedDocument is the host user.
   */
  get isHost() {
    return this.currentUser
      && this.hostUser
      && this.currentUser.id === this.hostUser.id;
  }

  /**
   * Determines if there are outstanding changes waiting to be saved.
   * The SharedDocument shouldn't be closed yet if this value is true
   * otherwise outstanding changes might not be saved.
   */
  get hasUnsavedChanges() {
    if (!this._session) return false;
    return this._session.hasUnsavedChanges;
  }

  /**
   * Determines if this SharedDocument is connect to a live editing session.
   */
  get isConnected() {
    // If running local only, we are connected if there is a session.
    if (!this.isShared) return !!this._session;

    const connectionState = get(this, '_client.connection.state');
    return connectionState === 'connected' && !!this._session;
  }

  /**
   * The appId this document should use for authorization. The appId is the app
   * this document belongs to.
   * The combination of 'appId' and 'resourceId' must be unique to this document.
   */
  get appId() {
    return this.options.appId;
  }

  /**
   * Returns the environment name configured for this instance.
   */
  get env() {
    return this.options.env;
  }

  /**
   * The resourceId this document should use for authorization. The resourceId
   * uniquely identifies this document within an appId.
   * resourceId also includes the env to help reduce the chances of channel naming
   * conflicts across environments
   * The combination of 'appId' and 'resourceId' must be unique to this document.
   */
  get resourceId() {
    return this.options.resourceId;
  }

  /**
   * Returns that unique identifier for this SharedDocument.
   */
  get documentId() {
    return this.options.documentId;
  }

  /**
   * Returns the YMap or YArray version of the document that was originally
   * loaded into the SharedDocument via the getDocument() option.
   */
  get document() {
    return get(this._session, 'document');
  }

  /*
    Determines if this instance will synchronize its changes to other
    connected users of the same document.
  */
  get isShared() {
    return this.options.isShared;
  }

  /**
   * Opens the SharedDocument editing session.
   * The 'connected' event will be raised when the document is ready.
   */
  open() {
    // If this instance is not shared, then don't open a realtime connection.
    if (!this.isShared) {
      this._openLocalOnly();
      return;
    }

    // apply eu lockdown
    const { connection } = this.options;

    if (this.options.region === 'UK') {
      connection.fallbackHosts = [
        'eu-a-fallback.ably-realtime.com',
        'eu-b-fallback.ably-realtime.com',
        'eu-c-fallback.ably-realtime.com',
        'eu-d-fallback.ably-realtime.com',
        'eu-e-fallback.ably-realtime.com',
      ];

      connection.environment = 'eu';
    }

    this._client = new Ably.Realtime(connection);
    this._channels = new SharedChannels(this);
    this._subscribeConnectionEvents();
  }

  /**
   * Closes this instance of the SharedDocument and disconnects from
   * the current editing session for this document. All queued messages will be
   * published before this async function resolves.
   * The 'disconnected' event will be raised after the document is disconnected.
   * The 'closed' event will be raised shortly when the document is closed.
   */
  async close() {
    if (this._sharedEvent) {
      await this._sharedEvent.flush();
    }
    this._closeChannels();
    if (this._client) {
      this._client.close();
      this._client = null;
    }
    /*
      Normally onClosed is called when the realtime client is closed,
      but if isShared option is false, we need to call onClosed here since
      the realtime client isn't being used.
    */
    if (!this.isShared) this._onClosed();
    this._connectionBecameDisconnected = false;
    this._lastSession = null;
  }

  /**
   * Applies changes to the SharedDocument in a single transaction. These changes will be
   * synced and merged with remote users editing the same document.
   * @param {function} func A function that makes one or more changes to the document.
   * @param {string} transactionOrigin A name for the transaction that these changes will be
   * grouped within. This name can be accessed when observing changes to the document.
   */
  change(func, transactionOrigin) {
    if (!this._session) return;
    this._session.change(func, transactionOrigin);
  }

  /**
   * Sets a data object that will be attached to this connected user. This data will be synced
   * and available for all remote users.
   * @param {Object} userData An object containing properties specific to this user
   * e.g. { name: 'Nick', status: 'typing' }
   */
  updateUserData(userData) {
    if (!this.isConnected) return;
    // When isShared option is false, recreate the local connected user list.
    if (!this.isShared) {
      this._setLocalOnlyConnectedUsers(userData);
      return;
    }
    if (this._mainChannel) {
      this._mainChannel.presence.update(this._getPresenceUser(userData),
        this._emitError.bind(this));
    }
  }

  /**
   * Cleans up any resources used by this instance. Should be called when this instance
   * of SharedDocument will no longer be used.
   */
  async destroy() {
    await this.close();
    if (this._utils) {
      this._utils.destroy();
    }
  }

  /**
   * The unique identifer for the current connected user.
   */
  get _currentUserId() {
    /*
      If not shared, then use unique identifiers generated locally instead
      since ids from realtime connection won't be available.
    */
    if (!this.isShared) {
      return this._getUserId(LOCAL_ONLY_CLIENT_ID, LOCAL_ONLY_CONNECTION_ID);
    }
    const clientId = get(this, '_client.auth.tokenDetails.clientId');
    return this._getUserId(clientId, this._client.connection.id);
  }

  /**
   * Channel used to track current users connected to this document.
   */
  get _mainChannel() {
    return this._channels.get(this._mainChannelName);
  }

  /**
   * Returns full name of the main channel.
   */
  get _mainChannelName() {
    return this._channels.getChannelName('main');
  }

  /**
   * Channel created for the current user only used to send messages
   * directly to specific user.
   */
  get _userChannel() {
    return this._channels.get(this._currentUserChannelName);
  }

  /**
   * Returns the full name of the current user's channel.
   */
  get _currentUserChannelName() {
    return this._getUserChannelName({ id: this._currentUserId });
  }

  /**
   * Gets a list of users other than the current user that are connected
   * to this SharedDocument.
   */
  get _otherConnectedUsers() {
    return reject(this.connectedUsers, (user) => user.id === this._currentUserId);
  }

  /**
   * Determines if the Ably realtime client is currently in the connected state.
   */
  get _clientIsConnected() {
    // When running in local mode, we're always connected.
    if (!this.isShared) return true;
    return get(this, '_client.connection.state') === 'connected';
  }

  /**
   * Gets the currently active session key. Because SharedDocument operates in a
   * distriubted environment, it may be possible for more than one connected user to think they
   * are the host for a brief period of time until Ably's servers have fully synchronized. Each
   * host user will create a new session key (and session) and distribute that key to all clients.
   * Each sessionKey also has a timestamp
   * e.g. { sessionKey: 'session-key-value', timestamp: 1610131190087 }
   * Even though multiple sessions may be created, we only want one active editing session.
   * To account for this, the currently active session key will always be the one with the oldest
   * timestamp value.
   */
  get _activeSessionKey() {
    return chain(this._sessionKeys)
      .values()
      .sortBy((sessionKey) => sessionKey.connectionTime)
      .first()
      .value();
  }

  /**
   * Creates the unique identifier for this SharedDocument
   * @param {string} appId Current appId configured for this SharedDocument
   * @param {string} env Current env
   * @param {string} resourceId Current unqiue resourceId configured for this appId
   */
  _getDocumentId(appId, env, resourceId) {
    return `${appId}__${env}_${resourceId}`;
  }

  /**
   * Gets the full channel name for a specified user.
   * @param {Object} user A currently connected user.
   */
  _getUserChannelName(user) {
    return this._channels.getChannelName(`user-${user.id}`);
  }

  /**
   * Gets the unique identifier for a connected user.
   * @param {string} clientId The user id for a connected user.
   * @param {string} connectionId The unique id for a connected user's connection.
   */
  _getUserId(clientId, connectionId) {
    return `${clientId}_${connectionId}`;
  }

  /**
   * Opens the SharedDocument for local use only. i.e. Changes will not
   * be synchronized to other connected users.
   */
  _openLocalOnly() {
    this._channels = new SharedChannels(this);
    this._setLocalOnlyConnectedUsers(this.options.userData);
    this._createSession();
  }

  /**
   * Creates the connected users collection when isShared option is false.
   * @param {Object} userData Custom user data for the current connected user.
   */
  _setLocalOnlyConnectedUsers(userData) {
    // Create a member to use as the local connected user.
    const members = [{
      clientId: LOCAL_ONLY_CLIENT_ID,
      connectionId: LOCAL_ONLY_CONNECTION_ID,
      timestamp: (new Date()).getTime(),
      data: this._getPresenceUser(userData),
    }];
    this._updateConnectedUsers(members);
  }

  /**
   * Creates a new SharedMessage object.
   * @param {string} name Name of the event.
   * @param {Object} payload JSON payload for this message.
   * @param {Uint8Array} binaryPayload Binary payload for this message.
   */
  _createMessage(name, payload, binaryPayload) {
    return new SharedMessage(name,
      payload,
      binaryPayload,
      this.options.maxMessageSizeBytes);
  }

  /**
   * Gets the user object to be submitted to the presence api.
   * @param {Object} userData Custom user attributes configured for this user.
   */
  _getPresenceUser(userData) {
    return { userData };
  }

  /**
   * Validates the document id created for this SharedDocument.
   */
  _validateDocumentId() {
    if (!isString(this.appId)) throw new Error('appId must be a string');
    if (!isString(this.resourceId)) throw new Error('resourceId must be a string');
    if (!isString(this.env)) throw new Error('env must be a string');
    /*
      Prevent colon character in document id. This is important since : is used
      to indicate namespaces within channel names.
    */
    if (this.appId.includes(':')) throw new Error('appId cannot contain the character \':\'');
    if (this.resourceId.includes(':')) throw new Error('resourceId cannot contain the character \':\'');
    if (this.env.includes(':')) throw new Error('env cannot contain the character \':\'');
  }

  /**
   * Opens needed channels for this SharedDocument.
   */
  async _openChannels() {
    try {
      await Promise.all([this._openMainChannel(), this._openUserChannel()]);
      this._subscribeMainChannelEvents();
    } catch (error) {
      this._emitError(error);
    }
  }

  /**
   * Closes all open channels for this SharedDocument and clears state
   * associated with the connection.
   */
  _closeChannels() {
    this.connectedUsers = [];
    this.currentUser = null;
    this.hostUser = null;
    this._channels.close();
    this._sessionKeys = {};
    this._connectedUsersSynced = false;
    this._closeActiveSession();
    this._stopConnectToSession();
  }

  /**
   * Opens the main channel used to track connected users.
   */
  _openMainChannel() {
    return this._channels.openChannel(this._mainChannelName);
  }

  /**
   * Opens a channel that specific to the currently connected user.
   */
  _openUserChannel() {
    return this._channels.openChannel(this._currentUserChannelName);
  }

  /**
   * Should be called when the Ably realtime client changes to the
   * 'disconnected' state.
   */
  _onDisconnected() {
    if (this._connectionBecameDisconnected) return;
    /*
      Set flag so when we become 'connected' again, we know that we
      were previously 'disconnected'.
    */
    this._connectionBecameDisconnected = true;
    /*
      Save the current session. When we become 'connected' again, we
      can try to reuse this same session if the _activeSessionKey at the
      time of reconnecting is still the same. This can help preserve any local
      changes that might not have been distributed before disconnect.
    */
    this._lastSession = this._session ? this._session.getState() : null;
    this._closeChannels();
  }

  /**
   * Should be called when the Ably realtime client is closed or when isShared
   * option is false and this SharedDocument instance is closed.
   */
  _onClosed() {
    this._emit('closed');
  }

  /**
   * Attaches event handlers to the Ably realtime connection.
   */
  _subscribeConnectionEvents() {
    this._client.connection.once('connected', this._openChannels.bind(this));

    this._client.connection.on('connected', function () {
      if (this._connectionBecameDisconnected) {
        /*
          If we were previously disconnected, reset the flag and attempt
          to reopen the live editing session.
        */
        this._connectionBecameDisconnected = false;
        this._closeChannels();
        this._openChannels();
      }
    }.bind(this));

    this._client.connection.on('disconnected', this._onDisconnected.bind(this));
    this._client.connection.on('suspended', this._onDisconnected.bind(this));
    this._client.connection.on('closed', this._onClosed.bind(this));
  }

  /**
   * Gets the currently known list of connected users from Ably and maps them to
   * the connectedUsers list for this SharedDocument.
   */
  _updateConnectedUsersFromPresence() {
    this._mainChannel.presence.get(function (presenceError, members) {
      if (presenceError) {
        this._emitError(presenceError);
        return;
      }
      this._updateConnectedUsers(members);
    }.bind(this));
  }

  /**
   * Listens for presence events on the main channel to determine current
   * users connected. This is the entry point for when a user is connected and will
   * determine whether that user creates a new session or connects to an
   * existing one.
   */
  _subscribeMainChannelEvents() {
    this._mainChannel.presence.subscribe(function (event) {
      // Unique identifier for the user this event belongs to.
      const eventUserId = this._getUserId(event.clientId, event.connectionId);
      // Ignore current user 'leave' events
      if (event.action === 'leave' && eventUserId === this._currentUserId) return;

      if (!this._connectedUsersSynced) {
        /*
          Here we wait for the first non-leave event for the current user. This
          means the user has entered the channel successfully.
        */
        const currentUserIsEntering = eventUserId === this._currentUserId
          && ['enter', 'present', 'update'].includes(event.action);
        if (currentUserIsEntering) {
          /*
            Once the current user has entered, we make a request to the Ably servers
            to get the list of connected users. We need to do this because at the time
            that the current user enters the channel, it's possible that another user
            has also entered the channel but we don't know about it yet. This is because it's
            possible that not all connected clients have synchronized yet. We want the most
            current list of connected users so we can better determine the 'host' user.
          */
          this._getChannelMembers(this._mainChannelName)
            .then((members) => {
              if (!this._clientIsConnected) return;
              this._subscribeUserChannelEvents();
              this._updateConnectedUsers(members);
              // Flag this SharedDocument instance as having connected users initially synced.
              this._connectedUsersSynced = true;
              /*
                Once we've retrieved the most recent list of connected users from Ably, then
                we can determine if we are the 'host' user. If we are, create a new session,
                otherwise connect to an existing session created by the host.
              */
              if (this.isHost) {
                this._createSession();
              } else {
                this._connectToSession();
              }
              this._updateConnectedUsersFromPresence();
            });
        }
      } else {
        this._updateConnectedUsersFromPresence();
      }
    }.bind(this));

    this._mainChannel.presence.enter(this._getPresenceUser(this.options.userData),
      this._emitError.bind(this));
  }

  /**
   * Subscribes to events on the current user's channel.
   * Handles events for exchanging session keys.
   */
  _subscribeUserChannelEvents = () => {
    this._sharedEvent.subscribe(this._userChannel, 'session-keys', (sharedMessage) => {
      // When new session keys come in, merge them with existing local session keys.
      this._applySessionKeys(sharedMessage.payload.sessionKeys);
    });
    this._sharedEvent.subscribe(this._userChannel, 'session-keys-requested', (sharedMessage) => {
      /*
        When session keys are requested from this connected user, then determine any keys
        that the current user has that the remote user doesn't. Then send these keys back to the
        user that requested them.
      */
      if (this._activeSessionKey) {
        const remoteSessionKeys = sharedMessage.payload.userSessionKeys || {};
        const missingSessionKeys = pickBy(this._sessionKeys,
          (value, key) => !remoteSessionKeys[key]);
        if (Object.keys(missingSessionKeys).length) {
          const channel = this._channels.get(sharedMessage.payload.userChannelName);
          const message = this._createMessage('session-keys', {
            sessionKeys: missingSessionKeys,
          });
          this._sharedEvent.publish(channel, message);
        }
      }
    });
  };

  /**
   * Starts a loop that will continuously attempt to connect to an existing session.
   */
  _connectToSession() {
    const activeSessionKey = this._activeSessionKey;
    if (activeSessionKey) {
      /*
        If there is an active session key, then ensure that the session
        for the _activeSessionKey is loaded and stop trying to connect.
      */
      this._onSessionKeysChanged();
      this._stopConnectToSession();
    } else if (!this.isHost) {
      /*
        If the max number of connect-to-session attempts has been reached, then
        create a new session since we can assume that all other users are unavailable.
      */
      if (this._connectToSessionAttempts >= CONNECT_TO_SESSION_MAX_ATTEMPTS) {
        this._stopConnectToSession();
        this._createSession();
        return;
      }
      // Remove any uses from the queu that are no longer connected
      this._connectToSessionQueue = reject(this._connectToSessionQueue, (user) => {
        return !this.connectedUsers.find((connUser) => connUser.id === user.id);
      });
      /*
        If the queue is empty, then refill it with the current connected users.
        Try to connect to the host first, then try other connected users at random.
      */
      if (!this._connectToSessionQueue.length) {
        const hostUserFunc = (user) => user.isHost;
        const hostUser = this._otherConnectedUsers.find(hostUserFunc);
        if (hostUser) this._connectToSessionQueue.push(hostUser);
        this._connectToSessionQueue.push(
          ...shuffle(reject(this._otherConnectedUsers, hostUserFunc)),
        );
      }
      /*
        Pull the next user out of the queue and send them a request for their
        session keys.
      */
      if (this._connectToSessionQueue.length) {
        const user = this._connectToSessionQueue.shift();
        this._requestSessionKeysFromUser(user);
        /*
          Send a second request a little later just in case the user we're
          requesting from was still initializing and their keys weren't available.
        */
        setTimeout(() => this._requestSessionKeysFromUser(user), CONNECT_TO_SESSION_INTERVAL * 2);
      }
      this._connectToSessionTimeout = setTimeout(this._connectToSession.bind(this),
        CONNECT_TO_SESSION_INTERVAL);
      this._connectToSessionAttempts += 1;
    } else {
      this._stopConnectToSession();
    }
  }

  /**
   * Stops the process for attempting to connect to a session.
   */
  _stopConnectToSession() {
    if (this._connectToSessionTimeout) {
      clearTimeout(this._connectToSessionTimeout);
      this._connectToSessionTimeout = null;
    }
    this._connectToSessionQueue = [];
    this._connectToSessionAttempts = 0;
  }

  /**
   * Distributes the current local list of session keys to all connected users.
   */
  _distributeSessionKeys() {
    this._otherConnectedUsers.forEach((user) => {
      const channel = this._channels.get(this._getUserChannelName(user));
      const message = this._createMessage('session-keys', {
        sessionKeys: this._sessionKeys,
      });
      this._sharedEvent.publish(channel, message);
    });
  }

  /**
   * Requests session keys from a specific connected user.
   * @param {Object} user The connected user to get session keys from.
   */
  _requestSessionKeysFromUser(user) {
    const channel = this._channels.get(this._getUserChannelName(user));
    const message = this._createMessage('session-keys-requested', {
      userChannelName: this._currentUserChannelName,
      userSessionKeys: this._sessionKeys,
    });
    this._sharedEvent.publish(channel, message);
  }

  /**
   * Requests session keys from all connected users.
   */
  _requestSessionKeys() {
    this._otherConnectedUsers.forEach(this._requestSessionKeysFromUser.bind(this));
  }

  /**
   * Merges incoming session keys with the local list of keys and raises events.
   * @param {Object} sessionKeys Object containing a set of session keys.
   */
  _applySessionKeys(sessionKeys) {
    Object.assign(this._sessionKeys, sessionKeys);
    this._onSessionKeysChanged();
  }

  /**
   * Handles when incoming session keys have been merged with the local keys list.
   * After merging in new keys, checks if the current session matches the active
   * session key. If not, closes the current session and loads a new session using
   * the _activeSessionKey.
   */
  async _onSessionKeysChanged() {
    if (!this._clientIsConnected) return;
    const activeSessionKey = this._activeSessionKey;
    if (!activeSessionKey || this._initializingSession) return;
    if (this._session && this._session.sessionKey.key !== activeSessionKey.key) {
      this._closeActiveSession();
      await this._loadSession();
    } else if (!this._session) {
      await this._loadSession();
    }
  }

  /**
   * Attempts to set the current active session. This should be the only way the
   * active session is set. Handles closing the session if needed and raises
   * connection events.
   * @param {SharedSession} session The session to be set as the active session.
   */
  _setActiveSession(session) {
    if (session && !this._clientIsConnected) {
      session.close();
      this._closeActiveSession();
      return;
    }
    const previousSession = this._session;
    this._session = session;
    if (this._session) {
      if (previousSession !== this._session) {
        this._emit('connected', this._session.document);
      }
    } else {
      this._emit('disconnected');
    }
  }

  /**
   * Closes the currently active session if it exists. This should be
   * the only way an active session is removed.
   */
  _closeActiveSession() {
    if (this._session) {
      this._session.close();
    }
    this._setActiveSession(null);
  }

  /**
   * Trys to set the active session. Ensures that the session being set
   * matches the _activeSessionKey. If not, aborts and starts loading
   * the session for _activeSessionKey.
   * @param {SharedSession} session The session object to set as the active session.
   */
  async _trySetSession(session) {
    const activeSessionKey = this._activeSessionKey;
    // If there is no active session key, close the session.
    if (!activeSessionKey) {
      session.close();
      return;
    }
    /*
      If the session matches the _activeSessionKey, then set the session.
      Otherwise, close the session and load a new session from the _activeSessionKey.
    */
    if (activeSessionKey.key === session.sessionKey.key) {
      this._setActiveSession(session);
    } else {
      session.close();
      await this._loadActiveSession();
    }
  }

  /**
   * Creates a new session and session key and attempts to set
   * as the currently active session.
   */
  async _createSession() {
    if (this._initializingSession) return;
    try {
      this._initializingSession = true;
      const session = new SharedSession(this);
      await session.open();
      // Add our new session key to our local set of session keys.
      this._applySessionKeys({
        [session.sessionKey.key]: session.sessionKey,
      });
      await this._trySetSession(session);
      this._distributeSessionKeys();
      this._requestSessionKeys();
    } catch (error) {
      this._emitError(new SharedDocumentCreateSessionError(error));
    } finally {
      this._initializingSession = false;
    }
    /*
      Ensure that the session created above still matches the
      _activeSessionKey. If not, it will be closed and the session
      for the _activeSessionKey will be loaded instead.
    */
    await this._onSessionKeysChanged();
  }

  /**
   * Loads an existing session from the _activeSessionKey or
   * from a saved previous session before getting disconnected.
   */
  async _loadSession() {
    if (this._initializingSession) return;
    try {
      this._initializingSession = true;
      /*
        Try to load the previous session if present. A previous session
        might be available if we had become disconnected and reconnected
        again.
      */
      const loadedLastSession = await this._tryLoadLastSession();
      // If no previous session, load the current active session.
      if (!loadedLastSession) await this._loadActiveSession();
      this._requestSessionKeys();
    } catch (error) {
      this._emitError(new SharedDocumentLoadSessionError(error));
    } finally {
      this._initializingSession = false;
    }
    /*
      Ensure that the session loaded above still matches the
      _activeSessionKey. If not, it will be closed and the session
      for the _activeSessionKey will be loaded instead.
    */
    await this._onSessionKeysChanged();
  }

  /**
   * Loads the session defined in the _activeSessionKey and attempts
   * to set it as the active session.
   */
  async _loadActiveSession() {
    const activeSessionKey = this._activeSessionKey;
    if (!activeSessionKey) return;
    const session = new SharedSession(this);
    await session.loadFromKey(activeSessionKey);
    await this._trySetSession(session);
  }

  /**
   * Attempts to load the last active session before the SharedDocument
   * became disconnected. This is an attept to save any local changes a user
   * might have made that didn't get distributed to other users before
   * getting disconnected.
   */
  async _tryLoadLastSession() {
    const activeSessionKey = this._activeSessionKey;
    if (!activeSessionKey) return false;
    const lastSessionKey = get(this, '_lastSession.sessionKey.key');
    const lastSessionDocumentBytes = get(this, '_lastSession.documentBytes');
    if (lastSessionKey === activeSessionKey.key && lastSessionDocumentBytes) {
      const session = new SharedSession(this);
      await session.loadFromState(this._lastSession);
      await this._trySetSession(session);
      return true;
    }
    return false;
  }

  /**
   * Creates a list of connected users from the list of members returned from
   * the Ably presence API. Determines the host and current user.
   * @param {Array} members The list of connected members returned from Ably presence.
   */
  _getConnectedUsersFromMembers(members) {
    const connectedUsers = chain(members)
      .map((member) => {
        const userId = this._getUserId(member.clientId, member.connectionId);
        return {
          id: userId,
          clientId: member.clientId,
          connectionTime: `${member.timestamp}_${member.connectionId}`,
          connectionId: member.connectionId,
          isCurrentUser: userId === this._currentUserId,
          isHost: false,
          data: member.data,
        };
      })
      .sortBy((member) => member.connectionTime)
      .value();
    if (connectedUsers.length) first(connectedUsers).isHost = true;
    return connectedUsers;
  }

  /**
   * Sets the current list of connected users and raises events.
   * Also sets the current host and current user objects.
   * Deterines when the host has changed.
   * @param {Array} members List of connected members returned from Ably's presence API.
   */
  _updateConnectedUsers(members) {
    this.connectedUsers = this._getConnectedUsersFromMembers(members);
    const previousHostId = get(this.hostUser, 'id');
    this.hostUser = this.connectedUsers.find((member) => member.isHost);
    this.currentUser = this.connectedUsers.find((member) => member.isCurrentUser);
    this._emitConnectedUsersChanged();
    const currentHostId = get(this.hostUser, 'id');
    if (previousHostId && currentHostId !== previousHostId) {
      this._onHostChanged();
    }
  }

  /**
   * Should be called whenever the host user has changed. Goes through a process to
   * ensure that the new host has a session loaded and is up to date.
   */
  async _onHostChanged() {
    if (!this._connectedUsersSynced) return;
    if (this.isHost) {
      if (this._session) {
        // Sync the host session with the latest state from the server.
        this._session.checkServerForChanges();
      } else if (this._otherConnectedUsers.length) {
        /*
          If there is no active session, request session keys from
          other connected users.
        */
        this._requestSessionKeys();
        setTimeout(() => {
          if (!this._clientIsConnected) return;
          if (!this._session && !this._initializingSession) {
            /*
              If still no active session and user is still the host
              then create a new session, otherwise try to connect to
              the active session.
            */
            if (this.isHost) {
              this._createSession();
            } else {
              this._connectToSession();
            }
          }
        }, HOST_CHANGED_SESSION_KEY_WAIT_TIME);
      } else if (!this._initializingSession) {
        /*
          If not other users are connected and there is an
          active session key, load the active session. Otherwise
          create a new session.
        */
        const activeSessionKey = this._activeSessionKey;
        if (activeSessionKey) {
          this._loadSession();
        } else {
          this._createSession();
        }
      }
    }
    this._emit('host-changed', this.hostUser);
  }

  /**
   * Makes a round trip to the Ably servers to fetch the current list of connected members.
   * @param {string} channelName The full channel name to get connected uers for.
   */
  async _getChannelMembers(channelName) {
    const membersUrl = this._utils.url(this.options.baseUrl, `shared_document/${channelName}/members`);
    let members = [];

    try {
      const response = await this.options.axiosClient.get(membersUrl, { withCredentials: true });
      members = get(response, 'data.members', response.data);
    } catch (e) {
      this._emitError(e);
    }

    return members.map((member) => {
      return {
        clientId: member.client_id,
        connectionId: member.connection_id,
        timestamp: member.timestamp,
        data: member.data,
        action: member.action,
      };
    });
  }

  /**
   * Raises events when the connected users list has changed.
   */
  _emitConnectedUsersChanged() {
    this._emit('connected-users-changed', this.connectedUsers);
  }

  /**
   * Broadcasts error messages from SharedDocument.
   * @param {Error} error Error object
   */
  _emitError(error) {
    if (error) {
      const emitError = error instanceof SharedDocumentError
        ? error : new SharedDocumentError(error);
      this._emit('error', { error: emitError });
    }
  }
}
