/**
 * An object that will call an action and then return a promise for the resolution
 * of that action. The promise can be resolved or rejected from somewhere external by
 * calling 'resolve()' or 'reject()' on this Deferred object.
 * e.g. The action could show a confirmation dialog and this deferred could be resolved or
 * rejected with the response from that dialog.
 */
export default class Deferred {
  _promise = null;

  _action = null;

  _reject = null;

  _resolve = null;

  /**
   * Deferred created, but action not called and promise
   * not created yet.
   */
  static CREATED = 'created';

  /**
   * Action called and promise created, but not resolved or rejected yet.
   */
  static PENDING = 'pending';

  /**
   * Action called and promise resolved.
   */
  static RESOLVED = 'resolved';

  /**
   * Action called and promise rejected.
   */
  static REJECTED = 'rejected';

  state = Deferred.CREATED;

  /**
   * Creates a new Deferred object with the specified action function.
   * @param {Function} action Function to be called when the promise
   * for this Deferred is created.
   */
  constructor(action) {
    this._action = action;
  }

  /**
   * Reject the promise for this Deferred with a value.
   * @param {Object} value Value to reject promise with.
   */
  reject(value) {
    if (this.state === Deferred.PENDING) {
      this._reject(value);
      this.state = Deferred.REJECTED;
    }
  }

  /**
   * Resolve the promise for this Deferred with a value.
   * @param {Object} value Value to resolve promise with.
   */
  resolve(value) {
    if (this.state === Deferred.PENDING) {
      this._resolve(value);
      this.state = Deferred.RESOLVED;
    }
  }

  /**
   * Resets the Deferred object the initial 'created' state.
   */
  reset() {
    this._promise = null;
    this._resolve = null;
    this._reject = null;
    this.state = Deferred.CREATED;
  }

  /**
   * Calls the action and creates and returns the promise
   * for the resolution of that action.
   * @returns A promise that will resolve when either 'reject()'
   * or 'resolve()' is called on this Deferred.
   */
  promise() {
    if (!this._promise) {
      this._promise = new Promise((resolve, reject) => {
        this._resolve = resolve;
        this._reject = reject;
      });
      this.state = Deferred.PENDING;
    }
    if (this._action) {
      this._action();
    }
    return this._promise;
  }
}
