/* eslint-disable no-underscore-dangle */
import { first, reject } from 'lodash-es';
import Observable from '../observable';

const EVENT_STACK_CHANGED = 'stack-changed';

/**
 * Max items allowed on the undo stack.
 */
const STACK_MAX_ITEMS = 500;

/**
 * Manages a stack of undo items and a stack of redo items.
 */
export default class UndoManager extends Observable {
  constructor() {
    super();
    this.undoStack = [];
    this.redoStack = [];
  }

  get canUndo() { return !!this.undoStack.length; }

  get canRedo() { return !!this.redoStack.length; }

  /**
   * Adds a new item to the undo stack.
   * @param {Object} stackItem New undo stack item to add.
   */
  add(addedItem) {
    const stackItem = addedItem;
    // Call 'apply' to commit changes if implemented.
    if (stackItem.apply) stackItem.apply();

    // Check if the item can be added. If not, cancel add.
    if (stackItem.canAdd && !stackItem.canAdd(this)) {
      return;
    }

    // Set the time this item was added to the stack.
    const now = (new Date()).getTime();
    stackItem.stackTime = now;

    // Get item on top of the stack
    const lastItem = first(this.undoStack);
    const lastItemStackTime = lastItem?.stackTime || 0;

    if (
      stackItem.captureTimeout
      && lastItem?.captureTimeout === stackItem.captureTimeout
      && (now - lastItemStackTime < stackItem.captureTimeout)
    ) {
      /*
        Merge this stack item with the last since it's within
        the configured capture timeout period.
      */
      lastItem.merge(stackItem);
      lastItem.stackTime = now;
    } else {
      // Add item to the top of the stack.
      this.undoStack.unshift(stackItem);
    }

    // Clear the redo stack when a new item is added to the undo stack.
    this.redoStack = [];

    // Allow stack items to perform actions after they've been added.
    if (stackItem.onAdded) stackItem.onAdded(this);

    // Only allow a set number of items on the stack at a time.
    if (this.undoStack.length > STACK_MAX_ITEMS) {
      this.undoStack.splice(STACK_MAX_ITEMS, this.undoStack.length - STACK_MAX_ITEMS);
    }

    this._emit(EVENT_STACK_CHANGED);
  }

  /**
   * Removes the top item on the undo stack and calls 'undo()' on that item.
   * Then adds that stack item to the redo stack.
   */
  undo() {
    if (this.canUndo) {
      const stackItem = this.undoStack.shift();
      stackItem.undo();
      this.redoStack.unshift(stackItem);
      this._emit(EVENT_STACK_CHANGED);
    }
  }

  /**
   * Removes the top item on the redo stack and calls 'redo()' on that item.
   */
  redo() {
    if (this.canRedo) {
      const stackItem = this.redoStack.shift();
      stackItem.redo();
      this.undoStack.unshift(stackItem);
      this._emit(EVENT_STACK_CHANGED);
    }
  }

  /**
   * Removes all stack items from the undo and redo stacks that meet
   * the criteria provided in the filter function.
   * @param {Function} filterFunc Function that matches stack items to remove.
   */
  clearStacks(filterFunc) {
    if (filterFunc) {
      this.undoStack = reject(this.undoStack, filterFunc);
      this.redoStack = reject(this.redoStack, filterFunc);
    } else {
      this.undoStack = [];
      this.redoStack = [];
    }
    this._emit(EVENT_STACK_CHANGED);
  }
}
