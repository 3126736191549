import { defineStore } from 'pinia';
import {
  first, last, keyBy,
} from 'lodash-es';
import { Note } from '@discoveryedu/platform-components';
import {
  useEditorStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

const useTextSelectStore = defineStore('studio-text-select', {
  state: () => ({
    highlightData: null,
    highlights: null,
    notesDataByHighlightId: null,
  }),
  getters: {
    currentPageHighlightData() {
      const editorStore = useEditorStore();
      return (this.highlightData || [])
        .filter((item) => (
          item.references.find((ref) => (
            ref.reference_type === 'page' && ref.reference_id === editorStore.currentPageId
          ))
        ))
        .map((item) => {
          const highlightDataItem = { ...item };
          const noteData = this.notesDataByHighlightId[item.id];
          if (noteData) {
            const note = new Note();
            note.createFromData(noteData);
            highlightDataItem.note = note;
          }
          return highlightDataItem;
        });
    },
  },
  actions: {
    [types.SET_HIGHLIGHTS](highlights) {
      this.highlights = highlights;
    },
    async [types.GET_HIGHLIGHT_DATA](referenceIds) {
      const highlightsUrl = '/notebook/highlight/references/?';
      const notesUrl = '/notebook/note/references/?';

      let queryString = '';

      referenceIds.forEach((id) => {
        if (queryString) {
          queryString += '&';
        }
        queryString += `reference_id=${id}`;
      });

      if (queryString) {
        const highlightsPromise = this.deApi.get(highlightsUrl + queryString);
        const notesPromise = this.deApi.get(notesUrl + queryString);

        const results = await Promise.all([highlightsPromise, notesPromise]);
        const highlightsResult = first(results);
        const notesResult = last(results);

        this.notesDataByHighlightId = keyBy(notesResult?.data?.notes || [], 'highlightId');
        this.highlightData = highlightsResult?.data?.highlights || [];
      }
    },
    [types.CLEAR_HIGHLIGHT_DATA]() {
      this.highlights = null;
      this.highlightData = null;
      this.notesDataByHighlightId = null;
    },
    add(list, item) {
      if (!list.find((h) => h.id === item.id)) {
        list.push(item);
      }
    },
    remove(list, id, replacements = []) {
      const index = list.findIndex((h) => h.id === id);
      if (index !== -1) {
        list.splice(index, 1, ...replacements);
      }
    },
    update(list, item) {
      this.remove(list, item.id);
      this.add(list, item);
    },
    [types.ADD_HIGHLIGHT](item) {
      this.add(this.highlights, item);
    },
    [types.REMOVE_HIGHLIGHT](highlightId) {
      this.remove(this.highlights, highlightId);
    },
    [types.UPDATE_HIGHLIGHT](item) {
      this.update(this.highlights, item);
    },
  },
});

export default useTextSelectStore;
