// This is adapted from edde/edde/constants/language_code.py

export const LANGUAGE_FAMILY_BY_CODE = {
  af: 'afr',
  ar: 'ara',
  be: 'bel',
  bg: 'bul',
  ca: 'cat',
  cs: 'cze',
  cy: 'wel',
  da: 'dan',
  de: 'ger',
  dv: 'div',
  el: 'gre',
  en: 'eng',
  es: 'spa',
  et: 'est',
  eu: 'baq',
  fa: 'fas',
  fi: 'fin',
  fo: 'fao',
  fr: 'fre',
  gl: 'glg',
  gu: 'guj',
  he: 'heb',
  hi: 'hin',
  hr: 'hrv',
  hu: 'hun',
  hy: 'arm',
  id: 'ind',
  is: 'ice',
  it: 'ita',
  ja: 'jpn',
  ka: 'geo',
  kk: 'kaz',
  kn: 'kan',
  ko: 'kor',
  ky: 'kir',
  lt: 'lit',
  lv: 'lav',
  mi: 'mri',
  mk: 'mak',
  mn: 'mon',
  mr: 'mar',
  ms: 'msa',
  mt: 'mlt',
  nb: 'nob',
  nl: 'dut',
  nn: 'nob',
  ns: 'nso',
  pa: 'pan',
  pl: 'pol',
  ps: 'pus',
  pt: 'por',
  qu: 'que',
  ro: 'rum',
  ru: 'rus',
  sa: 'san',
  se: 'sme',
  sk: 'slo',
  sl: 'slv',
  sq: 'alb',
  sv: 'swe',
  sw: 'swa',
  ta: 'tam',
  te: 'tel',
  th: 'tha',
  tl: 'tgl',
  tn: 'tsn',
  tr: 'tur',
  tt: 'crh',
  uk: 'ukr',
  ur: 'urd',
  vi: 'vie',
  xh: 'xho',
  zh: 'chi',
  zu: 'zul',
};

export function getTeiLang(str) {
  // Default to English
  if (!str || typeof str !== 'string') return 'eng';

  const languageFamily = str.split(/[-_]/)[0];

  // Convert two-char codes but leave three-char codes as they are
  return LANGUAGE_FAMILY_BY_CODE[languageFamily] || languageFamily;
}
