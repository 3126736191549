import { get } from 'lodash-es';

export class SharedDocumentError extends Error {
  constructor(error) {
    super(error.message, { cause: error });
    this.data = this.getErrorData(error);
  }

  get className() {
    return 'SharedDocumentError';
  }

  get meta() {
    return get(this, 'data.meta');
  }

  get errorMessage() {
    return get(this, 'meta.message', this.message);
  }

  get type() {
    return get(this, 'meta.exception_type');
  }

  get status() {
    return get(this, 'meta.status_code', get(this, 'cause.response.status'));
  }

  get requestId() {
    return get(this, 'meta.request_id');
  }

  getErrorData(error) {
    let errorData = get(error, 'response.data', {});
    if (errorData instanceof ArrayBuffer) {
      errorData = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(errorData)));
    }
    return errorData;
  }

  toString() {
    return `${this.className}:
      ${this.status || ''}
      ${this.type ? `(${this.type})` : ''}
      ${this.requestId || ''}${this.status || this.type || this.requestId ? ':' : ''}
      ${this.errorMessage}`;
  }
}

export class SharedDocumentPollingError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentPollingError';
  }
}
export class SharedDocumentSaveError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentSaveError';
  }
}
export class SharedDocumentGetError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentGetError';
  }
}
export class SharedDocumentAuthError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentAuthError';
  }
}
export class SharedDocumentCreateSessionError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentCreateSessionError';
  }
}
export class SharedDocumentLoadSessionError extends SharedDocumentError {
  get className() {
    return 'SharedDocumentLoadSessionError';
  }
}

export default {
  SharedDocumentError,
  SharedDocumentPollingError,
  SharedDocumentSaveError,
  SharedDocumentGetError,
  SharedDocumentAuthError,
  SharedDocumentCreateSessionError,
  SharedDocumentLoadSessionError,
};
