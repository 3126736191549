export class DraftIsDeletedError extends Error {
  constructor(draftPointerAssetId, draftId, draftIsPublished) {
    super(`Draft with reference id ${draftPointerAssetId} is deleted`);
    this.draftPointerAssetId = draftPointerAssetId;
    this.draftId = draftId;
    this.draftIsPublished = draftIsPublished;
  }
}

export default {
  DraftIsDeletedError,
};
