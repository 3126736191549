import i18next from '@/lib/i18next';

export const THEME_DEFAULTS = {
  default: {
    themeVariation: 1,
    font: 'font-proxima-nova',
    wordTrackingFont: 'font-mikado',
    nebulaDefault: i18next.t('Nebula (Default)'),
    mysteryDefault: i18next.t('Mystery'),
    buttonStyle: undefined,
  },
  'mystery-science': {
    themeVariation: 2,
    font: 'font-agrandir',
    wordTrackingFont: 'font-agrandir',
    nebulaDefault: i18next.t('Nebula'),
    mysteryDefault: i18next.t('Mystery (Default)'),
    buttonStyle: 'mystery-science',
  },
  'timepod-adventures': {
    themeVariation: 1,
    font: 'font-proxima-vara-true',
    wordTrackingFont: 'font-proxima-vara-true',
    nebulaDefault: i18next.t('Nebula (Default)'),
    mysteryDefault: i18next.t('Mystery'),
    buttonStyle: undefined,
  },
};

export default THEME_DEFAULTS;
