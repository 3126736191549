import { get } from 'lodash-es';
import { defineStore } from 'pinia';
import { decodeHTML } from '@/lib/utils';
import * as types from '@/lib/constants/store';

const myStudioApiRoutes = {
  all: {
    filter: 'user_id',
    apiRoute: 'drafts',
  },
  drafts: {
    filter: 'user_id',
    apiRoute: 'drafts',
  },
  shared: {
    filter: 'user_id',
    apiRoute: 'creations',
  },
  boards: {
    filter: 'type',
    filterValue: 'board',
    apiRoute: 'drafts',
  },
  slideshows: {
    filter: 'type',
    filterValue: 'slides',
    apiRoute: 'drafts',
  },
  lessons: {
    filter: 'type',
    filterValue: 'lesson',
    apiRoute: 'drafts',
  },
};

const sharedWithMeApiRoutes = {
  all: {
    apiRoute: 'shared',
    filter: undefined,
  },
  drafts: {
    filter: 'state',
    filterValue: 'draft',
    apiRoute: 'shared',
  },
  shared: {
    filter: 'state',
    filterValue: 'creation',
    apiRoute: 'shared',
  },
  boards: {
    filter: 'type',
    filterValue: 'board',
    apiRoute: 'shared',
  },
  slideshows: {
    filter: 'type',
    filterValue: 'slides',
    apiRoute: 'shared',
  },
  lessons: {
    filter: 'type',
    filterValue: 'lesson',
    apiRoute: 'shared',
  },
};

const useHomeStore = defineStore('studio-home', {
  state: () => ({
    activityTemplates: null,
    boards: null,
    totalPages: null,
    error: null,
    loading: false,
  }),
  actions: {
    async [types.GET_ACTIVITY_TEMPLATES]() {
      this.loading = true;

      try {
        const result = await this.apiProgressBar.get('/templates/activity');
        if (result && result.data) {
          // This template data is used in a couple places, so best to format it only once
          this.activityTemplates = result.data.templates.map((template) => ({
            ...template,
            canBeUsedWith: template.tags
              .filter((tag) => tag.type === 'Most Relevant Media Types')
              .map((tag) => tag.name),
            decodedTitle: decodeHTML(template.title),
            skills: template.tags
              .filter((tag) => tag.type === 'Thinking Skills')
              .map((tag) => tag.name),
          }));
        } else {
          throw result;
        }
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e);
      } finally {
        this.loading = false;
      }
    },
    async [types.GET_HOME_BOARDS](payload) {
      const {
        query,
        sidebarFilter,
        user,
      } = payload;

      const sortFieldMap = {
        alphabetical: 'name',
        created_on: 'metadata.created_on',
        updated_on: 'metadata.updated_on',
        deleted_on: 'metadata.deleted_on',
      };

      this.error = null;
      this.loading = true;

      try {
        let routeParams = {};

        if (sidebarFilter === 'MY_STUDIO') {
          routeParams = myStudioApiRoutes[query.filter];
        } else if (sidebarFilter === 'SHARED_WITH_ME') {
          routeParams = sharedWithMeApiRoutes[query.filter];
        } else if (sidebarFilter === 'TRASH') {
          routeParams = { apiRoute: 'deleted' };
        }

        let url = '/creations/';
        let boardsKey = 'creations';

        if (routeParams.apiRoute === 'shared') {
          url = '/creations/shared';
        } else if (routeParams.apiRoute === 'drafts') {
          url = '/drafts/';
          boardsKey = 'drafts';
        } else if (routeParams.apiRoute === 'deleted') {
          url = '/creations/deleted';
        }

        const mask = `{${boardsKey}{id, assignment_id, name, thumbnail{url}, shares, references, state, options{type, is_template, template_type}, metadata{created_on, updated_on, deleted_on, published_on}, access_level, pages{thumbnail{url}}}, page, num_pages}`;

        const mergedQuery = {
          ...query,
          ...routeParams,
        };

        const params = {
          page: mergedQuery.page,
          search_text: mergedQuery.search || undefined,
          search_type: mergedQuery.filter,
          search_value: (mergedQuery.filter === 'user_id' || mergedQuery.filter === 'shared_with')
            ? user.id
            : mergedQuery.filterValue,
          sort_field: sortFieldMap[mergedQuery.sort],
          sort_direction: mergedQuery.sortOrder,
          per_page: 20,
        };

        const result = await this.apiProgressBar.get(url, {
          params,
          headers: {
            'X-Fields': mask,
          },
        });

        if (result && result.data) {
          this.boards = result.data[boardsKey].map((board) => ({
            access_level: sidebarFilter === 'MY_STUDIO' ? 'owner' : undefined,
            state: boardsKey === 'creations' ? 'creation' : 'draft',
            ...board,
          }));
          this.totalPages = result.data.num_pages;
        } else {
          throw result;
        }
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e);
      } finally {
        this.loading = false;
      }
    },
  },
});

export default useHomeStore;
