import SharedMessage from './shared-message';

/**
 * Handles subscribing to and publishing messages between multiple
 * instances of SharedDocument.
 */
export default class SharedEvent {
  /**
   * Creates a new SharedEvent object.
   * @param {SharedDocument} sharedDocument The SharedDocument instance this belongs to.
   */
  constructor(sharedDocument) {
    this._sharedDocument = sharedDocument;
    this._triggerQueue = [];
    this._triggerTimeout = null;
    this._remainingEventsLoopCount = 0;
    this._sharedMessageChunks = {};
  }

  /**
   * Executes all events currently in the queue at the maximum
   * rate configured.
   */
  flush() {
    return new Promise((resolve) => {
      // If the events loop is running, cancel it first.
      if (this._triggerTimeout) {
        clearTimeout(this._triggerTimeout);
        this._triggerTimeout = null;
      }
      /*
        Processes the maximum events possible and then waits
        for one second and runs again if needed. Resolves when
        the queue is cleared.
      */
      const processMaxEventsPerSecond = () => {
        if (this._triggerQueue.length) {
          const events = this._triggerQueue.splice(0, this._maxMessagesPerSecond);
          const promises = events.map((event) => {
            return new Promise((publishResolve) => {
              event.channel.publish(event.name, event.data, function (error) {
                if (error) {
                  this._sharedDocument._emitError(error);
                }
                publishResolve();
              }.bind(this));
            });
          });

          Promise.all(promises).then(() => {
            if (this._triggerQueue.length) {
              setTimeout(() => processMaxEventsPerSecond(), 1000);
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      };
      processMaxEventsPerSecond();
    });
  }

  /**
   * Publishes a new message on the specified channel.
   * @param {Object} channel The Ably channel to publish the message on.
   * @param {SharedMessage} sharedMessage The SharedMessage object to publish.
   */
  publish(channel, sharedMessage) {
    /*
      SharedMessage will split its payload into chunks no larger than the configured
      maxMessageSizeBytes configured for the SharedDocument. Each chunk will be
      published separately and reassembled on the receiving end.
    */
    sharedMessage.chunks.forEach((encodedChunk) => {
      this._triggerQueue.push({
        channel,
        name: sharedMessage.name,
        data: encodedChunk,
      });
    });

    // If events are not currently being processed, start processing.
    if (!this._triggerTimeout) this._executeTriggerEvents();
  }

  /**
   * Subscribes an event handler to specified event name.
   * @param {Object} channel The Ably channel to subscribe to.
   * @param {string} eventName The name of the event to subscribe to.
   * @param {function} callback The handler to call when the event is raised.
   */
  subscribe(channel, eventName, callback) {
    channel.subscribe(eventName, function (message) {
      const sharedMessage = SharedMessage.fromChunk(message.data);
      /*
        Messages come in chunks. If a message only has one chunk, then
        it will be complete. If so, then call the handler function.
      */
      if (sharedMessage.isComplete) {
        callback(sharedMessage);
      } else {
        /*
          If a message has more than one chunk, then store a list of chunks and
          append each chunk until the message is complete. When complete, call
          the handler function and delete the cached chunks.
        */
        const existingMessage = this._sharedMessageChunks[sharedMessage.id];
        if (existingMessage) {
          existingMessage.addChunk(message.data);
          if (existingMessage.isComplete) {
            callback(existingMessage);
            delete this._sharedMessageChunks[sharedMessage.id];
          }
        } else {
          this._sharedMessageChunks[sharedMessage.id] = sharedMessage;
        }
      }
    }.bind(this));
  }

  /**
   * Returns the max messages per second configured for the SharedDocument.
   */
  get _maxMessagesPerSecond() {
    return this._sharedDocument.options.maxMessagesPerSecond;
  }

  /**
   * Dequeues the maximum number of events specified and publishes
   * them them on the channel attached to the event.
   * @param {number} numberOfEvents The max number of events to dequeue.
   */
  _dequeueAndExecuteEvents(numberOfEvents) {
    const events = this._triggerQueue.splice(0, numberOfEvents);
    events.forEach((event) => {
      event.channel.publish(event.name, event.data, function (error) {
        if (error) {
          this._sharedDocument._emitError(error);
        }
      }.bind(this));
    });
    return events;
  }

  /**
   * Messages are split into chunks and stored in queue to be published.
   * This function publishes these messages at a rate no more than the
   * maxMessagesPerSecond configured for the SharedDocument.
   * @param {number} numberOfEvents The max number of events to process in this loop iteration.
   */
  _executeTriggerEvents(numberOfEvents = this._maxMessagesPerSecond) {
    // If queue is empty, then clear and exit.
    if (!this._triggerQueue.length) {
      this._triggerTimeout = null;
      this._remainingEventsLoopCount = 0;
      return;
    }
    // Get the number of events allowed in this iteration and publish.
    const events = this._dequeueAndExecuteEvents(numberOfEvents);
    /*
      If we process less than the numberOfEvents allowed, then attempt to process
      the difference (eventsRemaining) in the next loop interation. We will continue this
      process every 50ms for up to 1 second. Then we wait another 1 second before processing
      events again.
    */
    const eventsRemaining = numberOfEvents - events.length;
    if (eventsRemaining && this._remainingEventsLoopCount < 20) {
      this._remainingEventsLoopCount += 1;
      this._triggerTimeout = setTimeout(() => this._executeTriggerEvents(eventsRemaining), 50);
    } else {
      this._remainingEventsLoopCount = 0;
      this._triggerTimeout = setTimeout(() => this._executeTriggerEvents(), 1000);
    }
  }
}
