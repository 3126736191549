import { merge } from 'lodash-es';
/**
 * Queues up requests and exectutes them sequentially, waiting for each
 * one to complete before executing the next.
 */
export default class RequestQueue {
  /**
   * Creates a new queue instance.
   */
  constructor(options) {
    this.options = {
      maxRetries: 3,
      successCodes: [200, 201, 202, 203, 204],
    };
    merge(this.options, options);
    this.queue = [];
    this.running = false;
    this.retryCount = 0;
  }

  async next() {
    if (!this.queue.length) return;
    const requestFunc = this.queue.shift();
    try {
      this.running = true;
      const result = await requestFunc();
      if (!this.options.successCodes.includes(result.status)) {
        throw result;
      }
      this.retryCount = 0;
      this.running = false;
      this.next();
    } catch (error) {
      if (this.retryCount < this.options.maxRetries) {
        this.retryCount += 1;
        this.queue.unshift(requestFunc);
        this.next();
      } else {
        this.retryCount = 0;
        this.running = false;
        this.next();
      }
    }
  }

  /**
   * Adds an async request function to the queue.
   * @param {function} requestFunc Function that returns a promise for a request result.
   */
  enqueue(asyncFunc) {
    this.queue.push(asyncFunc);
    if (!this.running) {
      this.next();
    }
  }
}
