import {
  findLast,
  get,
  last,
  map,
  omit,
} from 'lodash-es';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import { formatDate } from '@/lib/utils';
import chain from '@/lib/utils/chain';
import SharedDocument, { yjsHelper } from '@/shared-document';
import { getEnv, getRegion } from '@/lib/api';
import RequestQueue from '@/lib/requestQueue';
import { PRODUCT_SETTING_STUDIO_CHAT_ENABLED, ROLE_STUDENT } from '@/lib/constants';
import * as types from '@/lib/constants/store';
import {
  useUserStore,
} from '@/stores';

let sharedDoc = null;
const messageQueues = {};

const useChatStore = defineStore('studio-chat', {
  state: () => ({
    chat: null,
    connected: false,
    connectedUsers: null,
    /*
      This value needs to be an object to force Vue to update
      even when 'enabled' hasn't changed. Whole object must
      be replaced when setting value.
    */
    chatToggle: { enabled: false },
    queuedChatMessages: [],
    // since connected users can be determined before the chat is initialized,
    // create this list to store users that have ever joined at any point
    userList: [],
  }),
  getters: {
    chatIsConnected: (state) => state.connected,
    chatMessages: (state) => chain(get(state, 'chat.messages', []))
      .concat(state.queuedChatMessages)
      .sortBy(['message.metadata.created_on'])
      .map((m) => {
        // Chat messages are grouped by (local) day. Date operations are happening here so that
        // they don't have to happen multiple times in the Chat drawer. Since messages from the
        // same user are grouped by time, the data structure has to be massaged a little bit

        const formattedMessage = {
          ...m,
          messages: [{
            message: m.message,
            message_id: m.message_id,
            // In case a sent message and a queued message ever appear together (e.g. when the
            // user sends lots of short messages in a row, faster than the database can
            // respond), we need to make sure that Vue doesn't get confused if two have the
            // same id in the meantime:
            message_key: `${m.message_id}${m.queued ? '-queued' : ''}`,
            settings: m.settings,
            queued: m.queued,
          }],
          formattedDate: formatDate(get(m, 'metadata.created_on')),
          formattedTime: formatDate(get(m, 'metadata.created_on'), true, false),
        };

        return omit(formattedMessage, ['message', 'message_id', 'settings']);
      })
      .value(),
    chatUsers: (state) => get(state, 'chat.users', []),
    /** Return obj with firstname and lastname for use in rendering */
    userById: (state) => (id) => {
      // try chat users list first. this is the list of users that have ever submitted a message
      // that have been stored in the api (covers disconnected users)
      const foundUser = get(state, 'chat.users', [])
        .find((user) => user.user_guid === id);

      if (foundUser) {
        return {
          firstName: foundUser.first_name,
          lastName: foundUser.last_name,
        };
      }

      // try the userlist second to get any user that has ever connected to the chat session
      // regardless of whether or not they are currently present
      const foundConnectedUser = state.userList
        .find((user) => user.id === id);

      if (foundConnectedUser) {
        return foundConnectedUser;
      }

      return false;
    },
    isNonStudentHost: (state) => {
      const nonStudentHost = chain(state.connectedUsers)
        .sortBy('connectionTime')
        .filter((user) => {
          const userRoles = get(user, 'data.userData.roles');
          return userRoles && !userRoles.includes(ROLE_STUDENT);
        })
        .first()
        .value();

      return get(nonStudentHost, 'isCurrentUser', false);
    },
  },
  actions: {
    [types.CLOSE_EDITING_SESSION]() {
      if (sharedDoc) {
        sharedDoc.destroy();
        sharedDoc = null;
        this.connected = false;
        this.chat = null;
        this.connectedUsers = null;
      }
    },
    [types.GET_CHAT]({ draft }) {
      const userStore = useUserStore();
      // If the user doesn't have access to chat via the product setting, cancel
      if (!get(userStore.productSettings, `${PRODUCT_SETTING_STUDIO_CHAT_ENABLED}.value`)) {
        return;
      }

      // If we're connected, we have a chat already
      if (sharedDoc) return;

      try {
        this[types.CLOSE_EDITING_SESSION]();

        sharedDoc = new SharedDocument({
          appId: 'chat',
          env: getEnv(),
          region: getRegion(),
          resourceId: draft.id,
          references: draft.references,
          baseUrl: this.api.defaults.baseURL,
          axiosClient: this.api,
          userData: {
            firstName: userStore.user.first_name,
            lastName: userStore.user.last_name,
            roles: userStore.user.roles
              ? map(userStore.user.roles, 'code')
              : null,
          },
          getDocument: async () => {
            let chatData;

            const chatResponse = await this.api.get(`chat/draft/${draft.id}`);

            if (get(chatResponse, 'data.meta.status_code') === 200) {
              chatData = chatResponse.data.chat;
            } else {
              const newChat = await this.api.post('/chat/', {
                messages: [],
                draft_id: draft.id,
              },
              {
                headers: {
                  'X-Token': userStore.user.token,
                },
              });

              chatData = newChat.data.chat;
            }

            return {
              chat: chatData,
            };
          },
          userToken: userStore.user.token,
        });

        sharedDoc.on('connected', (yDoc) => {
          const updateStore = () => {
            const jsDoc = yDoc.toJSON();
            this[types.UPDATE_CHAT](jsDoc.chat);
          };

          yDoc.get('chat').observeDeep((changes) => {
            // Whenever a change is made to the yDoc, commit that change to the local
            // Pinia store
            updateStore();

            /*
              Only a non student user can save updates to message settings. If the current user
              is the selected non-student host, then current user saves updates to the server.
            */
            if (this.isNonStudentHost) {
              // Changes to message settings need to be resolved by the yDoc. Only the
              // host sends these updates to the api after they are resolved.
              changes.forEach((change) => {
                const messageMatch = change.path.join('/').match(/messages\/(\d+)$/);
                if (messageMatch && change.changes.keys.size && change.changes.keys.get('settings')) {
                  // If this change was a change to message settings, go ahead with the PATCH
                  const chatId = this.chat.id;
                  const messageIndex = messageMatch[1];
                  const message = yDoc.get('chat').get('messages').get(messageIndex);
                  if (!message) return;
                  const { settings, message_id: messageId } = message.toJSON();

                  const saveRequest = () => this.api.post(`/chat/${chatId}/messages/${messageId}/settings`,
                    settings,
                    {
                      headers: {
                        'X-Token': userStore.user.token,
                      },
                    });

                  // Ensure updates for each message are saved to the server sequentially.
                  if (!messageQueues[messageId]) messageQueues[messageId] = new RequestQueue();
                  messageQueues[messageId].enqueue(saveRequest);
                }
              });
            }
          });

          updateStore();

          this.connected = true;
        });

        sharedDoc.on('connected-users-changed', (connectedUsers) => {
          this.connectedUsers = connectedUsers;

          if (!connectedUsers) return;

          // If we have connected users, decode their userData
          connectedUsers.forEach((user) => {
            // make sure each connected user is in the chat users list
            // this allows us to render the user even if they leave
            if (!this.userList.find((chatUser) => chatUser.id === user.clientId)) {
              this.userList.push({
                id: user.clientId,
                firstName: get(user, 'data.userData.firstName', ''),
                lastName: get(user, 'data.userData.lastName', ''),
              });
            }
          });
        });

        sharedDoc.on('disconnected', () => {
          this.connected = false;
          this.connectedUsers = null;
        });

        sharedDoc.on('closed', () => {
          this.chat = null;
          this.connected = false;
          this.connectedUsers = null;
        });

        sharedDoc.on('error', () => {});

        sharedDoc.open();
      } catch (error) {
        this.chat = null;
      }
    },
    async [types.SEND_CHAT_MESSAGE]({ message }) {
      const userStore = useUserStore();
      const messageId = uuid();
      const formattedMessage = {
        from_user: userStore.user.id,
        message_id: messageId,
        message,
      };

      const latestTimestamp = get(last(this.chatMessages), 'metadata.created_on');

      // This temporary timestamp is not saved to the server. Since the client's timestamp
      // might not be correct, it's only used to sort messages that are in the
      // process of being sent. If the client's timestamp is earlier than the latest message
      // received, the latest timestamp is used instead to prevent the UI from jumping
      // if possible
      const temporaryTimestamp = last([latestTimestamp, new Date().toISOString()].sort());

      this.queuedChatMessages.push({
        ...formattedMessage,
        metadata: {
          created_on: temporaryTimestamp,
        },
        queued: true,
      });

      const chatId = this.chat.id;
      const messageResponse = await this.api.post(`/chat/${chatId}/messages`, [
        formattedMessage,
      ],
      {
        headers: {
          'X-Token': userStore.user.token,
        },
      });

      // TODO: Show error if message was not received successfully by the server
      if (!get(messageResponse, 'data.chat.messages')) return;

      // Find the message we just saved
      const resolvedMessage = findLast(messageResponse.data.chat.messages, (m) => (
        m.message_id === messageId
      ));

      if (resolvedMessage && sharedDoc) {
        // Save our message, stamped with the server time, to the yDoc
        sharedDoc.change((doc) => {
          const messages = doc.get('chat').get('messages');
          messages.push([yjsHelper.toY(resolvedMessage)]);

          const messageIdx = this.queuedChatMessages.findIndex((m) => m.id === messageId);
          this.queuedChatMessages.splice(messageIdx, 1);
        });
      }
    },
    [types.UPDATE_CHAT](chat) {
      this.chat = chat;
    },
    [types.UPDATE_MESSAGE_IS_HIDDEN]({ id, isHidden }) {
      // Settings can be returned from the server as null, so there are times
      // we can't reach in to set a value inside. For right now this overwrites
      // the whole settings prop each time. At the moment settings only contain
      // data about whether a message is hidden, so we won't overwrite anything
      // unrelated.

      const userStore = useUserStore();
      const newSettings = {
        ...get(this, `chat.messages['${id}'].settings`),
        is_hidden: isHidden,
      };

      if (isHidden) {
        newSettings.hidden_by_user = userStore.user.id;
      }

      sharedDoc.change((doc) => {
        const message = yjsHelper.find(
          doc.get('chat').get('messages'),
          (m) => m.get('message_id') === id,
        );
        if (!message) return;

        message.set('settings', yjsHelper.toY(newSettings));
      });
    },
    [types.SET_CHAT_TOGGLE_ENABLED](enabled) {
      this.chatToggle = { enabled };
    },
  },
});

export default useChatStore;
