import * as Y from 'yjs';
import yjsInfo from 'yjs/package.json';
import { isArray, isPlainObject } from 'lodash-es';

/**
 * Recursively maps a source object or array to a new Y.Map or Y.Array
 * object respectively.
 * @param {Object} sourceValue Source object or array to convert from.
 * @param {string} sourceKey Property name to set the result into on the
 * provided 'y' parameter.
 * @param {Y.Map} y A Y.Map object to set the result into.
 */
function toY(sourceValue, sourceKey = null, y = null) {
  const isArr = isArray(sourceValue);
  const isObj = isPlainObject(sourceValue);
  if (isArr || isObj) {
    const yObj = isArr ? new Y.Array() : new Y.Map();
    Object.keys(sourceValue).forEach((key) => toY(sourceValue[key], key, yObj));
    if (y) {
      if (y instanceof Y.Map) {
        y.set(sourceKey, yObj);
      } else {
        y.push([yObj]);
      }
    }
    return yObj;
  }
  if (y instanceof Y.Map) {
    y.set(sourceKey, sourceValue);
  } else {
    y.push([sourceValue]);
  }
  return y;
}

/**
 * Gets the clock for specified Y.Doc. The clock is a list of clientId's
 * and the latest revision the document has from that client. It represents
 * the current state of the document.
 * @param {Y.Doc} doc A Y.Doc to get the clock for.
 */
function getClock(doc) {
  const clock = {};
  doc.store.clients.forEach((structs, client) => {
    clock[client] = Y.getState(doc.store, client);
  });
  return clock;
}

/**
 * Determines if the targetClock is behind the sourceClock for any clientId.
 * @param {Object} targetClock Clock to check if it is behind.
 * @param {Object} sourceClock Clock to check targetClock against.
 */
function clockIsBehind(targetClock, sourceClock) {
  return Object.keys(sourceClock).some((key) => {
    return !Object.prototype.hasOwnProperty.call(targetClock, key)
      || targetClock[key] < sourceClock[key];
  });
}

/**
 * Checks if the update has any changes.
 * @param {Uint8Array} update The Yjs update to check.
 */
function updateHasChanges(update) {
  return !!update && (update[0] !== 0 || update[1] !== 0);
}

/**
 * Gets any changes that exist in documentWithChanges that do not
 * exist in document.
 * @param {Y.Doc} document Document that might be missing changes.
 * @param {Y.Doc} documentWithChanges Document to check for changes in.
 */
function getMissingChanges(document, documentWithChanges) {
  const documentStateVector = Y.encodeStateVector(document);
  const changes = Y.encodeStateAsUpdate(documentWithChanges, documentStateVector);
  return updateHasChanges(changes) ? changes : null;
}

/**
 * Finds an object within a given Y.Array object.
 * @param {Y.Array} yArray The Y.Array to search.
 * @param {function} func Function that returns true or false to find an item.
 */
function find(yArray, func) {
  let result;
  /* eslint-disable no-restricted-syntax */
  for (const item of yArray) {
    if (func(item)) {
      result = item;
      break;
    }
  }
  return result;
}

/**
 * Interates the Y.Array and returns the first truthy value returned
 * from the provided function.
 * @param {Y.Array} yArray The Y.Array to interate.
 * @param {function} func The function to execute for each iteration. When this
 * function returns a truthy value, arrary iteration is stopped and the
 * value is returned.
 */
function findDeep(yArray, func) {
  let result;
  /* eslint-disable no-restricted-syntax */
  for (const item of yArray) {
    result = func(item);
    if (result) {
      break;
    }
  }
  return result || undefined;
}

/**
 * Iterates the Y.Array and returns the first index where the provided
 * function returns true, otherwise returns -1.
 * @param {Y.Array} yArray The Y.Array to search.
 * @param {function} func Function to execute for each item in Y.Array.
 */
function findIndex(yArray, func) {
  let index = 0;
  /* eslint-disable no-restricted-syntax */
  for (const item of yArray) {
    if (func(item)) {
      return index;
    }
    index += 1;
  }
  return -1;
}

/**
 * Updates the contents of a Y.Map object from a plain
 * JSON object. Removes keys in Y.Map that do not exist in
 * the source. Overwrites keys in Y.Map that also exist in source.
 * @param {Object} source Plain JSON object.
 * @param {Y.Map} yMap Y.Map object to update.
 */
function updateYMap(source, yMap) {
  [...yMap.keys()].forEach((key) => {
    if (!(key in source)) {
      yMap.delete(key);
    }
  });
  Object.keys(source).forEach((key) => {
    toY(source[key], key, yMap);
  });
}

export default {
  toY,
  getClock,
  clockIsBehind,
  updateHasChanges,
  getMissingChanges,
  find,
  findDeep,
  findIndex,
  updateYMap,
  version: yjsInfo.version,
};
