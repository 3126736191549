import {
  get, isEqual,
} from 'lodash-es';
import { defineStore } from 'pinia';
import i18next from '@/lib/i18next';
import { bus } from '@/lib/eventBus';
import {
  useEditorStore,
  useErrorStore,
  useInactivityStore,
  useQuicklistStore,
  useChannelStore,
  useUserStore,
} from '@/stores';
import { initPendo } from '@/lib/pendo';
import * as types from '@/lib/constants/store';

const useAppStore = defineStore('studio-app', {
  state: () => ({
    initialized: false,
    loading: false,
    allowLoadingNextRouteChange: false,
    experimentList: {},
    studioReferer: null,
  }),
  getters: {
    apiToken() {
      const userStore = useUserStore();

      // if there is an api token on the window, use that
      if (window.initData?.tokens && window.initData.tokens.api) {
        return window.initData.tokens.api;
      }

      // otherwise use the token from the users/me api call
      return userStore.user?.token;
    },
  },
  actions: {
    [types.SET_STUDIO_REFERER](payload) {
      this.studioReferer = payload;
    },
    [types.UPDATE_LOADING](loading) {
      this.loading = loading;
    },
    [types.UPDATE_INITIALIZED](payload) {
      this.initialized = payload;
    },
    [types.SET_ALLOW_LOADING_NEXT_ROUTE_CHANGE](allowLoading) {
      this.allowLoadingNextRouteChange = allowLoading;
    },
    async [types.GET_EXPERIMENT_LIST]() {
      const experimentRes = await this.deApi.get('/experiment/member/');

      if (experimentRes?.status === 200) {
        this.experimentList = experimentRes.data.experiments;
      } else {
        this.experimentList = {};
      }
    },
    async [types.INIT](payload = {}) {
      const errorStore = useErrorStore();
      const inactivityStore = useInactivityStore();
      const quicklistStore = useQuicklistStore();
      const channelStore = useChannelStore();
      const userStore = useUserStore();
      // set loading true
      this[types.UPDATE_LOADING](true);

      try {
        // Before anything else, check the session state to see if the user needs to
        // log in. The catch block will catch this if they do.
        if (!payload.allowPublicAccess) {
          await inactivityStore[types.CHECK_AUTH_STATUS]({ addTtlTimeout: true });
        }

        // If that succeeds, we can continue initializing by getting the user
        await userStore[types.GET_USER]({
          allowPublicAccess: payload.allowPublicAccess,
        });

        // If the user comes back, then fetch product settings
        await userStore[types.GET_PRODUCT_SETTINGS]({
          allowPublicAccess: payload.allowPublicAccess,
        });

        // The QuickList and channels does not need to block rendering
        quicklistStore[types.GET_QUICKLIST]();
        channelStore[types.GET_CHANNELS]();
      } catch (result) {
        // If CHECK_AUTH_STATUS failed and this isn't a public board, redirect to signin
        if (!payload.allowPublicAccess && result?.redirectUrl) {
          window.location.href = result.redirectUrl;
          return;
        }

        /*
          If the error is anything other than 401, then first try to
          show an error returned from backend server. If that is not present,
          then show a generic error. 401 errors are handled at the app level.
        */
        if (get(result, 'response.status') !== 401) {
          const serverError = get(result, 'response.data');
          const error = {
            active: true,
            error: serverError || {
              meta: {
                message: i18next.t("An error occurred while initializing Studio. Please try refreshing the page. If that doesn't work, please contact the support team."),
              },
            },
          };
          errorStore[types.SET_ERROR](error);

          // If app cannot be initialized do not continue and show error.
          return;
        }
      }

      // If the user doesn't have access to Studio, either redirect them to the
      // same board in the suite slides player, or to the DE homepage
      if (!userStore.userCanAccessStudio) {
        const assetId = window.location.href && new URL(window.location.href).searchParams.get('id');
        if (assetId && window.location.pathname.includes('/view')) {
          window.location.href = new URL(`/suite/player/slides/${assetId}`, this.domains.appDomain);
        } else {
          window.location.href = new URL('/', this.domains.appDomain).toString();
        }
      }

      // set analytics
      if (
        userStore.user
        && userStore.user.analytics_data
      ) {
        // set analytics data on the window
        window.analytics_data = userStore.user.analytics_data;

        // Initialize Pendo
        if (!userStore.userIsStudent) {
          initPendo(userStore.user.analytics_data);
        }
      }

      // dispatch(types.GET_EXPERIMENT_LIST);

      // set initialized
      this[types.UPDATE_INITIALIZED](true);

      // stop loading
      this[types.UPDATE_LOADING](false);
    },
    async [types.INIT_LIB](payload = {}) {
      const editorStore = useEditorStore();
      const quicklistStore = useQuicklistStore();
      const channelStore = useChannelStore();
      const userStore = useUserStore();
      const {
        allowAttempt,
        allowTeacherAttempt,
        allowPublicAccess,
        user,
        mode,
        id,
        previewData,
        fetchQuicklist,
        fetchChannels,
        pageId,
        homeworkId,
      } = payload;

      if (!id && !previewData) return;

      /*
        If a preview already exists and the current user's locale hasn't
        changed, then skip the full initialization and just update the preview.
      */
      const currentUserLocale = get(userStore, 'user.analytics_data.locale');
      if (editorStore.isPreviewMode
        && previewData
        && userStore.user
        && isEqual(
          get(user, 'analytics_data.locale', currentUserLocale),
          currentUserLocale,
        )) {
        await editorStore[types.CREATE_PREVIEW](previewData);
        return;
      }

      this[types.UPDATE_INITIALIZED](false);

      if (!user) {
        await userStore[types.GET_USER]({ allowPublicAccess });
      } else {
        await this[types.UPDATE_L10N](get(user, 'analytics_data.locale'));
        userStore[types.SET_USER](user);
      }

      if (previewData) {
        await editorStore[types.CREATE_PREVIEW](previewData);
      } else if (mode === 'EDIT') {
        await editorStore[types.OPEN_EDITING_SESSION]({
          draftId: id,
        });
        if (!editorStore.templates) await editorStore[types.GET_TEMPLATES]();
        editorStore[types.SET_IS_PREVIEW_MODE](false);
      } else {
        await editorStore[types.GET_CREATION]({
          allowAttempt,
          allowTeacherAttempt,
          id,
          preventRedirect: true,
          pageId,
          homeworkId,
        });
        editorStore[types.SET_IS_PREVIEW_MODE](false);
      }

      // The QuickList does not need to block rendering
      if (fetchQuicklist) {
        quicklistStore[types.GET_QUICKLIST]({ allowPublicAccess });
      }

      if (fetchChannels) {
        channelStore[types.GET_CHANNELS]({ allowPublicAccess });
      }

      this[types.UPDATE_INITIALIZED](true);
      this[types.UPDATE_LOADING](false);
    },
    async [types.UPDATE_L10N](locale) {
      // When running in embedded mode, the parent app handles localization setup.
      if (!this.studioAppIsRunning) return;
      if (!locale) {
        document.documentElement.setAttribute('lang', 'en');
        return;
      }

      const { code, text_direction_is_ltr: isLTR } = locale;
      if (!code || code === 'en') {
        document.documentElement.setAttribute('lang', 'en');
        return;
      }

      const baseUrl = this.domains.appDomain.replace('/learn', '');

      // i18next expects locales to use hyphens
      const formattedCode = code.replace(/_/g, '-');

      // create a random key to prevent cache issues
      // cors headers are coming back as * in the original request, which the browser does not allow
      // adding a random value forces a response with the correct CORs origin response header
      const randomNumber = Math.random().toString(36);

      const response = await this.deApi.get(
        `${baseUrl}/static/common/components/nunjucks-config/localization/${code.toLowerCase()}.json?c=${randomNumber}`,
        {
          withCredentials: false,
        },
      );
      i18next.addResourceBundle(formattedCode, 'translation', response.data);
      i18next.changeLanguage(formattedCode);
      bus.emit('refreshPageTitle');
      document.documentElement.setAttribute('lang', formattedCode);
      if (isLTR === false) {
        document.documentElement.setAttribute('dir', 'rtl');
      }
    },
  },
});

export default useAppStore;
