import { isString } from 'lodash-es';
import { DOMAIN_NAME_WITHOUT_SUFFIX, INTERNAL_DOMAINS } from '@/lib/constants';

export function isExternalUrl(url) {
  if (!isString(url)) return false;
  const domains = [DOMAIN_NAME_WITHOUT_SUFFIX]
    .concat(INTERNAL_DOMAINS)
    .map((domain) => domain.toLowerCase());
  const { host } = new URL(url);
  return !domains.find((domain) => host.includes(domain));
}

export function formatUrl(url, defaultProtocol = 'https') {
  if (!isString(url)) return url;
  const trimmedUrl = url.trim();
  const hostNameOnlyExp = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,}/;
  return hostNameOnlyExp.test(trimmedUrl.toLowerCase())
    ? `${defaultProtocol}://${trimmedUrl}`
    : trimmedUrl;
}

export function httpsUrl(url) {
  if (!isString(url)) return url;
  return url.replace(/^http:\/\//i, 'https://');
}

export default {
  formatUrl,
  isExternalUrl,
  httpsUrl,
};
