import { nebulaI18nPluralTerms } from '@discoveryedu/nebula-components';
import i18next from 'i18next';
import pluralTerms from './constants/i18nPluralTerms.json';

/**
 * Note on English plurals: Since translation files are only loaded when the language is not
 * English, and i18next doesn't accept a plural term inside the template itself, these need
 * to be added separately. In the core repo, see the ngettext() function in
 * `wwwroot/static/common/components/nunjucks-config/nunjucks-globals.js`
 * for another example of this.
 */

const allPluralTerms = {
  ...nebulaI18nPluralTerms,
  ...pluralTerms,
};

i18next.init({
  lng: 'en',
  fallbackLng: false,
  resources: {
    en: {
      translation: Object.keys(allPluralTerms).reduce((acc, key) => {
        acc[`${key}_plural`] = allPluralTerms[key];
        return acc;
      }, {}),
    },
  },
  returnEmptyString: false,
  interpolation: {
    prefixEscaped: '%\\(',
    suffixEscaped: '\\)[sd]',
  },
  nsSeparator: false,
  keySeparator: false,
});

export default i18next;
