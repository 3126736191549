let queue = [];

// eslint-disable-next-line no-unused-vars
let active = true;

/**
 * add a patch request to the queue
 * @param {object} request patch request instructions
 */
const add = (request) => {
  queue.push(request);
};

const restore = (items) => {
  queue = [...items, ...queue];
};

/**
 * Clear the queue
 */
const clear = () => {
  queue = [];
};

/**
 * Get items in the queue
 */
const getItems = () => queue;

const kill = () => {
  clear();
  active = false;
};

/**
 * poll the queue for items and run the callback
 * @param {function} callback a promise-returning function
 * @param {number} rate rate to poll
 * @param {retry} number of times to retry before errorstate
 */
const poll = (callback, rate = 500, retry = 5) => {
  // keep # of retries
  let retryCount = 0;

  // reset kill
  active = true;

  const process = async () => {
    // check the queue for items
    const items = getItems();

    // clear the queue
    clear();

    // any items?
    if (items.length) {
      try {
        // call the callback
        await callback(items);

        // reset retry count
        retryCount = 0;
      } catch (e) {
        // put the items back on the queue
        restore(items);

        // on failure, inc retries
        retryCount += 1;
      }
    }

    // wait till done, check for active, and check retries before trying again
    if (active && retryCount <= retry) {
      setTimeout(process, rate);
    }
  };

  setTimeout(process, rate);
};

export default {
  add,
  restore,
  clear,
  getItems,
  kill,
  poll,
};
