import { nextTick } from 'vue';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import { cloneDeep, throttle, merge } from 'lodash-es';

import {
  ASSET_TYPES,
  ASSET_SUBTYPES,
} from '@/lib/constants';
import {
  useAppStore,
  useAssessmentStore,
  useEditorStore,
  useUserStore,
  useAssetStore,
} from '@/stores';
import { bus } from '@/lib/eventBus';
import * as types from '@/lib/constants/store';

let timeoutId = null;
const timeoutSeconds = 5 * 60 * 1000; // 5 mins
let activityHandler;

const useTrackingStore = defineStore('studio-tracking', {
  state: () => ({
    progressTracking: {
      enabled: false,
      meta: {},
      source: null,
    },
    eventsEnabled: true,
    videoPlays: {},
    activitySummary: {
      pages: [],
      progress: {},
    },
    activityHandler: null,
    sessionId: null,
    isAnonymous: false,
    anonymousEventsAllowed: false,
    eventQueue: {
      ready: false,
      queue: [],
      clearing: false,
      started: false,
      tries: 0,
    },
    assetIds: [],
    videoIds: [],
  }),
  actions: {
    [types.SET_ANONYMOUS_ALLOWED](allowed) {
      this.anonymousEventsAllowed = !!allowed;
    },
    [types.START_EVENT_QUEUE_POLLING]() {
      const assetStore = useAssetStore();

      this.eventQueue.started = true;
      const intervalId = setInterval(() => {
        if (!this.eventQueue.clearing) {
          this.eventQueue.tries += 1;
          this.eventQueue.clearing = true;

          // clear out the queue
          const { queue } = this.eventQueue;
          this.eventQueue.queue = [];

          // if the queue is empty and direct send is ready OR we've waited too long,
          // cancel the interval
          if (!this.eventQueue.length && (assetStore.assetsLoaded || this.eventQueue.tries >= 50)) {
            clearInterval(intervalId);
          }

          // process each event
          queue.forEach((event) => {
            this[types.SEND_EVENT]({
              ...event,
              queueable: false,
            });
          });

          this.eventQueue.clearing = false;
        }
      }, 1000 * 5);
    },
    [types.SET_IS_ANONYMOUS](isAnonymous) {
      if (this.anonymousEventsAllowed) {
        this.isAnonymous = !!isAnonymous;

        if (isAnonymous) {
          this[types.GET_ANONYMOUS_ACTIVITY_SUMMARY]();
        }
      }
    },
    [types.START_SESSION_TRACKING]() {
      // create new session id
      this.sessionId = uuid();

      // Keep track of this inactivity handler so that we can clear it later on
      activityHandler = throttle(() => {
        // clear old timeout
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // restart timeout
        timeoutId = setTimeout(() => {
          // create a new session id
          // we don't need to reset the timeout
          // it will be reset by the event listeners
          this.sessionId = uuid();
        }, timeoutSeconds);
      }, 500);
      document.addEventListener('click', activityHandler);
      document.addEventListener('mousemove', activityHandler);
      document.addEventListener('keypress', activityHandler);

      activityHandler();
    },

    [types.END_SESSION_TRACKING]() {
      if (timeoutId) {
        document.removeEventListener('click', activityHandler);
        document.removeEventListener('mousemove', activityHandler);
        document.removeEventListener('keypress', activityHandler);

        clearTimeout(timeoutId);
      }
    },
    [types.GET_ANONYMOUS_ACTIVITY_SUMMARY]() {
      if (window.localStorage && this.isAnonymous && this.sessionStorageKey) {
        const anonymousSummary = {
          media: [],
          pages: [],
          progress: {
            page: [],
            creation: null,
          },
        };

        // first, check to see if storage exists
        let summary = window.localStorage.getItem(this.sessionStorageKey);
        if (summary) {
          summary = JSON.parse(summary);
        } else {
          summary = {};
        }

        // make sure the resulting obj has everything needed
        summary = merge(
          summary,
          anonymousSummary,
        );

        this[types.STORE_ANONYMOUS_SUMMARY](summary);
      }
    },
    [types.SET_ACTIVITY_SUMMARY](summary) {
      this.activitySummary = summary;
      if (this.isAnonymous) {
        this[types.STORE_ANONYMOUS_SUMMARY](summary);
      }
    },
    [types.STORE_ANONYMOUS_SUMMARY](summary) {
      this.activitySummary = summary;
      // store in localstorage for anonymous users
      if (this.isAnonymous && this.sessionStorageKey) {
        window.localStorage.setItem(this.sessionStorageKey, JSON.stringify(summary));
      }
    },
    [types.STORE_VIDEO_PLAY_PERCENTAGE](videoId, perc) {
      let found = false;
      let sendEvent = false;

      if (this.activitySummary.media) {
        // walk existing media plays
        for (let x = 0; x < this.activitySummary.media.length; x += 1) {
          if (this.activitySummary.media[x].id === videoId) {
            found = true;
            if (this.activitySummary.media[x].pct < perc) {
              // update summary
              this.activitySummary.media[x].pct = perc;

              // toggle send the event only if the value is higher
              sendEvent = true;
            }
          }
        }
      }

      // if we didn't find the given module in the existing list
      if (!found) {
        if (!this.activitySummary.media) {
          this.activitySummary.media = [];
        }

        // push the play into the summary
        this.activitySummary.media.push(
          {
            id: videoId,
            pct: perc,
          },
        );

        sendEvent = true;
      }

      // send the event
      if (this.eventsEnabled && sendEvent) {
        this[types.SEND_EVENT]({
          eventType: 'media.view_pct', referenceId: videoId, referenceType: 'page', value: perc,
        });
      }

      // store
      this[types.STORE_ANONYMOUS_SUMMARY](this.activitySummary);
    },
    [types.STORE_PAGE_VIEW_PERCENTAGE](pageId, perc) {
      let found = false;
      let sendEvent = false;

      if (this.activitySummary.progress.page) {
        // walk existing media plays
        for (let x = 0; x < this.activitySummary.progress.page.length; x += 1) {
          if (this.activitySummary.progress.page[x].id === pageId) {
            found = true;
            if (this.activitySummary.progress.page[x].pct < perc) {
              // update summary
              this.activitySummary.progress.page[x].pct = perc;
              sendEvent = true;
            }
          }
        }
      }

      // if we didn't find the given module in the existing list
      if (!found) {
        if (!this.activitySummary.progress.page) {
          this.activitySummary.progress.page = [];
        }

        // push the play into the summary
        this.activitySummary.progress.page.push(
          {
            id: pageId,
            pct: perc,
          },
        );

        sendEvent = true;
      }

      // send the event
      if (sendEvent) {
        this[types.SEND_EVENT]({
          eventType: 'page.view', referenceId: pageId, referenceType: 'page', value: perc,
        });
      }

      // store
      this[types.STORE_ANONYMOUS_SUMMARY](this.activitySummary);
    },
    [types.SET_PROGRESS_TRACKING_DATA](data) {
      this.progressTracking = data;
    },
    [types.SET_EVENTS_ENABLED](enabled) {
      this.eventsEnabled = enabled;
    },
    [types.DISABLE_ALL_EVENTS]() {
      // set events enabled
      this.eventsEnabled = false;

      // disable progress tracking
      if (this.progressTracking?.enabled) {
        this.progressTracking.enabled = false;
      }

      // turn off session tracking
      this[types.END_SESSION_TRACKING]();
    },
    [types.SEND_EVENT](
      {
        eventType,
        referenceId,
        referenceType,
        value,
        queueable = true,
      },
    ) {
      const appStore = useAppStore();
      const userStore = useUserStore();
      const editorStore = useEditorStore();
      const assetStore = useAssetStore();
      const assessmentStore = useAssessmentStore();

      // events must be enabled for progress tracking to work
      if (this.progressTracking?.enabled && !this.eventsEnabled) {
        this.eventsEnabled = true;
      }

      // if both events and progress tracking are disabled,
      // or if there's no user, no reason to continue
      if (
        (!userStore.user && !this.isAnonymous)
        || (!this.eventsEnabled && !this.progressTracking?.enabled)
      ) {
        return;
      }

      if (!assetStore.assetsLoaded && queueable && this.eventQueue.tries < 50) {
        // start polling if we haven't already
        if (!this.eventQueue.started) {
          this[types.START_EVENT_QUEUE_POLLING]();
        }

        // if the assetStore isn't ready, push to the queue
        this.eventQueue.queue.push({
          eventType,
          referenceId,
          referenceType,
          value,
        });

        return;
      }

      if (this.isAnonymous) {
        this[types.GET_ANONYMOUS_ACTIVITY_SUMMARY]();
      }

      // Format POST data and add user id
      const userId = userStore.user?.id;
      const postData = {
        user_guid: userId,
        reference_id: referenceId, // e.g. page.id
        event_type: eventType, // e.g. 'page.view'
        reference_type: referenceType, // e.g. 'creation'
        value,
      };

      // if there is an assignment guid, add to the post data
      if (editorStore.assignmentId) {
        postData.assignment_id = editorStore.assignmentId;
      }

      if (!this.activitySummary.pages) {
        this.activitySummary.pages = [];
      }

      let progressPerc = 0;
      const sendStartedEvent = (
        this.progressTracking?.enabled && this.activitySummary.pages.length === 0
      );

      // if pageview event, store page in summary
      if (eventType === 'page.view') {
        const summary = cloneDeep(this.activitySummary);

        // make sure its in the list of pages
        summary.pages = summary.pages || [];

        if (!summary.pages.includes(referenceId)) {
          // add to the list in the clience
          summary.pages.push(referenceId);

          // apply the updated summary obj
          this[types.SET_ACTIVITY_SUMMARY](summary);
        }

        // update progress if needed
        if (Array.isArray(summary.progress?.page)) {
          const foundPageIdx = summary.progress.page.findIndex((page) => page.id === referenceId);
          if (foundPageIdx < 0) {
            summary.progress.page.push(
              {
                id: referenceId,
                pct: value,
              },
            );
            this[types.SET_ACTIVITY_SUMMARY](summary);
          } else if (summary.progress.page[foundPageIdx].pct < value) {
            summary.progress.page[foundPageIdx].pct = value;
            this[types.SET_ACTIVITY_SUMMARY](summary);
          }
        }
      }

      if (this.activitySummary?.pages) {
        // keep track of completed
        let completed = 0;

        // grab total pages viewed
        const pagesCompleted = this.activitySummary.pages.map((pageId) => {
          // if not a slide, we need to calculate view percentage
          const foundPage = editorStore.draft.pages.find((fullPage) => fullPage.id === pageId);
          if (foundPage && foundPage.options?.type !== 'slide') {
            // look for the progress value
            let pageProgress = null;

            if (this.activitySummary.progress?.page) {
              pageProgress = this.activitySummary.progress.page.find(
                (progress) => progress.id === pageId,
              );
            }
            if (pageProgress) {
              return pageProgress.pct / 100;
            }
          }

          // if none of the conditions are met, return 1
          return 1;
        });

        // grab total pages viewed
        completed = pagesCompleted.reduce((coll, val) => coll + val, 0);

        // add pages to opps
        let opportunities = editorStore.pageIds.length;

        const hasTEIs = !!(editorStore.draft.tei_page_asset_ids
          || editorStore.draftHasInteractiveModules);

        // TEI ATTEMPTED CALCULATION
        if (hasTEIs) {
          // add full slide teis to opps
          const fullSlideTeis = editorStore.draft.tei_page_asset_ids;
          if (Array.isArray(fullSlideTeis)) {
            opportunities += fullSlideTeis.length;
          }

          // add quiz modules to opps only if there is an assignment
          // quiz modules cannot be used in formative context
          if (editorStore.assignmentId) {
            opportunities += editorStore.interactiveModules.length;
          }

          // add attempted TEIs
          completed += assessmentStore.attemptedTEIs.length;
        }

        // VIDEO PLAY CALCULATION

        // get all of the asset ids in the creation
        if (!this.assetIds.length) {
          const assetIds = [];

          editorStore.draft.pages.forEach((page) => {
            if (page.options?.asset_id) {
              assetIds.push(page.options.asset_id);
            }

            page.modules.forEach((module) => {
              if (module.type === 'asset') {
                assetIds.push(module.options.asset_id);
              }
            });
          });

          this.assetIds = assetIds;
        }

        // get all of the video asset ids
        if (!this.videoIds) {
          this.videoIds = this.assetIds.reduce((videoIds, assetId) => {
            if (
              assetStore.assets[assetId]
              && assetStore.assets[assetId].group === 'video'
            ) {
              return [...videoIds, assetId];
            }
            return videoIds;
          }, []);
        }

        if (this.videoIds.length) {
          // add the number of videoIds to the opportunities
          opportunities += this.videoIds.length;

          // for each video, add play pct to opportuniites
          this.videoIds.forEach((video) => {
            const foundMedia = this.activitySummary.media?.find((media) => media.id === video);
            if (foundMedia) {
              // add the viewed pct to the completed opportunities
              completed += (foundMedia.pct / 100);
            }
          });
        }

        progressPerc = Math.round((completed / opportunities) * 100);
      }

      // if tracking enabled
      if (this.progressTracking?.enabled) {
        if (sendStartedEvent) {
          this[types.TRACK_PROGRESS]({ eventName: 'started', progressPerc });
        }

        // send view tracking event
        if (eventType === 'page.view') {
          this[types.TRACK_PROGRESS]({ eventName: 'slide-viewed', progressPerc });
        } else if (eventType === 'tei.update') {
          this[types.TRACK_PROGRESS]({ eventName: 'item-submitted', progressPerc });
        } else if (['attempt-started', 'attempt-submitted'].includes(eventType)) {
          this[types.TRACK_PROGRESS]({ eventName: eventType, progressPerc });
        }
      }

      // if studio events enabled, send event
      if (this.eventsEnabled) {
        // list of events for which we don't send studio events
        const excludedEvents = ['tei.update', 'attempt-started', 'attempt-submitted'];

        if (!excludedEvents.includes(eventType) && !this.isAnonymous) {
          this.api.post('/events/',
            { event: postData },
            {
              headers: {
                'X-Token': appStore.apiToken,
              },
            });
        }

        bus.emit(eventType, postData);

        // send a 'progress' event
        if (
          progressPerc !== null
          && this.activitySummary?.progress
          && this.activitySummary?.progress.creation < progressPerc
        ) {
          // update internal progress
          this.activitySummary.progress.creation = progressPerc;

          const progressPostData = {
            user_guid: userId,
            reference_id: editorStore.draftAssetId(),
            event_type: 'progress',
            reference_type: 'creation',
            value: progressPerc,
          };

          if (editorStore.assignmentId) {
            progressPostData.assignment_id = editorStore.assignmentId;
          }

          // send progress event
          if (!this.isAnonymous) {
            this.api.post('/events/',
              { event: progressPostData },
              {
                headers: {
                  'X-Token': appStore.apiToken,
                },
              });
          }

          bus.emit(eventType, progressPostData);

          // store
          this[types.STORE_ANONYMOUS_SUMMARY](this.activitySummary);
        }
      }
    },
    /**
     * send segment event for progress tracking
     */
    async [types.TRACK_PROGRESS](payload) {
      const editorStore = useEditorStore();
      const assessmentStore = useAssessmentStore();
      const userStore = useUserStore();

      if (
        window.DEAnalytics
        && window.analytics
        && window.analytics.initialized
        && window.DEAnalytics.lessonProgressed
        && !userStore.userIsImpersonating
      ) {
        nextTick(() => {
          // build out the data obj
          let dataObj = {
            pageId: editorStore.currentPage.id,
            totalSlides: editorStore.draft.pages.length,
            slideIndex: editorStore.currentPageIndex,
            embeddedAssetIds: [],
            assetId: editorStore.draftAssetId(),
            pageSubtypeId: null,
            progressPerc: payload.progressPerc || 0,
            attemptId: assessmentStore.attempt?.id,
            progressStatus: payload.eventName,
            source: this.progressTracking?.source,
            assignmentId: editorStore.assignmentId || null,
            sessionId: this.sessionId,
            gradeIds: editorStore.draft.grades?.map((grade) => grade.id) || [],
          };

          // grab the embedded assets
          if (editorStore.currentPage) {
            const embeddedIds = editorStore.currentPage.modules.map(
              module => module.options.asset_id,
            ).filter(
              (item) => !!item,
            ) || [];

            dataObj.embeddedAssetIds = embeddedIds;
          }

          // no embedded assets? check for a full slide asset
          if (!dataObj.embeddedAssetIds.length && editorStore.currentPage?.options?.asset_id) {
            dataObj.embeddedAssetIds = [editorStore.currentPage.options.asset_id];
          }

          const hasTEIs = !!(editorStore.draft.tei_page_asset_ids
            || editorStore.draftHasInteractiveModules);

          if (hasTEIs) {
            // item with teis is a TEA
            dataObj.assetTypeId = ASSET_TYPES.ASSET_TYPE_TECHNOLOGY_ENHANCED_ASSESSMENT;

            if (editorStore.draft.options.type === 'lesson') {
              dataObj.assetSubTypeId = ASSET_SUBTYPES.ASSET_SUBTYPE_LESSON;
            } else {
              dataObj.assetSubTypeId = ASSET_SUBTYPES.ASSET_SUBTYPE_STUDIO_QUIZ;
            }
          } else if (editorStore.draft.options.type === 'slides') {
            dataObj.assetTypeId = ASSET_TYPES.ASSET_TYPE_SLIDESHOW;
          } else if (editorStore.draft.options.type === 'lesson') {
            dataObj.assetTypeId = ASSET_TYPES.ASSET_TYPE_LESSON;
          } else if (editorStore.draft.options.type === 'board') {
            dataObj.assetTypeId = ASSET_TYPES.ASSET_TYPE_BOARD;
          }

          // figure out the page type
          if (editorStore.draft.options.type === 'board') {
            dataObj.pageSubtypeId = ASSET_TYPES.ASSET_TYPE_BOARD_PAGE;
          } else {
            dataObj.pageSubtypeId = ASSET_TYPES.ASSET_TYPE_SLIDE;
          }

          // merge in metadata
          if (this.progressTracking?.enabled) {
            dataObj = { ...dataObj, ...(this.progressTracking.meta || {}) };
          }

          window.DEAnalytics.lessonProgressed(dataObj);

          // check to see if progress is completed with the event
          if (dataObj.progressPerc === 100) {
            dataObj.progressStatus = 'completed';
            window.DEAnalytics.lessonProgressed(dataObj);
          }
        });
      }
    },
    [types.SEND_CLICKLOG_EVENT]({ ids, type }) {
      // Send a clicklog event for tracking; this endpoint expects a url-encoded string.
      // There is sometimes a race condition between asset creation and this clicklog request,
      // so 404s are purposely swallowed.

      // More info about the clicklog:
      // https://docs.discoveryeducation.com/display/developers/Click+Logging

      if (!ids || !ids.length) return;

      let dataString = `asset_guids=${ids.join(',')}`;

      if (type) {
        dataString += `&access_type=${type}`;
      }

      this.deApi.post(
        'clicklog',
        dataString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      ).catch(() => {});
    },
  },
  getters: {
    sessionStorageKey() {
      const editorStore = useEditorStore();
      const asset = editorStore.draftAssetId();
      const domain = window?.location.host || 'default';

      // put together asset id and domain
      if (asset && domain) {
        return `sessionKey_${asset}_${domain}`;
      }
      return false;
    },
  },
});

export default useTrackingStore;
