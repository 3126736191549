import { defineStore } from 'pinia';
import Deferred from '@/lib/deferred';
import * as types from '@/lib/constants/store';

const useConfirmationStore = defineStore('studio-confirmation', {
  state: () => ({
    confirmations: [],
  }),
  actions: {
    /**
     * Runs all confirmations in the store sequentially for a specified action.
     * A single confirmation can apply to many actions.
     * If any confirmations are rejected, then the action is denied.
     * While any confirmation is pending (waiting for a response), then all subsequent
     * calls to the action will be denied until the confirmation is resolved.
     * e.g. If a confirmation is pending, showing a dialog to confirm navigating to a new page
     * but hasn't been resolved or rejected yet, then all attempts to navigate to a new page
     * will be denied until the confirmation is resolved.
     * @param {String} action Unique identifier for an action taken in the app.
     * @returns A value that indicates whether the action is confirmed or denied.
     */
    async [types.CONFIRM_ACTION](action) {
      let confirmed = true;

      const confirmations = this.confirmations.filter((c) => c.actions.has(action));

      for (let index = 0; index < confirmations.length; index += 1) {
        const { deferred } = confirmations[index];

        try {
          /*
            Any action will be denied while there is a pending confirmation
            for that action that is waiting for a response.
          */
          if (deferred.state === Deferred.PENDING) {
            confirmed = false;
          } else {
            // eslint-disable-next-line no-await-in-loop
            confirmed = await deferred.promise();
          }
        } catch (e) {
          confirmed = false;
        }

        if (!confirmed) {
          break;
        }
      }

      return confirmed;
    },
    [types.ADD_CONFIRMATION](confirmation) {
      const existing = this[types.GET_CONFIRMATION](confirmation.id);
      if (!existing) {
        this.confirmations.push(confirmation);
      }
    },
    [types.REMOVE_CONFIRMATION](id) {
      const index = this.confirmations.findIndex((c) => c.id === id);
      if (index !== -1) {
        this.confirmations.splice(index, 1);
      }
    },
    [types.GET_CONFIRMATION](id) {
      return this.confirmations.find((c) => c.id === id);
    },
    [types.CLEAR_CONFIRMATIONS]() {
      this.confirmations = [];
    },
  },
});

export default useConfirmationStore;
