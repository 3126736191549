import axios from 'axios';
import { v4 as uuid } from 'uuid';
import * as Y from 'yjs';
import { get, merge } from 'lodash-es';
import yjsHelper from './yjs-helper';
import {
  SharedDocumentPollingError,
  SharedDocumentGetError,
  SharedDocumentSaveError,
} from './errors';

/**
 * The time to wait between save requests to the server.
 */
const SAVE_THROTTLE_TIME = 1500;
/**
 * How often the session should check the server for changes.
 */
const CHECK_SERVER_CHANGES_INTERVAL = 10000;
/**
 * Special identifier for a transaction created by a remote
 * SharedSession instance.
 */
const REMOTE_ORIGIN = '__remote__transaction__';

/**
 * Represents a live editing session for a specified SharedDocument. Handles synchronizing
 * and merging all document changes to all users connected to this editing session.
 * Only one SharedSession should be active at any given time in the SharedDocument.
 * While it's possible for multiple users to create a session, the SharedDocument
 * should eventually activate the one that was created first, and close the others.
 */
export default class SharedSession {
  /**
   * Creates a new SharedSession object.
   * @param {SharedDocument} sharedDocument The SharedDocument this session belongs to.
   */
  constructor(sharedDocument) {
    this.sessionKey = null;
    this._sharedDocument = sharedDocument;
    this._document = null;
    this._isSaving = false;
    this._hasUnsavedChanges = false;
    this._saveTimeout = null;
    this._checkServerForChangesState = {
      cancelToken: null,
      timeout: null,
    };
  }

  /**
   * Opens a new live editing session. This creates a new session key for this
   * so should be called when creating a new SharedSession when no active session
   * key already exists.
   */
  async open() {
    this.sessionKey = this._createSessionKey();
    const sourceDocument = await this._sharedDocument.options.getDocument();
    this._document = new Y.Doc();
    const ySharedDocument = this._document.getMap('shared-document');
    // Convert the sourceDocument to a Yjs object and insert into the document.
    yjsHelper.toY(sourceDocument, 'document', ySharedDocument);
    /*
      Ensure that we wait for the session to be saved to the server and
      all needed channels are open before binding to events.
    */
    if (this._isShared) {
      await Promise.all([
        this._save(),
        this._openDocumentChannel(),
        this._openDocumentUserChannel(),
      ]);
      this._bindDocumentChangeEvents();
      this.checkServerForChanges();
    }
  }

  /**
   * Loads an already active session from the specified session key.
   * @param {Object} sessionKey Active session key to load from the server.
   */
  async loadFromKey(sessionKey) {
    this.sessionKey = sessionKey;
    /*
      Ensure that we wait for the session to be loaded from the server and
      all needed channels are open before binding to events.
    */
    await Promise.all([
      this._load(),
      this._openDocumentChannel(),
      this._openDocumentUserChannel(),
    ]);
    this._bindDocumentChangeEvents();
    this._requestMissingChanges();
    this.checkServerForChanges();
  }

  /**
   * Loads a session from a previous session's saved state.
   * @param {Object} sessionState A previous sessions's state object.
   * @param {Object} sessionState.sessionKey The session key for previous state.
   * @param {Uint8Array} sessionState.documentBytes Document's binary data.
   */
  async loadFromState(sessionState) {
    this.sessionKey = sessionState.sessionKey;
    this._document = new Y.Doc();
    Y.applyUpdate(this._document, sessionState.documentBytes);
    // Wait until channels are open before binding to events.
    await Promise.all([
      this._openDocumentChannel(),
      this._openDocumentUserChannel(),
    ]);
    this._bindDocumentChangeEvents();
    this._requestMissingChanges();
    this.checkServerForChanges();
  }

  /**
   * Closes the editing session.
   */
  close() {
    this._channels.closeChannel(this._documentChannelName);
    this._channels.closeChannel(this._documentUserChannelName);
    this.sessionKey = null;
    this._document = null;
    this._sharedDocument = null;
    this._isSaving = false;
    this._hasUnsavedChanges = false;
    this._clearSaveTimeout();
    this._stopCheckServerForChanges();
  }

  /**
   * Applies a group of changes to the session document as a single transaction.
   * @param {function} func A function that will apply changes to the document.
   * @param {string} transactionOrigin A name for the transaction.
   */
  change(func, transactionOrigin) {
    if (!this._document) return;
    this._document.transact(() => {
      func(this.document);
    }, transactionOrigin);
  }

  /**
   * Gets the current state object for this session. Can be used reopen an existing
   * session at a later date.
   */
  getState() {
    if (!this.sessionKey || !this._document) return null;
    return {
      sessionKey: this.sessionKey,
      documentBytes: Y.encodeStateAsUpdate(this._document),
    };
  }

  /**
   * Starts a process that will periodically compare the local document to the
   * document on the server and sync any changes if needed.
   */
  async checkServerForChanges() {
    const state = this._checkServerForChangesState;
    // If this process is already running, cancel before starting again.
    if (state.cancelToken) {
      state.cancelToken.cancel();
      state.cancelToken = null;
    }
    if (state.timeout) {
      clearTimeout(state.timeout);
      state.timeout = null;
    }
    let canceled = false;
    let hasError = false;
    try {
      if (this.sessionKey && this._document) {
        state.cancelToken = axios.CancelToken.source();
        const response = await this._getDocumentFromServerIfHasChanges(
          { cancelToken: state.cancelToken.token },
        );

        const serverHasChanges = response.status === 200 && !!get(response, 'data.byteLength');
        if (serverHasChanges) {
          /*
            If we found changes on the server that don't exist locally (or vise versa), then
            sync changes and distribute to other clients if needed.
          */
          const serverDocument = new Y.Doc();
          Y.applyUpdate(serverDocument, new Uint8Array(response.data));
          // Changes on server that don't exist locally.
          const serverChanges = yjsHelper.getMissingChanges(this._document, serverDocument);
          // Changes that exist locally but not on the server.
          const localChanges = yjsHelper.getMissingChanges(serverDocument, this._document);
          const saveNeeded = serverChanges || localChanges;
          if (serverChanges) {
            Y.applyUpdate(this._document, serverChanges);
          }
          if (localChanges) {
            this._distributeChanges(localChanges);
          }
          // If we're the host, flag changes needed to be saved.
          if (this.isHost && saveNeeded) this._setHasUnsavedChanges();
        }
      }
    } catch (e) {
      canceled = axios.isCancel(e);
      if (!canceled) {
        hasError = true;
        /*
          If we encounter an error while polling the server for changes,
          stop polling, emit the error, and close the current editing session.
        */
        const sharedDocError = new SharedDocumentPollingError(e);
        this._stopCheckServerForChanges();
        this._sharedDocument.destroy();
        this._sharedDocument._emitError(sharedDocError);
      }
    }
    /*
      If this request was not canceled and didn't encounter an error,
      then continue polling the server for changes.
    */
    if (!canceled && !hasError) {
      state.timeout = setTimeout(
        this.checkServerForChanges.bind(this),
        CHECK_SERVER_CHANGES_INTERVAL,
      );
      state.cancelToken = null;
    }
  }

  /**
   * Gets the YDoc or YArray version of the document that was initially loaded
   * into the session via the SharedDocument.
   */
  get document() {
    if (!this._document) return null;
    return this._document.getMap('shared-document').get('document');
  }

  /**
   * Determines if the session has outstanding changes that need to be saved
   * or are in the process of saving. You probably don't want to close the browser
   * while this is returning true or changes may be lost.
   */
  get hasUnsavedChanges() {
    return this._hasUnsavedChanges || this._isSaving;
  }

  /**
   * Determines if this session if for the host user.
   */
  get isHost() {
    return get(this, '_sharedDocument.isHost', false);
  }

  /**
   * Determines if this session should sync changes to other users.
   */
  get _isShared() {
    return this._sharedDocument.isShared;
  }

  /**
   * Returns the unique identifier for the current connected user.
   */
  get _currentUserId() {
    return this._sharedDocument._currentUserId;
  }

  /**
   * Returns the list of channels from the SharedDocument.
   */
  get _channels() {
    return this._sharedDocument._channels;
  }

  /**
   * Returns the SharedEvent object from the SharedDocument.
   */
  get _sharedEvent() {
    return this._sharedDocument._sharedEvent;
  }

  /**
   * Returns the configured axios client from the SharedDocument.
   */
  get _axiosClient() {
    return this._sharedDocument.options.axiosClient;
  }

  /**
   * Returns the full name of the document channel used for this session.
   */
  get _documentChannelName() {
    return this._channels.getChannelName(`session-${this.sessionKey.key}`);
  }

  /**
   * Returns the channel used to pass messages pertaining to the document
   * currently loaded in this session.
   */
  get _documentChannel() {
    return this._channels.get(this._documentChannelName);
  }

  /**
   * Returns the full name of the user specific channel created for this session document.
   */
  get _documentUserChannelName() {
    return this._channels.getChannelName(`session-${this.sessionKey.key}-${this._currentUserId}`);
  }

  /**
   * Returns the channel that is specific to the current user and the document
   * loaded inthis session.
   */
  get _documentUserChannel() {
    return this._channels.get(this._documentUserChannelName);
  }

  /**
   * Returns the full url where this document will be stored.
   */
  get _documentUrl() {
    return this._getDocumentUrl(`shared_document/${this.sessionKey.key}`);
  }

  /**
   * Returns the full url of where this session will check for document changes.
   */
  get _documentHasChangesUrl() {
    return this._getDocumentUrl(`shared_document/${this.sessionKey.key}/changes`);
  }

  /**
   * Builds the full document url from a given path.
   * @param {string} path Path part of the document url.
   */
  _getDocumentUrl(path) {
    const url = this._sharedDocument._utils.url(this._sharedDocument.options.baseUrl, path);
    return `${url}?timestamp=${(new Date()).getTime()}`;
  }

  /**
   * Creates a new session key for this session.
   */
  _createSessionKey() {
    return {
      key: uuid(),
      connectionTime: this._sharedDocument.currentUser.connectionTime,
    };
  }

  /**
   * Opens the document channel specific to this session.
   */
  _openDocumentChannel() {
    return this._channels.openChannel(this._documentChannelName, (channel) => {
      /*
        Enter the presence for the document channel. This is needed to track the users
        connected to an individual session so that we can subscribe to Ably webhooks
        in order to know when the session document is no longer needed.
      */
      channel.presence.enter({}, this._sharedDocument._emitError.bind(this));
      this._subscribeDocumentChannelEvents();
    });
  }

  /**
   * Opens the user channel that is specific to the document loaded in this session.
   */
  _openDocumentUserChannel() {
    return this._channels.openChannel(this._documentUserChannelName, () => {
      this._subscribeDocumentUserChannelEvents();
    });
  }

  /**
   * Validates incoming SharedMessage objects to ensure they are for this session.
   * @param {SharedMessage} message Incoming message from a remote user.
   */
  _messageIsValid(message) {
    if (!this._document) return false;
    if (message.payload.sessionKey.key !== this.sessionKey.key) return false;
    return true;
  }

  /**
   * Bind events that will listen for changes to the document loaded in this session.
   */
  _bindDocumentChangeEvents() {
    if (this._document) {
      this._document.on('update', function (changes, origin) {
        if (origin !== REMOTE_ORIGIN) {
          /*
            If the trasaction didn't come from a remote user, then distribute
            the changes to all connected users.
          */
          this._distributeChanges(changes);
        }
        if (this.isHost) this._setHasUnsavedChanges();
      }.bind(this));
    }
  }

  /**
   * Distributes a set of document changes to all users currently connected
   * to this session.
   * @param {Uint8Array} changes Yjs changeset to be applied on remote instances
   * of the current session.
   */
  _distributeChanges(changes) {
    if (!this._sharedDocument._otherConnectedUsers.length) return;
    if (yjsHelper.updateHasChanges(changes)) {
      const message = this._sharedDocument._createMessage('document-changes', {
        remoteClock: yjsHelper.getClock(this._document),
        sessionKey: this.sessionKey,
      }, changes);
      this._sharedEvent.publish(this._documentChannel, message);
    }
  }

  /**
   * Attaches event handlers listening for incoming messages on the document
   * channel for this session.
   */
  _subscribeDocumentChannelEvents() {
    const documentChannel = this._documentChannel;
    /*
      Listen for any remote document changes coming in. If after applying the changes,
      the local clock is still behind, request missing changes from all connected users.
    */
    this._sharedEvent.subscribe(documentChannel, 'document-changes', (sharedMessage) => {
      if (!this._messageIsValid(sharedMessage)) return;
      Y.applyUpdate(this._document, sharedMessage.binaryPayload, REMOTE_ORIGIN);
      const { remoteClock } = sharedMessage.payload;
      const localClock = yjsHelper.getClock(this._document);
      if (yjsHelper.clockIsBehind(localClock, remoteClock)) {
        this._requestMissingChanges();
      }
    });
    /*
      Listen for any users requesting missing changes. Compare remote state against the local
      and if there are differences, send the changes to the requesting user.
    */
    this._sharedEvent.subscribe(documentChannel, 'document-request-missing-changes', (sharedMessage) => {
      if (!this._messageIsValid(sharedMessage)) return;
      const remoteStateVector = sharedMessage.binaryPayload;
      const changes = Y.encodeStateAsUpdate(this._document, remoteStateVector);
      if (yjsHelper.updateHasChanges(changes)) {
        const channel = this._channels.get(sharedMessage.payload.documentUserChannelName);
        const message = this._sharedDocument._createMessage('document-receive-missing-changes', {
          sessionKey: this.sessionKey,
        }, changes);
        this._sharedEvent.publish(channel, message);
      }
    });
  }

  /**
   * Attaches event handlers listening for incoming messages on the user channel that is specific to
   * the document currently loaded in the session.
   */
  _subscribeDocumentUserChannelEvents() {
    this._sharedEvent.subscribe(this._documentUserChannel, 'document-receive-missing-changes', (sharedMessage) => {
      if (!this._messageIsValid(sharedMessage)) return;
      Y.applyUpdate(this._document, sharedMessage.binaryPayload, REMOTE_ORIGIN);
    });
  }

  /**
   * Requests any changes that exist on remote instances, but not local. Sends the current
   * state to remote clients and if there are any missing changes they will send them back.
   */
  _requestMissingChanges() {
    const localStateVector = Y.encodeStateVector(this._document);
    const message = this._sharedDocument._createMessage('document-request-missing-changes', {
      documentUserChannelName: this._documentUserChannelName,
      sessionKey: this.sessionKey,
    }, localStateVector);
    this._sharedEvent.publish(this._documentChannel, message);
  }

  /**
   * Loads a document from the server by its session key and loads into the current session.
   */
  async _load() {
    const response = await this._getDocumentFromServer();
    this._document = new Y.Doc();
    Y.applyUpdate(this._document, new Uint8Array(response.data), REMOTE_ORIGIN);
  }

  /**
   * Gets a document from the server by its session key.
   * @param {Object} options Axios options for the request.
   */
  async _getDocumentFromServer(options) {
    const requestOptions = merge({
      responseType: 'arraybuffer',
      withCredentials: true,
    }, options);

    try {
      return await this._axiosClient.get(this._documentUrl, requestOptions);
    } catch (e) {
      this._sharedDocument._emitError(new SharedDocumentGetError(e));
      throw e;
    }
  }

  /**
   * Gets the document from the server only if there are differences. Sends the
   * current state to the server so state can be compared. If there are changes, the
   * server will send back the latest document.
   * @param {Object} options Axios options for the request.
   */
  _getDocumentFromServerIfHasChanges(options) {
    const requestOptions = merge({
      responseType: 'arraybuffer',
      withCredentials: true,
      headers: {
        'X-Token': this._sharedDocument.options.userToken,
      },
    }, options);

    return this._axiosClient.post(this._documentHasChangesUrl, {
      clock: yjsHelper.getClock(this._document),
    }, requestOptions);
  }

  /**
   * Saves the current document state to the server. This will be called anytime there
   * are known changes to the local document.
   */
  async _save() {
    if (!this._document) return;
    try {
      this._isSaving = true;
      const headers = {
        'Content-Type': 'multipart/form-data',
        'X-Token': this._sharedDocument.options.userToken,
      };

      let documentAsUpdate = null;
      try {
        if (this._sharedDocument.options.useCompression) {
          /*
            If compression is turned on, gzip the document and add the appropriate
            headers before saving to the server.
          */
          documentAsUpdate = await this._sharedDocument._utils
            .gzip(this.getState().documentBytes.buffer);
          headers['Content-Encoding'] = 'gzip';
        } else {
          documentAsUpdate = this.getState().documentBytes;
        }
      } catch (error) {
        documentAsUpdate = this.getState().documentBytes;
      }
      const formData = new FormData();
      formData.append('references', JSON.stringify(this._sharedDocument.options.references));
      formData.append('app_id', this._sharedDocument.options.appId);
      formData.append('shared-document', new Blob([documentAsUpdate]));
      formData.append('version', yjsHelper.version);
      formData.append('clock', JSON.stringify(yjsHelper.getClock(this._document)));
      formData.append('resource_id', this._sharedDocument.options.resourceId);
      await this._axiosClient.put(
        this._documentUrl,
        formData,
        {
          headers,
          withCredentials: true,
        },
      );
    } catch (e) {
      this._sharedDocument._emitError(new SharedDocumentSaveError(e));
      throw e;
    } finally {
      this._isSaving = false;
    }
  }

  /**
   * Flags this session as dirty which will trigger this session to
   * save its state to the server.
   */
  _setHasUnsavedChanges() {
    this._hasUnsavedChanges = true;
    // If the save loop isn't already running, start it.
    if (this.isHost && !this._saveTimeout && !this._isSaving) {
      this._setSaveTimeout();
    }
  }

  /**
   * Sets a timeout that will cause save operations to happen on
   * an interval. This essentially throttles save operations to the
   * server.
   */
  _setSaveTimeout() {
    if (this.isHost) {
      this._saveTimeout = setTimeout(function onSaveTimeout() {
        if (this._hasUnsavedChanges) {
          this._hasUnsavedChanges = false;
          this._save()
            .catch(() => {}) // Swallow error here.
            .finally(() => {
              this._setSaveTimeout();
            });
        } else {
          this._setSaveTimeout();
        }
      }.bind(this), SAVE_THROTTLE_TIME);
    } else {
      this._clearSaveTimeout();
      this._hasUnsavedChanges = false;
    }
  }

  /**
   * Stop this session from saving its state to the server.
   */
  _clearSaveTimeout() {
    if (this._saveTimeout) {
      clearTimeout(this._saveTimeout);
      this._saveTimeout = null;
    }
  }

  /**
   * Stop this session from periodically checking the server for changes.
   */
  _stopCheckServerForChanges() {
    const { cancelToken, timeout } = this._checkServerForChangesState;
    if (timeout) clearTimeout(timeout);
    if (cancelToken) cancelToken.cancel();
    this._checkServerForChangesState = {
      cancelToken: null,
      timeout: null,
    };
  }
}
