import { defineStore } from 'pinia';
import {
  get, chunk, without,
} from 'lodash-es';
import {
  useErrorStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

const useUploadStore = defineStore('studio-upload', {
  state: () => ({
    uploads: {},
  }),
  actions: {
    [types.CACHE_UPLOADS](uploadIds) {
      const errorStore = useErrorStore();
      // Send upload requests in batches of 30
      const uploadIdChunks = chunk(uploadIds, 30);

      uploadIdChunks.forEach((ids) => {
        const uploadQuery = ids.map((id) => `upload_id=${id}`).join('&');
        this.api.get(`/uploads/?${uploadQuery}`)
          .then((res) => {
            if (res.status !== 200) throw res;
            res.data.uploads.forEach((upload) => {
              this.uploads[upload.id] = upload;
            });

            // The bulk upload api is good for returning lots of uploads at once, but it
            // doesn't include error messages for the ones it couldn't find. We need to
            // get those manually
            const receivedIds = res.data.uploads.map((upload) => upload.id);
            without(ids, ...receivedIds).forEach((missingId) => {
              this[types.CACHE_UPLOAD](missingId);
            });
          }).catch((result) => {
            errorStore[types.SET_ERROR]({
              active: true,
              error: result.response.data,
            });
          });
      });
    },
    async [types.CACHE_UPLOAD](uploadId) {
      if (!uploadId) return;

      try {
        const result = await this.api.get(`/uploads/${uploadId}`);
        if (result.status !== 200) throw result;
        const { upload } = result.data;
        this.uploads[upload.id] = upload;
      } catch (error) {
        // Display either the error message from Python or the Axios error itself
        const upload = {
          error: get(error, 'response.data.meta', error),
          id: uploadId,
        };
        this.uploads[upload.id] = upload;
      }
    },
  },
});

export default useUploadStore;
