import {
  cloneDeep, get, isArray, isPlainObject,
} from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { interactiveModuleTypes } from '@/lib/constants';
import i18next from '@/lib/i18next';
import { yjsHelper } from '@/shared-document';
import { formatDate } from '@/lib/utils';
import { THEME_DEFAULTS } from '@/lib/constants/themeDefaults';

/**
 * Gets the default draft name for a specified draft type
 * @param {string} type - Type of draft e.g. 'slides', 'board', 'lesson'
 */
export function defaultDraftName(type) {
  const dateString = formatDate(new Date(), true);
  let typeLabel = i18next.t('Slideshow');

  if (type === 'board') {
    typeLabel = i18next.t('Board');
  } else if (type === 'lesson') {
    typeLabel = i18next.t('Lesson');
  }

  return `${typeLabel} ${dateString}`;
}

// This is a (stub) function to force the store to use a new instance each time
export function newPageTemplate(draftType) {
  const id = uuid();
  const options = {};

  // Lessons and slideshows don't use the grid.
  if (draftType !== 'board') {
    options.grid_disabled = true;
  }

  return {
    id,
    title: draftType === 'board' ? i18next.t('New page') : i18next.t('New slide'),
    modules: [],
    notes: [],
    options,
    sort_index: 1,
  };
}

export function newModalCanvasTemplate() {
  const id = uuid();
  const options = {
    grid_disabled: true, // modals do not use a grid
  };

  return {
    id,
    title: i18next.t('New modal'), // modals do not currently have names
    modules: [],
    notes: [],
    options,
    sort_index: 1,
  };
}

// When duplicating a page, create a new title based on the original page's title
// Note: the UI displays falsey page titles as "Page 1" (etc.) on its own
export function newPageTitle(oldPageTitle) {
  const copy = i18next.t('copy');
  const regexAdd2 = new RegExp(`${copy}$`);
  const regexAddCopy = new RegExp(`${copy} (\\d+)$`);
  const regexNumber = new RegExp('\\d+$');
  if (!oldPageTitle || typeof oldPageTitle !== 'string') return '';

  if (oldPageTitle.match(regexAdd2)) {
    // Add " 2" to the old page title, e.g. "Foo copy" -> "Foo copy 2"
    return i18next.t('%(oldPageTitle)s 2', { oldPageTitle });
  }

  const copyNumberMatch = oldPageTitle.match(regexAddCopy);
  if (!copyNumberMatch) {
    // Add " copy" to the old page title, e.g. "Foo" -> "Foo copy"
    return i18next.t('%(oldPageTitle)s copy', { oldPageTitle });
  }

  // Otherwise increment the page title's number, e.g. "Foo copy 2" -> "Foo copy 3"
  const incrementedPageNumber = parseInt(copyNumberMatch[1], 10) + 1;
  const newPageTitleAndNumber = oldPageTitle.replace(regexNumber, incrementedPageNumber);
  return i18next.t('%(count)s', { count: newPageTitleAndNumber });
}

// This is a (stub) function to force the store to use a new instance each time
export function newModuleTemplate(mod) {
  const id = mod.options?.id || uuid(); // generate new id for module if one hasn't been created
  const newMod = {
    id,
    type: mod.type,
    name: mod.name,
    layout: mod.layout,
    options: mod.options,
    meta: {
      updated: '2020-01-14T20:28:10.242Z', // Placeholders
      created: '2020-01-14T20:28:10.242Z', // Placeholders
    },
  };

  // deep copy
  return JSON.parse(JSON.stringify(newMod));
}

/**
 * get the page index for the given module in the draft
 * @param {object} draft
 * @param {string} moduleId
 */
export function getPageIndexByModuleId(draft, moduleId) {
  return draft.pages.findIndex((page) => (
    [...page.modules, ...(page.notes || [])].find((mod) => mod.id === moduleId)
  ));
}

/**
 * get the modal index for the given module in the draft
 * @param {object} draft
 * @param {string} moduleId
 */
export function getModalIndexByModuleId(draft, moduleId) {
  return draft.modals.findIndex((modal) => (
    (modal.modules || []).find((mod) => mod.id === moduleId)
  ));
}

/**
 * get full module data by id
 * @param {object} draft
 * @param {string} id
 */
export function getModuleById(draft, id) {
  let mod;

  // Loop through pages and modal canvases
  [...draft.pages, ...draft.modals].forEach((page) => {
    // Search the page's modules and notes
    [...page.modules, ...(page.notes || [])].forEach((pageMod) => {
      if (pageMod.id === id) {
        mod = pageMod;
      }
    });
  });

  return mod;
}

/**
 * Validate whether a draft can be parsed by the grid library
 * @param {object} draft - The draft or creation to test
 * @returns {boolean} Whether all modules have valid layouts
 */
export function validateModuleLayouts(draft, type = 'draft') {
  // users need to see something even if there is a layout issue. Instead of showing
  // an error we apply some layout defaults and log the error in New Relic
  if (window?.NREUM) {
    draft.pages.forEach((page, pageIdx) => {
      const checkModules = (modules, modType) => {
        modules.forEach((mod, modIdx) => {
          if (!(
            mod.layout
            && typeof mod.layout.x_position === 'number'
            && typeof mod.layout.y_position === 'number'
            && typeof mod.layout.width === 'number'
            && typeof mod.layout.height === 'number'
          )) {
            const attrs = {
              customAttrArtifactType: type,
              customAttrArtifactId: draft.id,
              customAttrPageIdx: pageIdx,
              customAttrPageId: page.id,
              customAttrModuleIdx: modIdx,
              customAttrLayout: mod.layout,
              customAttrModuleType: modType,
            };

            try {
              // exceptions must be thrown in order to generate a stack track in NR
              throw new Error('Custom Error: Missing module layout');
            } catch (e) {
              window.NREUM.noticeError(
                e,
                attrs,
              );
            }
          }
        });
      };

      // apply for modules
      checkModules(page.modules, 'modules');

      if (page.notes?.length) {
        // if present, apply for notes
        checkModules(page.notes, 'notes');
      }
    });
  }
}

/**
 * TEI responses are stringified in the database. Rather than parsing and stringifying them
 * each time they update, just parse them once
 * @param {object} attempt - Assessment attempt from the api
 */
export function parseAssessmentAttempt(attempt) {
  if (attempt && attempt.items) {
    return {
      ...attempt,
      items: attempt.items.map((item) => ({
        ...item,
        try: {
          ...item.try,
          response: item.try.response ? JSON.parse(item.try.response) : '',
        },
      })),
    };
  }

  return attempt;
}

/**
 * A draft or creation is considered a Studio Quiz if it has even one interactive module.
 * Interactive modules are not allowed in the Notes canvas, so only the main canvas is tested.
 * @param {object} draft - Draft or creation to test
 * @returns {boolean} Whether the draft has any interactive modules
 */
export function draftHasInteractiveModules(draft) {
  if (get(draft, 'pages.length')) {
    return draft.pages.some((page) => (
      page && page.modules.some((mod) => interactiveModuleTypes.indexOf(mod.type) !== -1)
    ));
  }

  return false;
}

export function getInteractiveModules(draft) {
  let modules = [];
  if (get(draft, 'pages.length')) {
    draft.pages.forEach((page) => {
      const interactiveMods = page.modules.filter(
        (mod) => interactiveModuleTypes.indexOf(mod.type) !== -1,
      );

      if (interactiveMods) {
        modules = [...modules, ...interactiveMods];
      }
    });
  }

  return modules;
}

/**
 * Sorts modules to the order expected by the editor canvas.
 * @param {Array} mods List of modules to sort.
 */
export function sortModules(mods) {
  mods.sort((a, b) => {
    if (a.layout.x_position < b.layout.x_position) {
      if (a.layout.y_position <= b.layout.y_position) {
        return -1;
      }
      return 1;
    }

    if (a.layout.x_position > b.layout.x_position) {
      if (a.layout.y_position >= b.layout.y_position) {
        return 1;
      }
      return -1;
    }

    if (a.layout.y_position < b.layout.y_position) { // x coords are the same
      return -1;
    }
    return 1;
  });

  return mods;
}

/**
 * use current theme object default values or default values
*/
export function getThemeDefaults(themeName) {
  return THEME_DEFAULTS[themeName] || THEME_DEFAULTS.default;
}

/**
 * add classes to word tracking base theme select
*/
export function wordTrackingDefault(defaultFont) {
  return [
    { class: defaultFont, key: 'font' },
    { class: 'weight-500', key: 'weight' },
    { class: 'letter-spacing-0-075', key: 'letter-spacing' },
    { class: 'line-height-150', key: 'line-height' },
    { class: 'word-spacing-0-3', key: 'word-spacing' },
  ];
}

/**
 * Removes word tracking from the specified module.
 * @param {Object} modToUpdate Module to remove word tracking from.
 */
export function removeWordTracking(modToUpdate) {
  const mod = modToUpdate;
  mod.options.word_tracking = undefined;
  if (mod.type === 'text' && mod.options.content) {
    const content = JSON.parse(mod.options.content);

    const replaceClassesExp = wordTrackingDefault()
      .map((item) => `${item.key}-[0-9-]+`)
      .join('|');

    const replaceClasses = (classes) => (classes || '')
      .replace(new RegExp(replaceClassesExp, 'g'), '')
      .replace(/\s+/g, ' ');

    /*
      Removes all word tracking specific class attributes
      from document nodes.
    */
    const removeWordTrackingClasses = (docNode) => {
      const node = docNode;
      if (isPlainObject(node)) {
        const attrClasses = node?.attrs?.class;
        if (attrClasses) {
          node.attrs.class = replaceClasses(attrClasses);
        }
        if (isArray(node.content)) {
          node.content.forEach((item) => removeWordTrackingClasses(item));
        }
      }
      return node;
    };

    mod.options.content = JSON.stringify(removeWordTrackingClasses(content));
  }
}

/*
  YDoc paths to match Yjs events against.
*/
const DRAFT_PATH = new RegExp('^$');
const DRAFT_OPTIONS_PATH = new RegExp('^options$');
const DRAFT_SECTION_PATH = new RegExp('^options/groups');
const DRAFT_METADATA_PATH = new RegExp('^metadata$');
const MODULE_PATH = new RegExp('^pages/[0-9]+/(modules|notes)/[0-9]+$');
const MODULES_PATH = new RegExp('^pages/[0-9]+/(modules|notes)$');
const PAGES_PATH = new RegExp('^pages$');
const PAGE_PATH = new RegExp('^pages/[0-9]+$');
const PAGE_OPTIONS_PATH = new RegExp('^pages/[0-9]+/options$');
const MODALS_PATH = new RegExp('^modals$');
const MODAL_MODULE_PATH = new RegExp('^modals/[0-9]+/modules/[0-9]+$');
const MODAL_MODULES_PATH = new RegExp('^modals/[0-9]+/modules$');

/**
 * Gets the Y change event path as a string.
 * @param {(YMapEvent|YArrayEvent)} change The Yjs change event to get the path from.
 */
export function changePath(change) {
  return change.path.join('/');
}

/**
 * Determines if the path of this change tests true against the given path expression.
 * @param {(YMapEvent|YArrayEvent)} change The Yjs change event to check.
 * @param {RegExp} pathExp Regular expression to test the change path against.
 */
export function changeMatchesPath(change, pathExp) {
  return pathExp.test(changePath(change));
}

/**
 * Determines if a YMapEvent changes represents an update operation.
 * @param {YMapEvent} change The YMapEvent raised.
 */
export function changeIsUpdate(change) {
  return change.changes.keys.size || get(change, 'keysChanged.size');
}

/**
 * Determines if a Yjs change event represents an UPDATE_MODULE operation
 * in the notes or modules canvas.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateModule(change) {
  return changeMatchesPath(change, MODULE_PATH)
    && !change.changes.added.size
    && !change.changes.deleted.size
    && changeIsUpdate(change);
}

/**
 * Determines if a Yjs change event represents an UPDATE_MODULE operation
 * in a modal canvas.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateModalModule(change) {
  return changeMatchesPath(change, MODAL_MODULE_PATH)
    && !change.changes.added.size
    && !change.changes.deleted.size
    && changeIsUpdate(change);
}

/**
 * Determines if a Yjs change event represents an ADD_MODULE operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isAddModule(change) {
  return changeMatchesPath(change, MODULES_PATH) && change.changes.added.size;
}

/**
 * Determines if a Yjs change event represents a DELETE_MODULE operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isDeleteModule(change) {
  return changeMatchesPath(change, MODULES_PATH) && change.changes.deleted.size;
}

/**
 * Determines if this is an ADD_MODULE or DELETE_MODULE operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isAddOrDeleteModule(change) {
  return isAddModule(change) || isDeleteModule(change);
}

/**
 * Determines if a Yjs change event represents an ADD_MODULE operation in a modal.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isAddModalModule(change) {
  return changeMatchesPath(change, MODAL_MODULES_PATH) && change.changes.added.size;
}

/**
 * Determines if a Yjs change event represents a DELETE_MODULE operation in a modal.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isDeleteModalModule(change) {
  return changeMatchesPath(change, MODAL_MODULES_PATH) && change.changes.deleted.size;
}

/**
 * Determines if this is an ADD_MODULE or DELETE_MODULE operation in a modal.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isAddOrDeleteModalModule(change) {
  return isAddModalModule(change) || isDeleteModalModule(change);
}

/**
 * Determines if a Yjs change event represents an ADD_PAGE operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isAddPage(change) {
  return changeMatchesPath(change, PAGES_PATH) && change.changes.added.size;
}

/**
 * Determines if a Yjs change event represents a DELETE_PAGE operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isDeletePage(change) {
  return changeMatchesPath(change, PAGES_PATH) && change.changes.deleted.size;
}

/**
 * Determines if a Yjs change event represents an UPDATE_PAGE operation.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdatePage(change) {
  return changeMatchesPath(change, PAGE_PATH)
    && !change.changes.added.size
    && !change.changes.deleted.size
    && changeIsUpdate(change);
}

/**
 * Determines if a Yjs change event updates keys inside a page's options object.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdatePageOptions(change) {
  return changeMatchesPath(change, PAGE_OPTIONS_PATH)
    && !change.changes.added.size
    && !change.changes.deleted.size
    && changeIsUpdate(change);
}

/**
 * Determines if the Yjs change event represents updates to
 * top level properties on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateDraft(change) {
  return changeMatchesPath(change, DRAFT_PATH) && changeIsUpdate(change);
}

/**
 * Determines if the Yjs change event represents updates to
 * top level 'shares' property on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateDraftShares(change) {
  return changeMatchesPath(change, DRAFT_PATH)
    && (change.changes.keys.size === 1 || get(change, 'keysChanged.size') === 1)
    && (change.changes.keys.has('shares') || get(change, 'keysChanged', new Map()).has('shares'));
}

/**
 * Determines if the Yjs change event represents updates to
 * the options object on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateDraftOptions(change) {
  return changeMatchesPath(change, DRAFT_OPTIONS_PATH) && changeIsUpdate(change);
}

/**
 * Determines if the Yjs change event represents updates to
 * the a section on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateSection(change) {
  return changeMatchesPath(change, DRAFT_SECTION_PATH);
}

/**
 * Determines if the Yjs change event represents updates to
 * the chat_enabled property on the options object on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateDraftOptionsChatEnabled(change) {
  return changeMatchesPath(change, DRAFT_OPTIONS_PATH)
    && (change.changes.keys.size === 1 || get(change, 'keysChanged.size') === 1)
    && (change.changes.keys.has('chat_enabled') || get(change, 'keysChanged', new Map()).has('chat_enabled'));
}

/**
 * Determines if a Yjs change event represents an UPDATE_CHAT_ENABLED operation.
 * @param {YArrayEvent} change The Yjs change event raised.
 */
export function isUpdateChatEnabled(change) {
  return change.changes.keys.size && change.changes.keys.has('chat_enabled');
}

/**
 * Determines if the Yjs change event represents updates to
 * the metadata object on the draft.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateDraftMetadata(change) {
  return changeMatchesPath(change, DRAFT_METADATA_PATH) && changeIsUpdate(change);
}

/**
 * Determines if the Yjs change event represents adding a new modal canvas.
 * @param {YMapEvent} change The Yjs change event raised.
 */
export function isUpdateAddModalCanvas(change) {
  return changeMatchesPath(change, MODALS_PATH) && change.changes.added.size;
}

/**
 * Get a page from the shared document by page id
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 * @param {String} pageOrId Id of page or page object to find.
 */
export function findYPageById(yDoc, pageOrId) {
  const pageId = get(pageOrId, 'id', pageOrId);
  const yPages = yDoc.get('draft').get('pages');
  return yjsHelper.find(yPages, (p) => p.get('id') === pageId);
}

/**
 * Returns the array of sections from the draft.
 */
export function getSections(yDoc) {
  return yDoc.get('draft').get('options').get('groups');
}

/**
 * Returns the array of pages from the draft.
 */
export function getPages(yDoc) {
  return yDoc.get('draft').get('pages');
}

/**
 * Get a page's options from the shared document by page id
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 * @param {String} pageOrId Id of page or page object to find.
 */
export function findYPageOptionsById(yDoc, pageOrId) {
  const yPage = findYPageById(yDoc, pageOrId);
  if (!yPage) return null;
  return yPage.get('options');
}

/**
 * Finds the page in the draft Y.Map where the provided moduleId exists.
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 * @param {string} canvasName Name of canvas to search.
 * @param {string} moduleId Module id to find.
 */
export function findYPageByModuleId(yDoc, canvasName, moduleId) {
  const yPages = yDoc.get('draft').get('pages');
  const pageIndex = yjsHelper.findIndex(yPages,
    (yPage) => yjsHelper.find(yPage.get(canvasName), (yMod) => yMod.get('id') === moduleId));
  if (pageIndex === -1) return undefined;
  return yPages.get(pageIndex);
}

/**
 * Finds the modal in the draft Y.Map where the provided moduleId exists.
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 * @param {string} moduleId Module id to find.
 */
export function findYModalByModuleId(yDoc, moduleId) {
  const yModals = yDoc.get('draft').get('modals');
  const modalIndex = yjsHelper.findIndex(yModals,
    (yModal) => yjsHelper.find(yModal.get('modules'), (yMod) => yMod.get('id') === moduleId));
  if (modalIndex === -1) return undefined;
  return yModals.get(modalIndex);
}

/**
 * Gets the yModule on the specified canvas name from the yDoc.
 * @param {String} canvasName Name of the canvas to search on.
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 * @param {String} moduleId Guid id of the module to find.
 */
export function findYModuleById(canvasName, yDoc, moduleId) {
  return yjsHelper.findDeep(
    yDoc.get('draft').get(canvasName === 'modalModules' ? 'modals' : 'pages'),
    (yPageOrModal) => yjsHelper.find(
      yPageOrModal.get(canvasName === 'modalModules' ? 'modules' : canvasName),
      (yMod) => yMod.get('id') === moduleId,
    ),
  );
}

/**
 * Returns the yArray from the yDoc for the specified canvas.
 * @param {String} canvasName Name of the canvas to get.
 * @param {String} pageOrModalId Id of the page or modal the canvas is on.
 * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
 */
export function findCanvasByPageOrModalId(canvasName, pageOrModalId, yDoc) {
  let yPageOrModal;
  if (canvasName === 'modalModules') {
    yPageOrModal = yjsHelper.find(
      yDoc.get('draft').get('modals'),
      (modal) => modal.get('id') === pageOrModalId,
    );
  } else {
    yPageOrModal = yjsHelper.find(
      yDoc.get('draft').get('pages'),
      (page) => page.get('id') === pageOrModalId,
    );
  }

  return yPageOrModal?.get(canvasName === 'modalModules' ? 'modules' : canvasName);
}

/**
 * Deletes a specified module from the draft Y.Map.
 * @param {Y.Map} yDoc Y.Map representation of draft loaded into SharedDocument.
 * @param {string} moduleId Id of module to delete.
 * @param {string} canvasName Name of canvas module belongs to.
 * @param {Object} replacementModule A module to replace the deleted module with.
 */
export function deleteYModule(yDoc, moduleId, canvasName, replacementModule) {
  let yPageOrModal;
  let canvasNameProperty = canvasName;

  if (canvasName === 'modalModules') {
    yPageOrModal = findYModalByModuleId(yDoc, moduleId);
    canvasNameProperty = 'modules';
  } else {
    yPageOrModal = findYPageByModuleId(yDoc, canvasName, moduleId);
  }

  if (yPageOrModal) {
    const moduleIndex = yjsHelper.findIndex(yPageOrModal.get(canvasNameProperty), (yMod) => yMod.get('id') === moduleId);
    if (moduleIndex !== -1) {
      yPageOrModal.get(canvasNameProperty).delete(moduleIndex, 1);
      if (replacementModule) {
        yPageOrModal.get(canvasNameProperty).push([yjsHelper.toY(replacementModule)]);
      }
    }
  }
}

/**
 * Gets a list of sections that do not have pages in them.
 * @param {Y.Map} yDoc Y.Map representation of draft loaded into SharedDocument.
 */
export function getEmptySections(yDoc) {
  const sectionsToDelete = [];
  const ySections = yDoc.get('draft').get('options').get('groups');
  if (ySections) {
    const yPages = yDoc.get('draft').get('pages');
    ySections.forEach((ySection) => {
      const pageInSection = yjsHelper.find(
        yPages,
        (yPage) => yPage.get('options').get('group_id') === ySection.get('id'),
      );
      if (!pageInSection) {
        sectionsToDelete.push(ySection.toJSON());
      }
    });
  }
  return sectionsToDelete;
}

/**
 * Get a section from the yDoc by id.
 * @param {Y.Map} yDoc Y.Map representation of draft loaded into SharedDocument.
 * @param {String} sectionId Id of the section object to get.
 */
export function getSectionById(yDoc, sectionId) {
  const ySections = yDoc.get('draft').get('options').get('groups');
  if (!ySections) return null;
  return yjsHelper.find(ySections, (s) => s.get('id') === sectionId);
}

/**
 * Deep copies the page and resets ids for contained objects
 * @param {Array} pagesSorted List of pages in the draft sorted by sort_index
 * @param {Object} page The page to be copied
 */
export function copyPage(pagesSorted, page) {
  const newPage = {
    ...cloneDeep(page),
    id: uuid(),
    title: newPageTitle(page.title),
    modules: page.modules.map((mod) => {
      const copiedModule = {
        ...cloneDeep(mod),
        options: cloneDeep(mod.options) || {},
        id: uuid(),
      };
      return copiedModule;
    }),
  };

  if (page.notes) {
    newPage.notes = page.notes.map((mod) => ({
      ...cloneDeep(mod),
      options: cloneDeep(mod.options) || {},
      id: uuid(),
    }));
  }

  const pageIndex = pagesSorted.findIndex((p) => p.id === page.id);

  let sortIndex;
  if (pageIndex === pagesSorted.length - 1) {
    sortIndex = page.sort_index + 1;
  } else {
    sortIndex = (page.sort_index + pagesSorted[pageIndex + 1].sort_index) / 2;
  }

  // set new sort index
  newPage.sort_index = sortIndex;

  return newPage;
}

/*
  Utilized in the Copy/Paste/Duplicate actions.
  Ensure existing ids are removed before adding the module.
  This ensures a new id will be generated.
*/
export function removeModuleIds(module) {
  const mod = module;

  if (mod.id) delete mod.id;
  if (mod.options?.id) delete mod.options.id;

  return mod;
}
