import {
  cloneDeep, isPlainObject, isArray, pick, keys, omit, isEqual,
} from 'lodash-es';
import yjsHelper from '../yjs-helper';

const toYValue = (value) => {
  if (isPlainObject(value) || isArray(value)) {
    return yjsHelper.toY(value);
  }
  return value;
};

const valueFromY = (yValue) => {
  if (yValue?.toJSON) return yValue.toJSON();
  return yValue;
};

/*
  Object that applies an add, delete, update or sync change to the yDoc.
*/
export default class DocumentChange {
  /**
   * Creates a new 'add' document change.
   * @param {Function} getYObject A function to get the array from the yDoc to add to.
   * @param {Object} add The object to add to the array.
   * @returns {DocumentChange} A new document change object that will revert this change.
   */
  static add(getYObject, add) {
    return new DocumentChange({ getYObject, add });
  }

  /**
   * Creates a new 'delete' document change.
   * @param {Function} getYObject A function to get the array from the yDoc to delete from.
   * @param {Object} item The object to delete from the array.
   * @returns {DocumentChange} A new document change object that will revert this change.
   */
  static delete(getYObject, item) {
    return new DocumentChange({ getYObject, delete: item });
  }

  /**
   * Creates a new 'update' document change.
   * @param {Function} getYObject A function to get the object to update from the yDoc.
   * @param {Object} update Object with keys and values to update.
   * @param {String} objectId The 'id' of the object to update if 'id' is not included
   * in the 'update' parameter.
   * @returns {DocumentChange} A new document change object that will revert this change.
   */
  static update(getYObject, update, objectId) {
    return new DocumentChange({ getYObject, update, objectId });
  }

  /**
   * Creates a new 'sync' document change.
   * Updates Y.Map with keys in the sync object.
   * Remove keys from the Y.Map that do not exist in the sync object.
   * @param {Function} getYObject A function to get the object to update from the yDoc.
   * @param {Object} sync Object to sync to the target Y.Map.
   * @param {String} objectId The 'id' of the object to update if 'id' is not included
   * in the 'update' parameter.
   * @returns {DocumentChange} A new document change object that will revert this change.
   */
  static sync(getYObject, sync, objectId) {
    return new DocumentChange({ getYObject, sync, objectId });
  }

  constructor(options) {
    this.options = options;
    Object.assign(this, cloneDeep(pick(options, [
      'getYObject',
      'sync',
      'update',
      'delete',
      'add',
    ])));
    /*
      Unique identifier for the object being added, deleted or updated.
    */
    this.objectId = options.objectId || options.add?.id
      || options.update?.id || options.delete?.id
      || options.sync?.id;
    this.isApplied = false;
  }

  /**
   * Gets the keys that were updated with an 'update' change (excludes 'id' which is not updated).
   */
  get updateKeys() {
    return keys(omit(this.update, 'id'));
  }

  /**
   * Applies this change to the yDoc.
   * @param {Y.Map} yDoc Document loaded into SharedDocument for Studio editor.
   * @returns {DocumentChange} A document change object that will undo or reverse
   * this change if applied.
   */
  apply(yDoc) {
    let undoChange;
    // Only allow a change to be applied once.
    if (this.isApplied) return undoChange;
    /*
      Gets the object in the yDoc that is being updated. This will be a
      Y.Map for 'update' and a Y.Array for 'add' or 'delete' changes.
    */
    const yObject = this.getYObject(yDoc, this.objectId);
    if (yObject) {
      if (this.sync) {
        /*
          Sync an object to an object in the yDoc.
          Updates keys from the sync object in the Y.Map from the yDoc.
          Removes keys from the Y.Map object that do not exist in the sync object.
        */
        const currentValue = yObject.toJSON();
        if (!isEqual(this.sync, currentValue)) {
          // Sync the object to the Y.Map.
          yjsHelper.updateYMap(this.sync, yObject);
          // Create a document change to revert this change.
          undoChange = DocumentChange.sync(this.getYObject, currentValue, this.options.objectId);
        }
      } else if (this.update) {
        /*
          Update an object in the yDoc.
          Only updates the keys that are provided in the updated object.
          Uses 'id' key as unique identifier. 'id' key is not updated.
        */
        const update = {};
        let hasChanges = false;
        this.updateKeys.forEach((key) => {
          const currentValue = valueFromY(yObject.get(key));
          const newValue = this.update[key];
          if (!isEqual(currentValue, newValue)) {
            yObject.set(key, toYValue(newValue));
            hasChanges = true;
          }
          update[key] = currentValue;
        });
        /*
          If we are allowing undo for this change, then return a new document
          change that will undo this change when applied.
        */
        if (hasChanges) {
          // If there was an id, add it to the update object for the undo state.
          if (this.update.id) {
            update.id = this.update.id;
          }
          // Create a document change to revert this change.
          undoChange = DocumentChange.update(this.getYObject, update, this.options.objectId);
        }
      } else if (this.add) {
        /*
          Adds a new object to the yDoc.
          Uses the key 'id' as the unique identifier.
        */
        if (!yjsHelper.find(yObject, (o) => o.get('id') === this.add.id)) {
          yObject.push([toYValue(this.add)]);
          /*
            If we are allowing undo for this change, then return a new document
            change that will undo this change when applied.
          */
          undoChange = DocumentChange.delete(this.getYObject, this.add);
        }
      } else if (this.delete) {
        /*
          Deletes an object from the yDoc.
          Uses the key 'id' as the unique identifier.
        */
        const index = yjsHelper.findIndex(yObject, (o) => o.get('id') === this.delete.id);
        if (index !== -1) {
          yObject.delete(index);
          /*
            If we are allowing undo for this change, then return a new document
            change that will undo this change when applied.
          */
          undoChange = DocumentChange.add(this.getYObject, this.delete);
        }
      }
    }

    /*
      Mark this change as applied.
      Once applied, it cannot be run again.
    */
    this.isApplied = true;

    // Return a change that will undo this change.
    return undoChange;
  }
}
