import { keys } from 'lodash-es';
import {
  LOCAL_TRANSACTION_UNDO_MANAGER,
  LOCAL_TRANSACTION,
} from '../constants';

/**
 * UndoManager stack item for applying a list of changes to the yDoc
 * as a single undoable transaction.
 */
export default class DocumentChangeStackItem {
  /**
   * Creates a new instance of a document changes stack item
   * @param {SharedDocument} sharedDoc Active SharedDocument instance for editing session.
   * @param {Object} updatedState Contains a list of changes to apply.
   * @param {Map} tags A Map of tags to attach to this stack item.
   * @param {Number} captureTimeout Any consecutive stack items that have the same captureTimeout
   * configured and are added to the stack within the timeout period from each other will be
   * merged together as one undoable stack item.
   */
  constructor(sharedDoc, { updatedState, tags, captureTimeout } = {}) {
    this.sharedDoc = sharedDoc;
    this.previousState = { changes: [] };
    this.updatedState = updatedState || { changes: [] };
    this.tags = new Map(tags?.entries());
    this.captureTimeout = captureTimeout;
    this.updateTags();
  }

  /*
    Adds a new change to this document changes stack item.
    After new changes are added, apply() must be called again
    to apply the new changes to the yDoc.
  */
  add(...changes) {
    this.updatedState.changes.push(...changes);
  }

  /*
    Merges a stack item's changes into this one. This assumes
    that both stack item's changes have been applied.
  */
  merge(stackItem) {
    // Add to the end of the updated state changes
    this.add(...stackItem.updatedState.changes);
    // Add previous state changes to front of the list.
    this.previousState.changes = [
      ...stackItem.previousState.changes,
      ...this.previousState.changes,
    ];
    this.updateTags();
  }

  /*
    Builds a set of tags that can be used to query this stack item.
    These are mainly used to find and remove specific stack items from a user's
    undo history during collaborative editing.
    e.g. User A updates a property on an object. Then user B updates the same property.
    We will then remove the stack item from user A's undo history so they will not
    overwrite user B's change when undoing their local changes.
  */
  updateTags() {
    const changes = [
      ...(this.updatedState?.changes || []),
      ...(this.previousState?.changes || []),
    ];
    changes.forEach((change) => {
      let changeTags = this.tags.get(change.objectId);
      if (!changeTags) {
        changeTags = new Set();
        this.tags.set(change.objectId, changeTags);
      }
      if (change.sync) {
        changeTags.add('sync');
        keys(change.sync).forEach((key) => {
          changeTags.add(key);
        });
      }
      if (change.update) {
        changeTags.add('update');
        change.updateKeys.forEach((key) => {
          changeTags.add(key);
        });
      }
      if (change.add) changeTags.add('add');
      if (change.delete) changeTags.add('delete');
    });
  }

  /**
   * Determines if this transaction has changes and if it should
   * be added to the undo stack.
   */
  canAdd() {
    return !!this.previousState?.changes?.length;
  }

  /**
   * Apply changes to the shared doc for the first time.
   * This can be called multiple times if more changes are added
   * after calling this function for the first time.
   */
  apply(transactionOrigin = LOCAL_TRANSACTION) {
    this.sharedDoc.change((yDoc) => {
      this.updatedState.changes
        .map((change) => change.apply(yDoc))
        .filter((undoChange) => undoChange)
        .forEach((undoChange) => {
          this.previousState.changes.unshift(undoChange);
        });
    }, transactionOrigin);
    this.updateTags();
  }

  /**
   * Undo the changes by returning to the previousState.
   * Resets the updatedState to the list of undo changes returned
   * by applying the previousState changes.
   */
  undo() {
    this.sharedDoc.change((yDoc) => {
      this.updatedState.changes = this.previousState.changes
        .map((change) => change.apply(yDoc))
        .filter((undoChange) => undoChange)
        .reverse();
    }, LOCAL_TRANSACTION_UNDO_MANAGER);
  }

  /**
   * Redo the changes by returning to the updatedState.
   * Resets the previousState to the list of undo changes returned
   * by applying the updatedState changes.
   */
  redo() {
    this.sharedDoc.change((yDoc) => {
      this.previousState.changes = this.updatedState.changes
        .map((change) => change.apply(yDoc))
        .filter((undoChange) => undoChange)
        .reverse();
    }, LOCAL_TRANSACTION_UNDO_MANAGER);
  }
}
