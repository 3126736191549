import { get, throttle } from 'lodash-es';
import { defineStore } from 'pinia';
import { PRODUCT_SETTING_INACTIVITY_TIMEOUT } from '@/lib/constants';
import * as types from '@/lib/constants/store';
import {
  useUserStore,
  useModalStore,
} from '@/stores';

// Since this doesn't need to be reactive, I'm keeping it here so it doesn't
// spam the logs
let isInactive = false;

const useInactivityStore = defineStore('studio-inactivity', {
  state: () => ({
    activityHandler: null,
    authCheckTimeoutID: null,
  }),
  getters: {
    inactivityTimeout: () => {
      const userStore = useUserStore();
      // Return user's inactivity timeout, or else 20 minutes
      return get(userStore, `productSettings.${PRODUCT_SETTING_INACTIVITY_TIMEOUT}.value`)
        || 1200000;
    },
  },
  actions: {
    async [types.CHECK_AUTH_STATUS]({ addTtlTimeout, returnUrl }) {
      // Check the session state a single time. This is called when the
      // app initializes and in the ConfirmStillEditingModal
      const userStore = useUserStore();
      const nextUrl = encodeURIComponent(returnUrl || window.location.href);
      const defaultRedirectUrl = `${userStore.domains.appDomain}/signin?next=${nextUrl}`;
      try {
        const result = await this.deApi.get('/users/session_state');
        if (get(result, 'data.session_status') !== 'logged_in') {
          const response = await this.deApi.get(`/users/suggest_signin?next_url=${nextUrl}`);
          const redirectUrl = get(response, 'data.suggest.recommended', defaultRedirectUrl);
          const error = new Error('User not authenticated');
          error.redirectUrl = redirectUrl;
          throw error;
        }

        // Check the auth status after the session TTL
        if (addTtlTimeout && get(result, 'data.session_ttl')) {
          setTimeout(() => {
            this[types.AUTH_CHECK]();
          }, result.data.session_ttl);
        }
      } catch (error) {
        if (!error.redirectUrl) error.redirectUrl = defaultRedirectUrl;
        throw error;
      }
    },

    async [types.AUTH_CHECK]() {
      // This runs when the user interacts with the site. If they've been
      // inactive for a long time, call the session_state api to see if
      // they're still logged in.
      if (isInactive) {
        this[types.END_AUTH_CHECK]();
        const result = await this.deApi.get('/users/session_state');

        if (result.data && result.data.session_status !== 'logged_in') {
          const userStore = useUserStore();
          const modalStore = useModalStore();
          const returnUrl = encodeURIComponent(window.location.href);
          const redirect = await this.deApi.get(`/users/suggest_signin?next_url=${returnUrl}`);

          modalStore[types.OPEN_MODAL]({
            type: 'AuthRedirectModal',
            userFirstName: userStore.user.first_name,
            redirectLink: redirect.data.suggest.recommended,
          });
        } else {
          this[types.START_AUTH_CHECK]();
        }
      } else {
        // If the user is still active, reset the inactivity timeout
        clearTimeout(this.authCheckTimeoutID);
        const timeoutID = setTimeout(() => {
          isInactive = true;
        }, this.inactivityTimeout);
        this.authCheckTimeoutID = timeoutID;
      }
    },

    [types.START_AUTH_CHECK]() {
      isInactive = false;
      this.authCheckTimeoutID = null;

      // Keep track of this inactivity handler so that we can clear it later on
      const activityHandler = throttle(() => {
        this[types.AUTH_CHECK]();
      }, 500);
      document.addEventListener('click', activityHandler);
      document.addEventListener('mousemove', activityHandler);
      document.addEventListener('keypress', activityHandler);
      this.activityHandler = activityHandler;

      const timeoutID = setTimeout(() => {
        isInactive = true;
      }, this.inactivityTimeout);
      this.authCheckTimeoutID = timeoutID;
    },

    [types.END_AUTH_CHECK]() {
      document.removeEventListener('click', this.activityHandler);
      document.removeEventListener('mousemove', this.activityHandler);
      document.removeEventListener('keypress', this.activityHandler);
      clearTimeout(this.authCheckTimeoutID);
    },
  },
});

export default useInactivityStore;
