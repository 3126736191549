import axios from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash-es';
import { loadProgressBar } from 'axios-progress-bar';
import * as appConstants from '@/lib/constants/app';
import { addLocalDataInterceptors } from '@/lib/localData';
import { cleanOrionTextModule } from '@/lib/utils';

// get the env string
export function getEnv(hostname) {
  // use window hostname if one isn't provided
  const host = hostname || window.location.hostname;

  const regexResults = /(.*?)(.local|.stage|.qa|.dev|])\.discoveryeducation.(com|co\.uk|ca)/gi.exec(host);

  // If we've found a prefix, use that as the env
  if (regexResults && regexResults[2]) {
    return regexResults[2].replace(/[^a-z]/gi, '').toUpperCase();
  }

  return 'PROD';
}

export function getRegion(hostname) {
  // use window hostname if one isn't provided
  const host = hostname || window.location.hostname;

  const regexResults = /(.*?)\.discoveryeducation.(com|co\.uk|ca)/gi.exec(host);

  // If we've found a prefix, use that as the env
  if (regexResults && regexResults[2]) {
    const tld = regexResults[2];

    if (tld === 'com') {
      return 'US';
    }

    if (tld === 'co.uk') {
      return 'UK';
    }

    if (tld === 'ca') {
      return 'CA';
    }
  }

  // default
  return 'US';
}

export function getDomains(hostname) {
  // use window hostname if one isn't provided
  const host = hostname || window.location.hostname;

  const domains = {};
  const env = getEnv(host);
  const region = getRegion(host);

  // Grab the domain env vars (variables prefixed with `VUE_APP_` are available at runtime)
  domains.titanDomain = appConstants[`VUE_APP_${env}_API_${region}`];
  domains.deDomain = appConstants[`VUE_APP_${env}_DEAPI_${region}`];
  domains.appDomain = appConstants[`VUE_APP_${env}_APPURL_${region}`];
  domains.studioDomain = appConstants[`VUE_APP_${env}_STUDIO_URL_${region}`];
  domains.adminDomain = appConstants[`VUE_APP_${env}_ADMIN_URL_${region}`];
  domains.wwwDiscoveryEducationUrl = appConstants[`VUE_APP_WWW_DISCOVERY_EDUCATION_URL_${region}`];
  domains.staticUrl = appConstants[`VUE_APP_${env}_STATICURL_${region}`];
  domains.contentDomain = appConstants[`VUE_APP_${env}_CONTENT_API`];

  if (region === 'US') {
    // Apollo only has dev and prod
    domains.apolloDomain = env === 'PROD'
      ? 'https://apollo.discoveryeducation.com'
      : 'https://apollo.dev.discoveryeducation.com';
  } else if (region === 'UK') {
    // Apollo only has dev and prod
    domains.apolloDomain = env === 'PROD'
      ? 'https://apollo.discoveryeducation.co.uk'
      : 'https://apollo.dev.discoveryeducation.co.uk';
  } else if (region === 'CA') {
    // Apollo only has dev and prod
    domains.apolloDomain = env === 'PROD'
      ? 'https://apollo.discoveryeducation.ca'
      : 'https://apollo.dev.discoveryeducation.ca';
  }

  return domains;
}

// get the domain to link back to the learn app
export function getAppDomain(hostname) {
  return getDomains(hostname).appDomain;
}

/**
 * Attach an interceptor to clean up orion text modules on page patch
 * @param {axiosClient} api
 */
function attachOrionModuleInterceptor(api) {
  // Add a request interceptor
  api.interceptors.request.use((config) => {
    // only apply on patch draft
    if (['patch'].includes(config.method) && config.url.includes('draft')) {
      for (let x = 0; x < config.data.length; x += 1) {
        if (
          config.data[x].path.includes('/pages')
          && typeof config.data[x].value === 'object'
          && Array.isArray(config.data[x].value.modules)
        ) {
          // clean up the modules
          config.data[x].value.modules.forEach((mod) => cleanOrionTextModule(mod));
        }
      }
    }
    return config;
  });
}
// helper func to make it easier to spy on location changes
export function redirectToUrl(url) {
  window.location.href = url;
}

/**
 * Attach a response interceptor to catch 401's and reroute to the signin page
 * @param {axiosClient} api
 * @param {function} redirectCallback func to call to initiate a redirect
 */
export function attachErrorResponseInterceptor(api, deApi, redirectCallback = null) {
  // make sure we have a redirect function
  const redirect = redirectCallback || redirectToUrl;

  // use a purposefully vague cookie name
  const errorCookieName = 'status_track';
  const limit = 5;

  // get domains
  const { appDomain } = getDomains(window.location.hostname);
  const cookieDomain = window.location.host;

  api.interceptors.response.use((response) => {
    // remove the cookie when we get a 200
    Cookies.remove(errorCookieName);
    return response;
  },
  (error) => {
    // eslint-disable-next-line
    // grab the status code and message
    const statusCode = error.response?.status;
    // eslint-disable-next-line
    let message = error.response?.data;

    // deal with array buffers to translate the message if needed
    // this is because the shared_doc requests an arraybuffer response type
    if (statusCode === 403 && message instanceof ArrayBuffer && window.TextDecoder) {
      const decoder = new TextDecoder('utf-8');

      // decode the array buffer so that we can parse the string
      const decodedString = decoder.decode(message);

      try {
        // parse the json string and get the message out of it
        message = JSON.parse(decodedString);
        message = message.meta?.message || '';
      } catch {
        message = ''; // if the format wasn't as expected, then just use an empty string
      }
    } else if (message instanceof Object) {
      // get the message out of the response json object
      message = message.meta?.message || '';
    }

    // create a promise that we will use later
    const prom = new Promise((resolve, reject) => {
      if (
        (
          (
            statusCode === 401 // 401
            && !error.config?.url.includes('assessments') // assessments api uses a 401 response for more than login status
          )
          || (
            statusCode === 403 // OR 403 token expiration
            && message.includes('Token is too old')
          )
        ) && !error.config?.skipErrorHandling
      ) {
        // grab the cookie value
        const cookieVal = parseInt(Cookies.get(errorCookieName), 10) || 0;

        // if we met the limit, go back to the homepage
        if (cookieVal && cookieVal > limit) {
          Cookies.remove(errorCookieName, { domain: cookieDomain });

          // redirect to the learn homepage
          redirect(`${appDomain}/signin`);

          // reject the promise to clean everything up
          reject(error);
        } else {
          // increment
          Cookies.set(errorCookieName, cookieVal + 1, { expires: 7, domain: cookieDomain });

          // Match the interceptors used throughout the rest of the app
          const returnUrl = encodeURIComponent(window.location.href);
          deApi.get(`/users/suggest_signin?next_url=${returnUrl}`)
            .then((signinResponse) => {
              if (!get(signinResponse, 'data.suggest.recommended')) {
                redirect(`${appDomain}/signin?next=${returnUrl}`);
              } else {
                redirect(signinResponse.data.suggest.recommended);
              }

              reject(error);
            }).catch(() => {
              redirect(`${appDomain}/signin?next=${returnUrl}`);
              reject(error);
            });
        }
      } else {
        reject(error);
      }
    });
    return prom;
  });
}

/**
 * Get build info and the hash for the current js file
 * (in case the build file isn't cached, but the js file is)
 */
export async function getBuildTrackingDefaults() {
  const buildDefaults = {};

  try {
    const response = await axios.get('build-info.json');

    if (response.data?.date_time) {
      buildDefaults.bd = response.data.date_time;
    }

    if (response.data?.commit) {
      buildDefaults.bc = response.data.commit;
    }
  } catch (e) {
    // do nothing if build-info file doesn't exist (which it won't on local auto-builds)
  }

  // get the build hash for the app.js file
  const scripts = document.getElementsByTagName('link');
  for (let x = 0; x < scripts.length; x += 1) {
    const res = /app\.(.*?)\.js$/.exec(scripts[x].href);
    if (res) {
      // eslint-disable-next-line prefer-destructuring
      buildDefaults.bh = res[1];
    }
  }

  return buildDefaults;
}

export function addDefaultRequestParams(client, buildDefaults) {
  client.interceptors.request.use((config) => {
    if (!config.url.includes('suggest_signin') && !config.url.includes('update_status')) {
      // eslint-disable-next-line no-param-reassign
      config.params = config.params || {};

      // eslint-disable-next-line no-param-reassign
      config.params = {
        ...config.params,
        ...buildDefaults,
      };
    }

    return config;
  });
}

export function createAxiosClients(hostname, buildDefaults) {
  // host fallback
  const host = hostname || window.location.hostname;
  const { titanDomain, deDomain, contentDomain } = getDomains(host);

  if (!titanDomain || !deDomain || !contentDomain) throw (new Error('Missing valid api domains'));

  axios.defaults.withCredentials = true;

  // Set up Titan Api
  const apiClient = axios.create({
    baseURL: titanDomain,
    withCredentials: true,
  });

  // Titan Api with progress bar
  const apiClientWithProgressBar = axios.create({
    baseURL: titanDomain,
    withCredentials: true,
  });

  loadProgressBar({
    easing: 'easeInQuint',
    trickleSpeed: 800,
  }, apiClientWithProgressBar);

  // Set up DE Api
  const deApiClient = axios.create({
    baseURL: deDomain,
    withCredentials: true,
  });

  // Content API axios client
  const contentApiClient = axios.create({
    baseURL: contentDomain,
    withCredentials: true,
  });

  // clean up orion text modules on patch
  attachOrionModuleInterceptor(apiClient);
  attachOrionModuleInterceptor(apiClientWithProgressBar);

  // attach 401 interceptor
  attachErrorResponseInterceptor(deApiClient, deApiClient);
  attachErrorResponseInterceptor(apiClient, deApiClient);

  /*
    Add interceptors to use local data for api requests when present instead
    of requests going to the server. Example use case is for generating thumbnails
    on the server via selenium where draft data is injected into the browser window
    at window.studioAppData. Data in this object will be used instead of data from
    api request.
  */
  addLocalDataInterceptors(apiClient);
  addLocalDataInterceptors(apiClientWithProgressBar);
  addLocalDataInterceptors(deApiClient);

  if (buildDefaults) {
    addDefaultRequestParams(apiClient, buildDefaults);
    addDefaultRequestParams(apiClientWithProgressBar, buildDefaults);
    addDefaultRequestParams(deApiClient, buildDefaults);
  }

  return {
    apiClient,
    apiClientWithProgressBar,
    deApiClient,
    contentApiClient,
  };
}

/**
 * Uses axios interceptors to rate limit the network transactions
 * to the content api
 *
 * @param {obj} client the axios client
 * @param {int} limit number of concurrent connections to limit to (set aggressively to 2)
 * @param {int} intervalMs wait time for interval check
 */
export function addRateLimiting(client, limit = 2, intervalMs = 20) {
  /* eslint-disable no-param-reassign */
  let pendingRequests = 0;

  client.interceptors.request.use((config) => (
    new Promise((resolve) => {
      // set an interval timer
      const interval = setInterval(() => {
        // if there are too many requests, wait to resolve the request
        if (pendingRequests < limit) {
          pendingRequests += 1;
          clearInterval(interval);
          resolve(config);
        }
      }, intervalMs);
    })
  ));

  // wait till the response is resolved to get rid of the pending request
  client.interceptors.response.use((response) => {
    pendingRequests = Math.max(0, pendingRequests - 1);
    return Promise.resolve(response);
  }, (error) => {
    pendingRequests = Math.max(0, pendingRequests - 1);
    return Promise.reject(error);
  });
  /* eslint-enable no-param-reassign */
}
