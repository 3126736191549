import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import * as types from '@/lib/constants/store';

const useErrorStore = defineStore('studio-error', {
  state: () => ({
    error: null,
  }),
  actions: {
    [types.SET_ERROR](payload) {
      const active = get(payload, 'active', false);

      if (active && payload.error) {
        this.error = payload.error;
      } else {
        this.error = null;
      }
    },
  },
});

export default useErrorStore;
