import {
  get, findIndex, findLastIndex, first, isNumber, last,
} from 'lodash-es';
import chain from '@/lib/utils/chain';
import {
  EDITOR_CANVAS_WIDTH,
  EDITOR_CANVAS_GRID_MARGIN,
  EDITOR_CANVAS_GRID_COL_NUM,
  EDITOR_CANVAS_GRID_ROW_HEIGHT,
} from '@/lib/constants';

export function bottomYPosition(modules) {
  return chain(modules)
    .map((mod) => mod.layout.y_position + mod.layout.height)
    .concat([0])
    .max()
    .value();
}

/**
 * Find best layout placement for a new module
 * @param gridMatrix - Existing modules on the canvas in nested arrays
 * @param mod - The module to place
 * @param isBoard - Whether the grid is an infinite-scroll board
 */

// `Array.some` is used here to eject early if we've found a spot. The module height and
// width is subtracted from the ends of rows and columns so we don't test cells that
// we know will fail.
function findPlaceForModule(testingMatrix, height, width, beginningRow) {
  let destinationLayout = null;
  testingMatrix.slice(0, testingMatrix.length - (height - 1)).some((row, rowIdx) => (
    rowIdx >= beginningRow && row.slice(0, row.length - (width - 1)).some((column, columnIdx) => {
      // A module fits if all its grid cells fit, meaning every row and every column.
      const cellsFit = Array.from({ length: height }).every((moduleRow, moduleRowIdx) => (
        Array.from({ length: width }).every((moduleColumn, moduleColumnIdx) => (
          // A cell is filled if its contents is truthy, so we want it to be falsey.
          !testingMatrix[rowIdx + moduleRowIdx][columnIdx + moduleColumnIdx]
        ))
      ));

      if (cellsFit) {
        destinationLayout = {
          x: columnIdx,
          y: rowIdx,
          height,
        };

        // If we've found a match, we can eject from the loops
        return true;
      }

      // Otherwise we continue
      return false;
    })
  ));
  return destinationLayout;
}

export function scanGrid(gridMatrix, mod, isBoard) {
  const { width, height } = mod.layout;
  let testingMatrix = [...gridMatrix]; // use a shallow copy since this comes from the store
  let destination = null;
  let beginningRow = 0;

  // For board layouts, add additional rows in case we need to expand vertically,
  // and begin from the bottom rather than the top
  if (isBoard) {
    const lastRowWithContent = gridMatrix
      .map((row) => Math.max(...row))
      .lastIndexOf(1);

    // In order to align the bottom of the module, if it's any larger than one row we need to
    // step backwards
    const heightOffset = height - 1;
    beginningRow = Math.max(lastRowWithContent - heightOffset, 0);

    const colNum = gridMatrix[0].length;
    testingMatrix = testingMatrix.concat(Array.from({ length: height }, () => (
      Array.from({ length: colNum }, () => 0)
    )));
  }

  // Step through the rows and columns of the matrix via findPlaceForModule.
  destination = findPlaceForModule(testingMatrix, height, width, beginningRow);

  // If destination remains null on the original height and we're attempting
  // to add an asset, then pass height as 4 and try findPlaceForModule again.
  if (!destination && mod.type === 'asset') {
    destination = findPlaceForModule(testingMatrix, 4, width, beginningRow);
  }

  if (!destination) {
    throw new Error('No space in the canvas for the new module');
  }

  return destination;
}

/**
 * Attempts to fit a module somewhere in the grid ensuring the module's area
 * is less than the specified max area to use.
 * @param {Array} gridMatrix The grid matrix to use for placement.
 * @param {object} mod The module to place.
 * @param {number} maxArea The max area the module can use in the grid.
 */
export function tryFitModule(gridMatrix, mod, maxArea) {
  let { width, height } = mod.layout;
  let moduleArea = width * height;

  // Adjust the modules dimensions so its area is less than maxArea
  while (moduleArea > maxArea && (width > 1 || height > 1)) {
    if (width > height) {
      width -= 1;
    } else {
      height -= 1;
    }
    moduleArea = width * height;
  }

  // Build a list of possible dimensions to try when placing the module.
  const tryFitDimensions = [{ layout: { width, height } }];
  if (width !== height) tryFitDimensions.push({ layout: { width: height, height: width } });
  while (width > 1 || height > 1) {
    if (width > height) {
      width -= 1;
    } else {
      height -= 1;
    }
    tryFitDimensions.push({ layout: { width, height } });
    if (width !== height) {
      tryFitDimensions.push({ layout: { width: height, height: width } });
    }
  }

  // Place the module with the first dimension that fits.
  let layout;
  for (let dimIndex = 0; dimIndex < tryFitDimensions.length; dimIndex += 1) {
    try {
      const modDim = tryFitDimensions[dimIndex];
      const { x, y } = scanGrid(gridMatrix, modDim, false);
      layout = {
        ...modDim.layout,
        x_position: x,
        y_position: y,
      };
      break;
    } catch {
      layout = null;
    }
  }
  return layout;
}

export function zPositionCount(modulesOrderedbyZ, zPosition) {
  return modulesOrderedbyZ.filter((mod) => mod.layout.z_position === zPosition).length;
}

/**
 * Creates a new z_position that is above the rest. If the provided position
 * is already at the top, then returns the provided position.
 * @param {Array} modulesOrderedbyZ Modules on page ordered by z_position
 * @param {Number} currentZPosition We're using fractional indexing here so that
 * each module is on its own layer and the module's actual position
 * in the array doesn't need to change. This is needed to work with collaborative editing.
 */
export function createTopZPosition(modulesOrderedbyZ, currentZPosition) {
  const maxZ = get(last(modulesOrderedbyZ), 'layout.z_position', 0);

  if (isNumber(currentZPosition) && currentZPosition === maxZ) {
    const modulesWithMaxZ = zPositionCount(modulesOrderedbyZ, maxZ);
    /*
      If the currentZPosition is the maxZ and there are no other modules with this position,
      then return the maxZ since this position is already at the top.
    */
    if (modulesWithMaxZ === 1) return maxZ;
  }

  return maxZ + 1;
}

/**
 * Creates a new z_position that is below the rest. If the provided position
 * is already at the bottom, then returns the provided position.
 * @param {Array} modulesOrderedbyZ Modules on page ordered by z_position
 * @param {Number} currentZPosition We're using fractional indexing here so that
 * each module is on its own layer and the module's actual position
 * in the array doesn't need to change. This is needed to work with collaborative editing.
 */
export function createBottomZPosition(modulesOrderedbyZ, currentZPosition) {
  const minZ = get(first(modulesOrderedbyZ), 'layout.z_position', 1);

  if (isNumber(currentZPosition) && currentZPosition === minZ) {
    const modulesWithMinZ = zPositionCount(modulesOrderedbyZ, minZ);
    /*
      If the currentZPosition is the minZ and there are no other modules with this position,
      then return the minZ since this position is already at the bottom.
    */
    if (modulesWithMinZ === 1) return minZ;
  }

  return minZ - 1;
}

/**
 * Creates a new z_position that is greater than the current and less than the next position.
 * If the provided position is already at the top, then returns the provided position.
 * @param {Array} modulesOrderedbyZ Modules on page ordered by z_position
 * @param {Number} currentZPosition We're using fractional indexing here so that
 * each module is on its own layer and the module's actual position
 * in the array doesn't need to change. This is needed to work with collaborative editing.
 */
export function createForwardZPosition(modulesOrderedbyZ, currentZPosition) {
  const nextModuleIndex = findIndex(modulesOrderedbyZ,
    (mod) => mod.layout.z_position > currentZPosition);

  if (nextModuleIndex === -1) {
    const modulesWithCurrentZ = zPositionCount(modulesOrderedbyZ, currentZPosition);
    /*
      If the currentZPosition is the only one, return it otherwise create a new
      position that is greater than the current.
    */
    return modulesWithCurrentZ === 1 ? currentZPosition : currentZPosition + 1;
  }

  const nextZPosition = modulesOrderedbyZ[nextModuleIndex].layout.z_position;

  const moduleAfterNextIndex = findIndex(modulesOrderedbyZ,
    (mod) => mod.layout.z_position > nextZPosition);

  // If there's no module after the next, then put after the next.
  if (moduleAfterNextIndex === -1) return nextZPosition + 1;

  const moduleAfterNext = modulesOrderedbyZ[moduleAfterNextIndex];

  // Otherwise, put between the next and the one after.
  return (nextZPosition + moduleAfterNext.layout.z_position) / 2;
}

/**
 * Creates a new z_position that is less than the current and greater than the
 * previous position. If the provided position is already at the bottom,
 * then returns the provided position.
 * @param {Array} modulesOrderedbyZ Modules on page ordered by z_position
 * @param {Number} currentZPosition We're using fractional indexing here so that
 * each module is on its own layer and the module's actual position
 * in the array doesn't need to change. This is needed to work with collaborative editing.
 */
export function createBackwardZPosition(modulesOrderedbyZ, currentZPosition) {
  const previousModuleIndex = findLastIndex(modulesOrderedbyZ,
    (mod) => mod.layout.z_position < currentZPosition);

  if (previousModuleIndex === -1) {
    const modulesWithCurrentZ = zPositionCount(modulesOrderedbyZ, currentZPosition);
    /*
      If the currentZPosition is the only one, return it otherwise create a new
      position that is below the current.
    */
    return modulesWithCurrentZ === 1 ? currentZPosition : currentZPosition - 1;
  }

  const previousZPosition = modulesOrderedbyZ[previousModuleIndex].layout.z_position;

  const moduleBeforePreviousIndex = findLastIndex(modulesOrderedbyZ,
    (mod) => mod.layout.z_position < previousZPosition);

  // If there's no module before the previous, then put before the previous.
  if (moduleBeforePreviousIndex === -1) return previousZPosition - 1;

  const moduleBeforePrevious = modulesOrderedbyZ[moduleBeforePreviousIndex];

  // Otherwise, put between the previous and the one before.
  return (moduleBeforePrevious.layout.z_position + previousZPosition) / 2;
}

/**
 * Assigns unique z_positions to the provided modules in order.
 * @param {Array} modules Modules to assign z_positions to.
 */
export function assignUniqueZPositions(modules) {
  return modules.map((mod, index) => ({
    ...mod,
    layout: {
      ...mod.layout,
      z_position: index + 1,
    },
  }));
}

/**
 * Converts width in grid columns to pixels including margins in between.
 * @param {Number} value Width in grid columns
 */
export function widthToPixels(value) {
  const totalMarginsWidth = (EDITOR_CANVAS_GRID_COL_NUM + 1) * EDITOR_CANVAS_GRID_MARGIN;
  const singleColumnWidth = (EDITOR_CANVAS_WIDTH - totalMarginsWidth) / EDITOR_CANVAS_GRID_COL_NUM;
  const marginBetweenColumns = (value - 1) * EDITOR_CANVAS_GRID_MARGIN;
  return marginBetweenColumns + singleColumnWidth * value;
}

/**
 * Converts height in grid rows to pixels including margins in between.
 * @param {Number} value Height in rows.
 */
export function heightToPixels(value) {
  const marginBetweenRows = (value - 1) * EDITOR_CANVAS_GRID_MARGIN;
  return EDITOR_CANVAS_GRID_ROW_HEIGHT * value + marginBetweenRows;
}

/**
 * Converts x_position in grid columns to pixels.
 * @param {Number} value x_position in grid columns
 */
export function xPositionToPixels(value) {
  const totalMarginsWidth = (EDITOR_CANVAS_GRID_COL_NUM + 1) * EDITOR_CANVAS_GRID_MARGIN;
  const singleColumnWidth = (EDITOR_CANVAS_WIDTH - totalMarginsWidth) / EDITOR_CANVAS_GRID_COL_NUM;
  const marginPixels = (value + 1) * EDITOR_CANVAS_GRID_MARGIN;
  return marginPixels + value * singleColumnWidth;
}

/**
 * Converts y_position in grid rows to pixels.
 * @param {Number} value y_position in grid rows
 */
export function yPositionToPixels(value) {
  const marginPixels = (value + 1) * EDITOR_CANVAS_GRID_MARGIN;
  return EDITOR_CANVAS_GRID_ROW_HEIGHT * value + marginPixels;
}

/**
 * Converts module layout from rows/columns to pixels.
 * @param {Object} layout - module layout
 */
export function layoutToPixels(layout, zPosition) {
  return {
    ...layout,
    height: heightToPixels(layout.height),
    width: widthToPixels(layout.width),
    x_position: xPositionToPixels(layout.x_position),
    y_position: yPositionToPixels(layout.y_position),
    z_position: zPosition || 0.5,
  };
}

/**
 * Converts a height in pixels to grid rows.
 * @param {Number} heightInPixels Height in pixels
 */
export function pixelsToHeight(heightInPixels) {
  const rowHeight = EDITOR_CANVAS_GRID_ROW_HEIGHT + EDITOR_CANVAS_GRID_MARGIN;
  let rowCount = Math.floor(heightInPixels / rowHeight);
  if (heightInPixels % rowHeight === 0) rowCount -= 1;
  const rowsHeightPixels = rowCount * rowHeight + EDITOR_CANVAS_GRID_ROW_HEIGHT;
  rowCount = heightInPixels <= rowsHeightPixels ? rowCount + 1 : rowCount + 2;
  return rowCount;
}

/**
 * Reformats the layout data stored in Mongo into the format
 * expected by the grid library
 * @param {Object} mod - Module data from the store
 */
export function formatModuleLayoutForGrid(mod) {
  // if missing, apply default values
  return {
    x: mod.layout.x_position || 0,
    y: mod.layout.y_position || 0,
    z: mod.layout.z_position || 0,
    h: mod.layout.height || 350,
    w: mod.layout.width || 350,
    r: mod.layout.rotate_degree,
    i: mod.id,
  };
}

/**
 * Gets the bounding box for the given array of points.
 */
export function getBoundingBox(points) {
  if (!points?.length) {
    return {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 0,
      height: 0,
    };
  }
  const xValues = points.map((point) => point.x);
  const yValues = points.map((point) => point.y);
  const left = Math.min(...xValues);
  const right = Math.max(...xValues);
  const top = Math.min(...yValues);
  const bottom = Math.max(...yValues);

  return {
    left,
    right,
    top,
    bottom,
    width: right - left,
    height: bottom - top,
  };
}

/**
 * Returns true if the two bounding boxes overlap each other.
 */
export function boundingBoxesOverlap(boxOne, boxTwo) {
  const xAxisOverlap = (boxOne.left >= boxTwo.left && boxOne.left <= boxTwo.right)
    || (boxOne.right >= boxTwo.left && boxOne.right <= boxTwo.right)
    || (boxTwo.left >= boxOne.left && boxTwo.left <= boxOne.right)
    || (boxTwo.right >= boxOne.left && boxTwo.right <= boxOne.right);
  const yAxisOverlap = (boxOne.top >= boxTwo.top && boxOne.top <= boxTwo.bottom)
    || (boxOne.bottom >= boxTwo.top && boxOne.bottom <= boxTwo.bottom)
    || (boxTwo.top >= boxOne.top && boxTwo.top <= boxOne.bottom)
    || (boxTwo.bottom >= boxOne.top && boxTwo.bottom <= boxOne.bottom);
  return xAxisOverlap && yAxisOverlap;
}

export default {
  bottomYPosition,
  scanGrid,
  tryFitModule,
  createTopZPosition,
  createBottomZPosition,
  createForwardZPosition,
  createBackwardZPosition,
  assignUniqueZPositions,
  widthToPixels,
  heightToPixels,
  xPositionToPixels,
  yPositionToPixels,
  layoutToPixels,
  pixelsToHeight,
  formatModuleLayoutForGrid,
  getBoundingBox,
  boundingBoxesOverlap,
};
