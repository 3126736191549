/**
 * Handles opening and closing channels and determining if
 * a channel attaches successfully or not.
 */
export default class SharedChannels {
  /**
   * Creates a new SharedChannels object.
   * @param {SharedDocument} sharedDocument The SharedDocument instance these channels belong to.
   */
  constructor(sharedDocument) {
    this._sharedDocument = sharedDocument;
    this._channels = {};
  }

  /**
   * Opens a new channel with Ably.
   * @param {string} channelName Full name of the channel to open.
   * @param {function} onAttached A function that will be called when channel is
   * successfully attached.
   */
  openChannel(channelName, onAttached) {
    return new Promise((resolve, reject) => {
      this._channels[channelName] = this._client.channels.get(channelName);
      const channel = this._channels[channelName];
      channel.attach(function (attachError) {
        if (attachError) {
          reject(attachError);
          return;
        }
        if (onAttached) onAttached(channel);
        resolve(channel);
      });
    });
  }

  /**
   * Gets a channel by its full name.
   * @param {string} channelName Full name of the channel.
   */
  get(channelName) {
    return channelName in this._channels
      ? this._channels[channelName]
      : this._client.channels.get(channelName);
  }

  /**
   * Creates a full namespaced channel name to be used for opening a channel.
   * @param {string} channelName Name of the channel that will make up the last
   * section of a full namespaced channel name.
   */
  getChannelName(channelName) {
    return `shared-document:${this._sharedDocument.documentId}:${channelName}`;
  }

  /**
   * Closes all channels that are currently open.
   */
  close() {
    Object.keys(this._channels).forEach(this.closeChannel.bind(this));
  }

  /**
   * Closes the specified channel by name.
   * @param {string} channelName Full name of channel to close.
   */
  closeChannel(channelName) {
    if (channelName in this._channels) {
      const channel = this._channels[channelName];
      channel.presence.unsubscribe();
      channel.unsubscribe();
      delete this._channels[channelName];
    }
  }

  /**
   * Gets the Ably client from the SharedDocument instance.
   */
  get _client() {
    return this._sharedDocument._client;
  }
}
