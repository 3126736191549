import { encode, decode } from '@ably/msgpack-js';
import { range, sortBy, sumBy } from 'lodash-es';
import { v4 as uuid } from 'uuid';

/**
 * Holds a message to be published and received between multiple instances
 * of SharedDocument.
 */
export default class SharedMessage {
  /**
   * Creates a new SharedMessage object.
   * @param {string} name Name of the event this message is for.
   * @param {Object} payload The JSON payload for this message. Note that the JSON payload
   * is not considered when calculating message size and should be kept below 1KB.
   * @param {Uint8Array} binaryPayload A binary payload to be sent. This payload will be split into
   * multiple chunks if larger than the specified value for chunkSizeBytes.
   * @param {number} chunkSizeBytes The max size for each binaryPayload chunk.
   */
  constructor(name, payload = {}, binaryPayload, chunkSizeBytes = 1024 * 15) {
    if (name) {
      this.id = uuid();
      this.name = name;
      this.payload = payload;
      this.binaryPayload = binaryPayload;
      this.chunkSizeBytes = chunkSizeBytes;
      this.chunks = this._getMessageChunks();
      this.totalChunks = this.chunks.length;
    }
  }

  /**
   * Creates a new SharedMessage object from a binary encoded SharedMessage.
   * SharedMessage encoded using MessagePack.
   * @param {ArrayBuffer} chunk Binary encoded SharedMessage object representing
   * a single chunk of data.
   */
  static fromChunk(chunk) {
    const message = new SharedMessage();
    message.addChunk(chunk);
    return message;
  }

  /**
   * Will return true if all chunks have been added to this SharedMessage.
   */
  get isComplete() {
    return this.chunks.length === this.totalChunks;
  }

  /**
   * Adds a MessagePack encoded chunk to this SharedMessage.
   * @param {ArrayBuffer} encodedChunk MessagePack encoded chunk.
   */
  addChunk(encodedChunk) {
    const chunk = decode(new Uint8Array(encodedChunk));
    if (!this.id) {
      /*
        If this SharedMessage doesn't have an id, then initialize its
        properties with the first chunk added.
      */
      this.id = chunk.messageId;
      this.name = chunk.name;
      this.payload = chunk.payload;
      this.chunks = [chunk];
      this.totalChunks = chunk.totalChunks;
    } else {
      if (chunk.messageId !== this.id) return;
      this.chunks.push(chunk);
    }
    /*
      If we have all the chunks, then combine all the chunks into
      the binaryPayload property.
    */
    if (this.chunks.length === this.totalChunks && chunk.chunkData) {
      const totalByteLength = sumBy(this.chunks, (item) => item.chunkData.byteLength);
      const combinedChunks = new Uint8Array(totalByteLength);
      let combinedWriteOffset = 0;
      sortBy(this.chunks, (item) => item.chunkIndex)
        .forEach((chunkItem) => {
          combinedChunks.set(chunkItem.chunkData, combinedWriteOffset);
          combinedWriteOffset += chunkItem.chunkData.byteLength;
        });
      this.binaryPayload = combinedChunks;
    }
  }

  /**
   * Gets an array of MessagePack encoded SharedMessage chunks.
   * Chunks are determined by splitting the binaryPayload data.
   */
  _getMessageChunks() {
    if (this.binaryPayload) {
      const totalChunks = Math.ceil(this.binaryPayload.byteLength / this.chunkSizeBytes);
      return range(0, totalChunks)
        .map((chunkIndex) => {
          const start = chunkIndex * this.chunkSizeBytes;
          const end = (chunkIndex + 1) * this.chunkSizeBytes;
          const chunkData = this.binaryPayload.slice(start, end);
          return encode({
            ...this._baseMessageChunk(),
            chunkIndex,
            totalChunks,
            chunkData,
          });
        });
    }
    return [encode(this._baseMessageChunk())];
  }

  /**
   * Creates a base chunk object from this SharedMessage.
   */
  _baseMessageChunk() {
    return {
      messageId: this.id,
      name: this.name,
      payload: this.payload,
      totalChunks: 1,
      chunkIndex: 0,
    };
  }
}
