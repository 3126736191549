import { defineStore } from 'pinia';
import { sortBy, uniq } from 'lodash-es';
import * as types from '@/lib/constants/store';
import {
  useEditorStore,
} from '@/stores';

const useBulkActionsStore = defineStore('studio-bulk-actions', {
  state: () => ({
    pageIds: [], // don't access this directly; use the getter
  }),
  getters: {
    bulkActionPageIds: (state) => {
      const editorStore = useEditorStore();
      // Sort page ids by the page's sort_index to maintain order in UI.
      const uniquePageIds = uniq([...state.pageIds, editorStore.currentPageId]);
      return sortBy(uniquePageIds, (pageId) => editorStore.pagesById[pageId]?.sort_index || 0);
    },
  },
  actions: {
    [types.SET_BULK_ACTION_PAGE_IDS](pageIds) {
      this.pageIds = pageIds;
    },
    [types.ADD_BULK_ACTION_PAGE_ID](pageId) {
      if (this.pageIds.includes(pageId)) return;

      this.pageIds.push(pageId);
    },
    [types.REMOVE_BULK_ACTION_PAGE_ID](pageId) {
      const idIndex = this.pageIds.indexOf(pageId);

      if (idIndex === -1) return;

      this.pageIds.splice(idIndex, 1);
    },
  },
});

export default useBulkActionsStore;
