import { reject } from 'lodash-es';

/**
 * Base class that provides ability to raise events and for
 * callers to observe events by attaching event handler functions.
 */
export default class Observable {
  constructor() {
    this._subscriptions = {};
  }

  /**
   * Attaches an event handler to this object.
   * @param {string} eventName Name of the event
   * @param {function} func Handler function to be called when event is raised.
   */
  on(eventName, func) {
    if (!this._subscriptions[eventName]) this._subscriptions[eventName] = [];
    const subscriptions = this._subscriptions[eventName];
    if (!subscriptions.find((sub) => sub === func)) {
      subscriptions.push(func);
    }
  }

  /**
   * Remove an event handler from this object.
   * @param {string} eventName Name of the event
   * @param {function} func Function used when added the event handler.
   */
  off(eventName, func) {
    this._subscriptions[eventName] = func
      ? reject(this._subscriptions[eventName], (sub) => sub === func)
      : [];
  }

  /**
   * Clears all event handler subscriptions.
   */
  clearSubscriptions() {
    this.subscriptions = {};
  }

  /**
   * Raises an event by calling all subscription handlers.
   * @param {string} eventName Name of the event to be raised.
   * @param {Object} payload Event payload object.
   */
  _emit(eventName, payload) {
    (this._subscriptions[eventName] || []).forEach((func) => func(payload));
  }
}
