import { find, get, partial } from 'lodash-es';

class HasLocalDataError extends Error {
  constructor(config, { path, handler }) {
    super(`Local data used for url: ${config.url}`);

    this.getLocalDataResponse = partial(handler, config.url.match(path));
  }
}

/**
 * Gets the local data object from the window if it has been set.
 */
export function getLocalData() {
  return window.studioAppData;
}

/**
 * Adds interceptors to axios clients that will use local data stored at
 * window.studioAppData instead of fetching from the server. If window.studioAppData
 * is not present in the window, then API requests should pass through to the server.
 * @param  {} client The axios client to add local data interceptors to.
 */
export function addLocalDataInterceptors(client) {
  const getResponse = (data) => Promise.resolve({
    status: 200,
    statusText: 'OK',
    headers: {},
    data,
  });

  const getUser = () => getResponse({ user: null });

  const getQuicklist = () => getResponse({
    quicklist: [],
    tokens: {},
    meta: {},
  });

  const getQuicklistIds = () => getResponse({ quicklist: [] });

  const getChannels = () => getResponse({ channels: [] });

  const getDraft = ([, id]) => getResponse({
    draft: get(getLocalData(), 'draft.id') === id ? get(getLocalData(), 'draft') : null,
  });

  const getModule = (collection, property, [, id]) => getResponse({
    [property]: find(get(getLocalData(), collection), (item) => item.id === id),
  });

  const getAsset = partial(getModule, 'assets', 'asset');

  const getAssets = ([, queryString]) => {
    const assetIds = queryString.replace(/asset_id=/g, '').split('&')
      .map((id) => id.toLowerCase());

    return getResponse({
      assets: get(getLocalData(), 'assets', [])
        .filter((asset) => assetIds.includes(asset.id.toLowerCase())),
    });
  };

  const getUpload = partial(getModule, 'uploads', 'upload');

  const getUploads = ([, queryString]) => {
    const uploadIds = queryString.replace(/upload_id=/g, '').split('&')
      .map((id) => id.toLowerCase());

    return getResponse({
      uploads: get(getLocalData(), 'uploads', [])
        .filter((upload) => uploadIds.includes(upload.id.toLowerCase())),
    });
  };

  const getTEI = ([, id]) => {
    let data = {};
    if (getLocalData().teis) {
      data = getLocalData().teis.find((tei) => tei.id === id) || {};
    }
    return getResponse(data);
  };

  const getTemplates = () => getResponse({ templates: [] });

  const getClicklog = () => getResponse({});

  const getProductSettings = () => getResponse({ settings: null });

  const getSessionState = () => getResponse({ session_status: 'logged_in' });

  const idGroup = '([\\-a-zA-Z0-9]+)';

  const localDataRoutes = [
    { path: new RegExp('/users/me'), handler: getUser },
    { path: new RegExp('/mycontent/quicklist$'), handler: getQuicklist },
    { path: new RegExp('/mycontent/quicklist/ids'), handler: getQuicklistIds },
    { path: new RegExp('/home/channels'), handler: getChannels },
    { path: new RegExp('/users/product_settings'), handler: getProductSettings },
    { path: new RegExp(`/drafts/${idGroup}`), handler: getDraft },
    { path: new RegExp(`/assets/${idGroup}`), handler: getAsset },
    { path: new RegExp('/assets/\\?(asset_id=.+)'), handler: getAssets },
    { path: new RegExp(`/uploads/${idGroup}`), handler: getUpload },
    { path: new RegExp('/uploads/\\?(upload_id=.+)'), handler: getUploads },
    { path: new RegExp('/templates'), handler: getTemplates },
    { path: new RegExp('clicklog'), handler: getClicklog },
    { path: new RegExp('session_state'), handler: getSessionState },
    { path: new RegExp(`/teis/${idGroup}`), handler: getTEI },
  ];

  const matchUrlToLocalDataRoute = (url) => {
    if (!getLocalData()) return null;
    return localDataRoutes.find((item) => item.path.test(url));
  };

  client.interceptors.request.use((config) => {
    const localDataRoute = matchUrlToLocalDataRoute(config.url);
    return localDataRoute
      ? Promise.reject(new HasLocalDataError(config, localDataRoute))
      : config;
  }, (error) => Promise.reject(error));

  client.interceptors.response.use((response) => response, (error) => {
    if (error instanceof HasLocalDataError) {
      return error.getLocalDataResponse();
    }
    return Promise.reject(error);
  });

  return client;
}
