import { v4 as uuid } from 'uuid';
import { last, first } from 'lodash-es';
import chain from '@/lib/utils/chain';
import Worker from './worker?worker';

/**
 * Utility object to be used by SharedDocument. Creates a worker process
 * to be used for CPU intensive tasks.
 */
export default class Utils {
  /**
   * Creates a new instance and worker process.
   * @param {SharedDocument} sharedDocument The SharedDocument instance this
   * utility object belongs to.
   */
  constructor(sharedDocument) {
    this._sharedDocument = sharedDocument;
    if (this._sharedDocument.isShared) {
      this._worker = new Worker();
      this._workerPromises = {};
      this._onWorkerMessage = this._handleWorkerMessage.bind(this);
      this._worker.addEventListener('message', this._onWorkerMessage);
    }
  }

  /**
   * Creates a new Promise that can be resolved or rejected externally.
   * @param {function} func The function to be executed by the Promise.
   */
  getPublicPromise(func) {
    const wrapper = {};
    wrapper.promise = new Promise((resolve, reject) => {
      wrapper.resolve = resolve;
      wrapper.reject = reject;
      func(resolve, reject);
    });
    return wrapper;
  }

  /**
   * Combines a base url and list of paths and removes duplicate slashes.
   * @param  {...any} paths Base url first, and then list of paths to be joined.
   */
  url(...paths) {
    return chain(paths)
      .map((item) => {
        let path = item;
        if (first(path) === '/') path = path.slice(1, path.length);
        if (last(path) === '/') path = path.slice(0, path.length - 1);
        return path;
      })
      .join('/')
      .value();
  }

  /**
   * Compresses data using Gzip.
   * @param {ArrayBuffer} arrayBuffer Data to compress.
   */
  gzip(arrayBuffer) {
    const eventId = uuid();
    const publicPromise = this.getPublicPromise((resolve, reject) => {
      try {
        const event = {
          eventId,
          name: 'gzip',
          data: arrayBuffer,
        };
        /*
          Run the Gzip compression in a worker process since it
          is a CPU intensive process and may freeze the UI thread.
        */
        this._worker.postMessage(event, [event.data]);
        setTimeout(() => {
          /*
            If we don't get a response back from the worker within a
            few seconds, we will consider it an error. Worker should return errors,
            but this is just in case we don't hear back.
          */
          if (this._workerPromises[eventId]) {
            delete this._workerPromises[eventId];
            reject(new Error('No response for gzip request'));
          }
        }, 3000);
      } catch (error) {
        reject(error);
      }
    });
    /*
      Store the promise so when we hear back from the worker process,
      we can reject or resolve the promise.
    */
    this._workerPromises[eventId] = publicPromise;
    return publicPromise.promise;
  }

  /**
   * Cleans up any resources used by this object.
   */
  destroy() {
    if (this._worker) {
      this._worker.removeEventListener('message', this._onWorkerMessage);
      this._worker.terminate();
      this._worker = null;
    }
  }

  /**
   * Handles responses from worker process.
   * @param {Object} message Message sent from worker process.
   */
  _handleWorkerMessage(message) {
    try {
      const event = message.data;
      // Get the stored promise and resolve or reject.
      const promise = this._workerPromises[event.eventId];
      if (promise) {
        if (event.error) {
          promise.reject(new Error(event.error));
        } else {
          promise.resolve(event.data);
        }
        delete this._workerPromises[event.eventId];
      }
    } catch (error) {
      this._sharedDocument._emitError(error);
    }
  }
}
