import { defineStore } from 'pinia';
import i18next from '@/lib/i18next';
import * as types from '@/lib/constants/store';

const useToastStore = defineStore('studio-toast', {
  state: () => ({
    studioToast: {},
  }),
  actions: {
    [types.SET_STUDIO_TOAST](payload) {
      const message = payload.message || i18next.t('Something went wrong. Changes were not saved.');
      const { type, position } = payload;

      this.studioToast = { type, message, position };
      setTimeout(() => {
        this.studioToast = {};
      }, 3000);
    },
  },
});

export default useToastStore;
