import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import * as types from '@/lib/constants/store';

const useMessageStore = defineStore('studio-message', {
  state: () => ({
    appMessage: null,
  }),
  actions: {
    [types.SET_APP_MESSAGE](message) {
      this.appMessage = {
        ...message,
        id: uuid(),
      };
    },
  },
});

export default useMessageStore;
