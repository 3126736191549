import chain from '@/lib/utils/chain';

/*
  For each attempt to place a module on the canvas at a certain angle,
  this is the distance from the center (defaultPosition) we will increment
  for each try.
*/
const PLACE_MODULE_CENTER_OFFSET_INCREMENT = 45;

/**
 * Gets the bounding box for a module in vue-grid-layout coordinates. Adjusts the
 * bounding box for module rotation.
 * @param {Object} mod The module to get the bounding box for.
 * @param {Object} position The position { x, y } the module will be placed on the canvas.
 * @returns {Object} The boudning box { top, left, right, bottom, width, height } for the module.
 */
function getRotatedModuleBoundingBox(mod, position) {
  const xPosition = position ? position.x : mod.layout.x_position;
  const yPosition = position ? position.y : mod.layout.y_position;
  const halfHeight = mod.layout.height / 2;
  const halfWidth = mod.layout.width / 2;
  const modCenterX = xPosition + halfWidth;
  const modCenterY = yPosition + halfHeight;
  const cornerRadius = Math.sqrt((halfWidth ** 2) + (halfHeight ** 2));
  const rotationRadians = (mod.layout.rotate_degree || 0) * (Math.PI / 180);
  const halfHeightRadiusRatio = halfHeight / cornerRadius;
  const cornerRadiusAngleRadians = Math.asin(halfHeightRadiusRatio);

  const ninetyDegRadians = (90 / 180) * Math.PI;
  const leftToRightAngleDiff = (ninetyDegRadians - cornerRadiusAngleRadians) * 2;
  const topToBottomAngleDiff = cornerRadiusAngleRadians * 2;

  const bottomRightCornerAngle = (cornerRadiusAngleRadians + rotationRadians) * -1;
  const bottomLeftCornerAngle = bottomRightCornerAngle - leftToRightAngleDiff;
  const topLeftCornerAngle = bottomRightCornerAngle - leftToRightAngleDiff - topToBottomAngleDiff;
  const topRightCornerAngle = bottomRightCornerAngle - (leftToRightAngleDiff * 2)
    - topToBottomAngleDiff;

  const cornerAngles = [bottomRightCornerAngle, bottomLeftCornerAngle,
    topLeftCornerAngle, topRightCornerAngle];

  return cornerAngles.reduce((acc, angle, index) => {
    const box = acc;
    const yOffset = Math.sin(angle) * cornerRadius;
    const xOffset = Math.cos(angle) * cornerRadius;
    const corner = {
      x: Math.round(xOffset + modCenterX),
      y: Math.round(modCenterY - yOffset),
    };
    box.corners.push(corner);
    if (box.left === undefined || corner.x < box.left) box.left = corner.x;
    if (box.right === undefined || corner.x > box.right) box.right = corner.x;
    if (box.top === undefined || corner.y < box.top) box.top = corner.y;
    if (box.bottom === undefined || corner.y > box.bottom) box.bottom = corner.y;
    const isLastAngle = index === 3;
    if (isLastAngle) {
      box.width = box.right - box.left;
      box.height = box.bottom - box.top;
    }
    return box;
  }, { corners: [] });
}

/**
 * Gets the total area for the provided bounding boxes.
 * @param {Array} boxes Array of bounding boxes to get total area for.
 * @returns {Number} The total area for all boxes combined.
 */
function getAreaForBoxes(boxes) {
  return boxes.reduce((acc, box) => {
    const width = box.right - box.left;
    const height = box.bottom - box.top;
    return acc + width * height;
  }, 0);
}

/**
 * Determines what areas of the bottom box are still visible after the
 * top box has been overlaid. The remaining visible area of the bottom box
 * is split into rectangles and returned as a list of visible boxes.
 * @param {Object} bottomBox Represents one visible rectangle of the bottom module.
 * @param {Object} topBox Represents one visible rectangle of the top module.
 * @returns {Array} The remaining visible rectangles for the bottom box.
 */
function calculateModuleVisibleBoxes(bottomBox, topBox) {
  const topInside = topBox.top >= bottomBox.top
    && topBox.top <= bottomBox.bottom;
  const bottomInside = topBox.bottom >= bottomBox.top
    && topBox.bottom <= bottomBox.bottom;
  const leftInside = topBox.left >= bottomBox.left
    && topBox.left <= bottomBox.right;
  const rightInside = topBox.right >= bottomBox.left
    && topBox.right <= bottomBox.right;
  const leftOutside = topBox.left < bottomBox.left;
  const rightOutside = topBox.right > bottomBox.right;
  const topOutside = topBox.top < bottomBox.top;
  const bottomOutside = topBox.bottom > bottomBox.bottom;

  const topLeftCornerInside = topInside && leftInside;
  const topRightCornerInside = topInside && rightInside;
  const bottomLeftCornerInside = bottomInside && leftInside;
  const bottomRightCornerInside = bottomInside && rightInside;
  const insideCornerCount = [
    topLeftCornerInside,
    topRightCornerInside,
    bottomLeftCornerInside,
    bottomRightCornerInside,
  ].filter((inside) => inside).length;

  let splitBoxes = [];

  if (topLeftCornerInside && insideCornerCount === 1) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.left + (topBox.left - bottomBox.left) },
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        left: bottomBox.right - (bottomBox.right - topBox.left),
      },
    ];
  } else if (topRightCornerInside && insideCornerCount === 1) {
    splitBoxes = [
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        right: bottomBox.right - (bottomBox.right - topBox.right),
      },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
    ];
  } else if (bottomRightCornerInside && insideCornerCount === 1) {
    splitBoxes = [
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        right: bottomBox.right - (bottomBox.right - topBox.right),
      },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
    ];
  } else if (bottomLeftCornerInside && insideCornerCount === 1) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.right - (bottomBox.right - topBox.left) },
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
      },
    ];
  } else if (topLeftCornerInside && bottomLeftCornerInside && insideCornerCount === 2) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.right - (bottomBox.right - topBox.left) },
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
      },
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
      },
    ];
  } else if (topLeftCornerInside && topRightCornerInside && insideCornerCount === 2) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.left + (topBox.left - bottomBox.left) },
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
    ];
  } else if (bottomRightCornerInside && topRightCornerInside && insideCornerCount === 2) {
    splitBoxes = [
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
    ];
  } else if (bottomRightCornerInside && bottomLeftCornerInside && insideCornerCount === 2) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.left + (topBox.left - bottomBox.left) },
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
    ];
  } else if (topLeftCornerInside && topRightCornerInside
    && bottomLeftCornerInside && bottomRightCornerInside) {
    splitBoxes = [
      { ...bottomBox, right: bottomBox.left + (topBox.left - bottomBox.left) },
      { ...bottomBox, left: bottomBox.left + (topBox.right - bottomBox.left) },
      {
        ...bottomBox,
        bottom: bottomBox.top + (topBox.top - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
      {
        ...bottomBox,
        top: bottomBox.top + (topBox.bottom - bottomBox.top),
        left: bottomBox.left + (topBox.left - bottomBox.left),
        right: bottomBox.left + (topBox.right - bottomBox.left),
      },
    ];
  } else if (bottomInside && leftOutside && rightOutside && topOutside) {
    splitBoxes = [{ ...bottomBox, top: topBox.bottom }];
  } else if (topInside && leftOutside && rightOutside && bottomOutside) {
    splitBoxes = [{ ...bottomBox, bottom: topBox.top }];
  } else if (rightInside && leftOutside && topOutside && bottomOutside) {
    splitBoxes = [{ ...bottomBox, left: topBox.right }];
  } else if (leftInside && rightOutside && topOutside && bottomOutside) {
    splitBoxes = [{ ...bottomBox, right: topBox.left }];
  } else if (topInside && bottomInside && leftOutside && rightOutside) {
    splitBoxes = [
      { ...bottomBox, bottom: topBox.top },
      { ...bottomBox, top: topBox.bottom },
    ];
  } else if (leftInside && rightInside && topOutside && bottomOutside) {
    splitBoxes = [
      { ...bottomBox, right: topBox.left },
      { ...bottomBox, left: topBox.right },
    ];
  } else if (leftOutside && rightOutside && topOutside && bottomOutside) {
    return [];
  }

  if (splitBoxes.length) {
    return splitBoxes.filter((box) => box.right > box.left && box.bottom > box.top);
  }

  return [bottomBox];
}

/**
 * Recalculates the visible rectangles of the bottom box after the
 * top box has been overlaid. Takes each visible rectangle of the top
 * box and punches its area out of each visible rectangle of the bottom
 * box.
 * @param {Object} bottomBox Bounding box and visible boxes of the bottom module.
 * @param {Object} topBox Bounding box and visible boxes of the top module.
 * @returns {Array} The remaining visible rectangles for the bottom box.
 */
function getModuleVisibleBoxes(bottomBox, topBox) {
  let bottomVisibleBoxes = bottomBox.visibleBoxes;
  const topVisibleBoxes = topBox.visibleBoxes;
  topVisibleBoxes.forEach((topVisibleBox) => {
    const visibleBoxes = [];
    bottomVisibleBoxes.forEach((bottomVisibleBox) => {
      const boxes = calculateModuleVisibleBoxes(bottomVisibleBox, topVisibleBox);
      visibleBoxes.push(...boxes);
    });
    bottomVisibleBoxes = visibleBoxes;
  });

  return bottomVisibleBoxes;
}

/**
 * Gets a list of bounding boxes and visible boxes for the given modules.
 * @param {Array} modules Modules to get bounding boxes for.
 * @returns {Array} Array of bounding boxes for the given modules.
 */
function getModuleBoundingBoxes(modules) {
  const moduleBoxes = chain(modules)
    .map((mod) => {
      const box = getRotatedModuleBoundingBox(mod);
      return {
        mod,
        box,
        visibleBoxes: [box],
      };
    })
    .orderBy('mod.layout.z_position', 'desc')
    .value();

  if (moduleBoxes.length > 1) {
    for (let index = 0; index < moduleBoxes.length - 1; index += 1) {
      const topModuleBox = moduleBoxes[index];
      for (let nextIndex = index + 1; nextIndex < moduleBoxes.length; nextIndex += 1) {
        const bottomModuleBox = moduleBoxes[nextIndex];
        bottomModuleBox.visibleBoxes = getModuleVisibleBoxes(bottomModuleBox, topModuleBox);
      }
    }
  }

  moduleBoxes.forEach((box) => {
    const moduleBox = box;
    moduleBox.visibleBoxes.forEach((modVisibleBox) => {
      const visibleBox = modVisibleBox;
      visibleBox.width = visibleBox.right - visibleBox.left;
      visibleBox.height = visibleBox.bottom - visibleBox.top;
    });
    moduleBox.visibleBoxesArea = getAreaForBoxes(moduleBox.visibleBoxes);
  });

  return moduleBoxes;
}

/**
 * Gets the max percentage of a module's area that can be covered by
 * the module being placed on the canvas. The smaller the module's area,
 * the less percentage of its area we will allow the module being placed to cover.
 * @param {Number} visibleArea The remaining visible area for the module that will
 * be covered by the module being placed.
 * @returns {Number} Percent coverage allowed for the module that will be covered.
 */
function getMaxCoveredAreaPctAllowed(visibleArea) {
  if (visibleArea >= 10000) return 0.85;
  if (visibleArea >= 6000) return 0.75;
  if (visibleArea >= 2500) return 0.15;
  return 0;
}

/**
 * Determines if the provided module can be placed at the specified position.
 * @param {Object} moduleToPlace Module object.
 * @param {Object} position Postion on canvas { x, y } the module will be placed.
 * @param {Array} moduleBoxes List of bounding and visbile boxes for modules already on the canvas.
 * @returns {Boolean} True if the module can be placed at this position.
 */
function canPlaceModuleAtPosition(moduleToPlace, position, moduleBoxes) {
  const modToPlaceBox = getRotatedModuleBoundingBox(moduleToPlace, position);
  for (let boxIndex = 0; boxIndex < moduleBoxes.length; boxIndex += 1) {
    const moduleBox = moduleBoxes[boxIndex];
    const newVisibleBoxes = chain(moduleBox.visibleBoxes)
      .map((visibleBox) => calculateModuleVisibleBoxes(visibleBox, modToPlaceBox))
      .flatten()
      .value();
    const newArea = getAreaForBoxes(newVisibleBoxes);
    const areaCoveredPct = (moduleBox.visibleBoxesArea - newArea) / moduleBox.visibleBoxesArea;

    if (areaCoveredPct > getMaxCoveredAreaPctAllowed(moduleBox.visibleBoxesArea)) {
      return false;
    }
  }

  return true;
}

/**
 * Determines if the specified module hanging off the canvas or beyond the
 * canvas bleed in any direction.
 * @param {Object} moduleToPlace The module to check.
 * @param {Object} position The grid coordinates { x, y } the module is placed at.
 * @param {Object} canvasInfo Information about the canvas size and allowed bleed.
 * @returns {Boolean} True if the module is off canvas or outside canvas bleed.
 */
function moduleIsOffCanvas(moduleToPlace, position, canvasInfo) {
  const moduleBox = getRotatedModuleBoundingBox(moduleToPlace, position);
  const bleed = canvasInfo.bleed || 0;
  const minLeftOrTop = bleed * -1;
  const maxRight = canvasInfo.width + bleed;
  const maxBottom = canvasInfo.height + bleed;
  return moduleBox.left < minLeftOrTop
    || moduleBox.right > maxRight
    || moduleBox.top < minLeftOrTop
    || moduleBox.bottom > maxBottom;
}

/**
 * Gets the canvas bleed needed for the module being placed. If the defaultPosition
 * puts the module outside the canvas, then we return the largest distance the
 * module is overhanging the canvas in any direction.
 * @param {Object} moduleToPlace Module being placed.
 * @param {Object} defaultPosition Starting position { x, y } for the module being placed.
 * @param {Object} canvasInfo Dimensions and bleed info for the canvas.
 * @returns {Number} The largest distance the module is extending beyond the canvas edge.
 */
function getCanvasBleed(moduleToPlace, defaultPosition, canvasInfo) {
  let leftBleed = 0;
  let rightBleed = 0;
  let topBleed = 0;
  let bottomBleed = 0;
  if (defaultPosition.x < 0) {
    leftBleed = Math.abs(defaultPosition.x);
  }
  const modRight = defaultPosition.x + moduleToPlace.layout.width;
  if (modRight > canvasInfo.width) {
    rightBleed = modRight - canvasInfo.width;
  }
  if (defaultPosition.y < 0) {
    topBleed = Math.abs(defaultPosition.y);
  }
  const modBottom = defaultPosition.y + moduleToPlace.layout.height;
  if (modBottom > canvasInfo.height) {
    bottomBleed = modBottom - canvasInfo.height;
  }
  return Math.max(leftBleed, rightBleed, topBleed, bottomBleed);
}

/**
 * Gets all the angles (radiating outward from the defaultPosition) that we should
 * attempt to place the module on. The angles are from the defaultPosition and if followed
 * will eventually place the module:
 * 1. In one of the four corners of the canvas.
 * 2. Directly left or right or top or bottom of the center of each canvas edge.
 * 3. Centered on one of 8 outer edges that make up the 4 quadrants of the canvas.
 * @param {Object} moduleToPlace Module object being placed.
 * @param {Object} position The position { x , y } to radiate angles from.
 * @param {Object} canvasInfo Dimensions and bleed info for the canvas.
 * @returns The list of angles (in order) to attempt to place the module on.
 */
function getModulePlacementAngles(moduleToPlace, position, canvasInfo) {
  const moduleBox = getRotatedModuleBoundingBox(moduleToPlace, position);
  const moduleHalfWidth = moduleBox.width / 2;
  const moduleHalfHeight = moduleBox.height / 2;
  const canvasHalfWidth = canvasInfo.width / 2;
  const canvasQuarterWidth = canvasHalfWidth / 2;
  const canvasHalfHeight = canvasInfo.height / 2;
  const canvasQuarterHeight = canvasHalfHeight / 2;
  const topPointY = 0;
  const rightPointX = canvasInfo.width - moduleBox.width;
  const leftPointX = 0;
  const topRadiationPoints = [
    { x: leftPointX, y: topPointY },
    { x: canvasQuarterWidth - moduleHalfWidth, y: topPointY },
    { x: canvasHalfWidth - moduleBox.width, y: topPointY },
    { x: canvasHalfWidth, y: topPointY },
    { x: canvasInfo.width - canvasQuarterWidth - moduleHalfWidth, y: topPointY },
    { x: rightPointX, y: topPointY },
  ];
  const rightRadiationPoints = [
    { x: rightPointX, y: canvasQuarterHeight - moduleHalfHeight },
    { x: rightPointX, y: canvasHalfHeight - moduleBox.height },
    { x: rightPointX, y: canvasHalfHeight },
    { x: rightPointX, y: canvasInfo.height - canvasQuarterHeight - moduleHalfHeight },
  ];
  const bottomRadiationPoints = topRadiationPoints
    .map((point) => ({ ...point, y: point.y + (canvasInfo.height - moduleBox.height) }))
    .reverse();
  const leftRadiationPoints = rightRadiationPoints
    .map((point) => ({ ...point, x: leftPointX }))
    .reverse();
  const radiationPoints = [...bottomRadiationPoints, ...leftRadiationPoints,
    ...topRadiationPoints, ...rightRadiationPoints];
  const toDegrees = (rad) => rad * (180 / Math.PI);

  const angles = radiationPoints.map((point) => {
    const xDistance = Math.abs(moduleBox.left - point.x);
    const yDistance = Math.abs(moduleBox.top - point.y);
    const pointRadius = Math.sqrt((xDistance ** 2) + (yDistance ** 2));
    let angle = toDegrees(Math.asin(yDistance / pointRadius));
    if (point.y >= moduleBox.top && point.x <= moduleBox.left) {
      angle = 180 - angle;
    } else if (point.y < moduleBox.top && point.x <= moduleBox.left) {
      angle += 180;
    } else if (point.y < moduleBox.top && point.x > moduleBox.left) {
      angle = 360 - angle;
    }
    return angle;
  });

  return [
    // Try angles to all four corners first.
    ...angles.filter((angle, index) => index % 5 === 0),
    // Then go clockwise around the canvas.
    ...angles.filter((angle, index) => index % 5 !== 0),
  ];
}

/**
 * Attempts to place modules consecutively on angles radiating outward from
 * the defaultPosition (Like decks of cards spread in different directions)
 * The defaultPosition will default to the module centered on the canvas or
 * the 'defaultPosition' provided in the canvas object parameter.
 * Also tries not completely cover the visible portions of any already existing
 * modules on the canvas.
 * @param {Object} modules The already existing modules on the canvas.
 * @param {Object} moduleToPlace Module object to place.
 * @param {Object} canvas Dimensions and bleed info for the canvas.
 * @returns The position { x, y } the module should be placed.
 */
export default function placeModule(modules, moduleToPlace, canvas) {
  const canvasInfo = canvas;
  let { defaultPosition } = canvas;

  // calculate a default Position
  if (!defaultPosition) {
    // here to make sure placement still works if we're missing a default
    defaultPosition = {
      x: (canvas.width - moduleToPlace.layout.width) / 2,
      y: (canvas.height - moduleToPlace.layout.height) / 2,
    };
  }
  /*
    Gets the bounding boxes and visible rectangles for each module on the canvas.
  */
  const moduleBoxes = getModuleBoundingBoxes(modules);

  /*
    If the defaultPosition is off the canvas then extend the bleed of the canvas
    to reach the defaultPosition. This way modules can be placed outside the canvas
    boundaries if needed.
  */
  if (moduleIsOffCanvas(moduleToPlace, defaultPosition, canvasInfo)) {
    canvasInfo.bleed = getCanvasBleed(moduleToPlace, defaultPosition, canvasInfo);
  }

  /*
    Gets the angles (in order) to attempt to place the module on.
  */
  const angles = getModulePlacementAngles(moduleToPlace, defaultPosition, canvasInfo);

  /*
    For each angle, attempt to place the module on the angle incrementing the
    distance outward further for each try. If the module cannot be placed at
    the current distance, we increase the distance and try again. When the edge of
    the canvas (or bleed) is reached, repeat for the next angle.
  */
  for (let angleIndex = 0; angleIndex < angles.length; angleIndex += 1) {
    const angleRadians = ((angles[angleIndex] * -1) / 180) * Math.PI;
    let inCanvas = true;
    let angleStep = 0;
    while (inCanvas) {
      const radius = angleStep * PLACE_MODULE_CENTER_OFFSET_INCREMENT;
      const xOffset = Math.cos(angleRadians) * radius;
      const yOffset = Math.sin(angleRadians) * radius * -1;

      const tryPosition = {
        x: Math.round(defaultPosition.x + xOffset),
        y: Math.round(defaultPosition.y + yOffset),
      };

      if (moduleIsOffCanvas(moduleToPlace, tryPosition, canvasInfo)) {
        // if we're off the canvas, we need to break out of this particular loop
        inCanvas = false;
      } else if (canPlaceModuleAtPosition(moduleToPlace, tryPosition, moduleBoxes)) {
        // the module is placeable AND on the canvas, set that as the new position
        // and break out of the loop
        return tryPosition;
      }

      angleStep += 1;
    }
  }

  return defaultPosition;
}
