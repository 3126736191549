import { get } from 'lodash-es';
import i18next from '@/lib/i18next';
import { widthToPixels, heightToPixels } from '@/lib/utils/grid';
import { getThemeDefaults } from '@/lib/utils/store';
import {
  ASSET_TYPE_IMAGE,
  ASSET_TYPE_NON_SEARCHABLE_IMAGE,
} from '@/lib/constants';

// Only should be used if it's the only module on the page.
const defaultZPosition = 0.5;

export function textModuleDefault(gridDisabled, themeName, height = 1, width = 2) {
  const defaultContent = {
    type: 'doc',
    attrs: {
      defaultFont: getThemeDefaults(themeName).font,
    },
    content: [{
      type: 'paragraph',
      attrs: {
        class: null,
      },
      content: [],
    }],
  };
  return {
    type: 'text',
    name: i18next.t('Text'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      overflow: 'overflow',
      content: JSON.stringify(defaultContent),
      theme: {
        name: 'default',
        variation: 1,
      },
    },
  };
}

export function wordTrackingModuleDefault(gridDisabled, themeName) {
  const mod = textModuleDefault(gridDisabled, themeName, gridDisabled ? 3 : 5, 5);
  mod.options.overflow = 'hidden';
  mod.options.word_tracking = {
    enabled: true,
    button_style: getThemeDefaults(themeName).buttonStyle,
    button_theme: {
      variation: getThemeDefaults(themeName).themeVariation,
    },
  };
  return mod;
}

export function buttonModuleDefault(gridDisabled, themeName) {
  const height = 1;
  const width = 2;
  return {
    type: 'button',
    name: i18next.t('Button'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      text: i18next.t('Button'),
      button_type: 'button',
      button_style: getThemeDefaults(themeName).buttonStyle,
      theme: {
        variation: getThemeDefaults(themeName).themeVariation,
      },
      action: 'link',
      target: 'https://www.discoveryeducation.com',
      target_page: 'NEXT', // out of ['NEXT', 'PREV', pageId]
    },
  };
}

export function advancedButtonModuleDefault(gridDisabled, themeName) {
  const defaultContent = {
    type: 'doc',
    attrs: {
      verticalAlign: 'middle',
      defaultFont: getThemeDefaults(themeName).font,
    },
    content: [{
      type: 'paragraph',
      attrs: {
        class: 'align-center',
      },
      content: [],
    }],
  };
  const height = 1;
  const width = 2;
  return {
    type: 'advanced_button',
    name: i18next.t('Advanced Button'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      overflow: 'overflow',
      content: JSON.stringify(defaultContent),
      button_style: getThemeDefaults(themeName).buttonStyle,
      theme: {
        variation: getThemeDefaults(themeName).themeVariation,
      },
      action: 'link',
      target: 'https://www.discoveryeducation.com',
      target_page: 'NEXT', // out of ['NEXT', 'PREV', pageId]
    },
  };
}

export function placeholderModuleDefault(gridDisabled) {
  const height = 4;
  const width = 4;
  return {
    type: 'placeholder',
    name: i18next.t('Placeholder'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {},
  };
}

export function uploadModuleDefault(gridDisabled) {
  const height = 4;
  const width = 4;
  return {
    type: 'upload',
    name: i18next.t('Upload'),
    options: {
      image_settings: 'letterbox',
      layout: 'image',
      show_description: false,
      show_title: false,
      show_type: false,
      use_transparent_background: false,
      upload_id: null,
      pending: false,
    },
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
  };
}

export function promptModuleDefault(gridDisabled) {
  const height = 4;
  const width = 4;
  return {
    type: 'prompt',
    name: i18next.t('Short Answer'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      content: i18next.t('Type in question here'),
      style: {},
    },
  };
}

export function singleSelectionModuleDefault(gridDisabled) {
  const height = 7;
  const width = 4;
  return {
    type: 'single_selection',
    name: i18next.t('Multiple Choice'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      content: i18next.t('Type in question here'),
      valid_options: [0],
      options: [
        i18next.t('Option 1'),
        i18next.t('Option 2'),
        i18next.t('Option 3'),
        i18next.t('Option 4'),
      ],
    },
  };
}

export function multipleSelectionModuleDefault(gridDisabled) {
  const height = 7;
  const width = 4;
  return {
    type: 'multiple_selection',
    name: i18next.t('Multiple Select'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      content: i18next.t('Type in question here'),
      valid_options: [0],
      options: [
        i18next.t('Option 1'),
        i18next.t('Option 2'),
        i18next.t('Option 3'),
        i18next.t('Option 4'),
      ],
    },
  };
}

export function blockModuleDefault(gridDisabled) {
  const height = 7;
  const width = 4;
  return {
    type: 'block',
    name: i18next.t('Block'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      content: i18next.t('Type in question here'),
    },
  };
}

export function assetModuleDefault(gridDisabled = false, asset = null) {
  const assetTypeId = get(asset, 'type.id') || get(asset, 'type_id');
  const isImage = [ASSET_TYPE_IMAGE, ASSET_TYPE_NON_SEARCHABLE_IMAGE].includes(assetTypeId);
  const height = isImage ? 4 : 8;
  const width = 4;

  return {
    type: 'asset',
    name: i18next.t('Asset'),
    layout: {
      x_position: 0,
      y_position: 0,
      z_position: gridDisabled ? defaultZPosition : undefined,
      height: gridDisabled ? heightToPixels(height) : height,
      width: gridDisabled ? widthToPixels(width) : width,
    },
    options: {
      asset_id: null,
      image_settings: 'fill',
      layout: isImage ? 'image' : 'top',
      show_title: true,
      show_description: true,
      show_type: true,
    },
  };
}

export default [
  textModuleDefault,
  wordTrackingModuleDefault,
  buttonModuleDefault,
  placeholderModuleDefault,
  uploadModuleDefault,
  promptModuleDefault,
  singleSelectionModuleDefault,
  multipleSelectionModuleDefault,
  blockModuleDefault,
  assetModuleDefault,
];
